import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  RadioGroup,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import {
  MdArrowForwardIos,
  MdAutorenew,
  MdClose,
  MdFlag,
  MdHail,
  MdInfoOutline,
  MdLocalTaxi,
  MdWatchLater,
} from "react-icons/md";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Calendar from "../../../components/svg/Calendar";
import Return from "../../../components/svg/Return";
import { TaxiButton } from "../../../components/taxi/TaxiButton";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useHeaderConfig from "../../../hooks/useHeaderConfig";
import { p2r } from "../../../utils/font";
import useTaxiReservationCreateState from "../useTaxiReservationCreateState";
import InputText from "./InputText";
import RadioButton from "./RadioButton";

type ReverseButtonProps = ButtonProps;
const ReverseButton: React.FC<ReverseButtonProps> = (props) => {
  const { ...rest } = props;
  return (
    <Button
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxSize={p2r(48)}
      backgroundColor="grand.grayLight"
      borderRadius="50%"
      {...rest}
    >
      <Icon as={MdAutorenew} boxSize={p2r(24)} color="grand.grayDark" />
    </Button>
  );
};

type CreateStepItemButtonProps = {
  isEmpty?: boolean;
  isActive?: boolean;
  children: ReactNode;
} & ButtonProps;
const CreateStepItemButton: React.FC<CreateStepItemButtonProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isEmpty = true, isActive = false, children, ...rest } = props;
  return (
    <Button
      width="100%"
      height={isMobile ? p2r(48) : p2r(52)}
      pl="16px"
      pr="8px"
      py="16px"
      backgroundColor="grand.white"
      borderRadius="8px"
      borderWidth="2px"
      borderColor={isActive ? "theme.primary" : "grand.grayLight"}
      variant="ghost"
      display="flex"
      justifyContent="space-between"
      fontSize={isEmpty ? p2r(16) : p2r(24)}
      color={isEmpty ? "text.sub" : "text.body"}
      _hover={{
        background: "grand.white",
      }}
      {...rest}
    >
      {children}
      <Icon as={MdArrowForwardIos} boxSize={p2r(24)} color="grand.gray" />
    </Button>
  );
};

type CreateStepItemProps = {
  icon: React.FC;
  name: string;
  children: ReactNode;
};
const CreateStepItem: React.FC<CreateStepItemProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { icon, name, children } = props;
  return (
    <Flex
      direction="column"
      position="relative"
      rowGap={isMobile ? "0px" : "4px"}
      w="100%"
      flex="1"
      maxW="560px"
    >
      <Flex columnGap="8px" py={isMobile ? "2px" : "8px"} align="center">
        <Icon
          as={icon}
          boxSize={isMobile ? p2r(24) : p2r(32)}
          color="theme.primaryDark"
        />
        <Text
          fontSize={isMobile ? p2r(11) : p2r(24)}
          fontWeight="700"
          lineHeight="150%"
          color="theme.primaryDark"
        >
          {name}
        </Text>
      </Flex>

      {children}
    </Flex>
  );
};

// タクシー予約の画面一覧（サイドメニューの現在位置判定用）
const menuItemConfigs = [
  {
    path: "/taxi/reservations/create/select-company",
  },
  {
    path: "/taxi/reservations/create/select-from",
  },
  {
    path: "/taxi/reservations/create/select-to",
  },
  {
    path: "/taxi/reservations/create/select-date",
  },
  {
    path: "/taxi/reservations/create/select-time",
  },
  {
    path: "/taxi/reservations/create/return-select-from",
  },
  {
    path: "/taxi/reservations/create/return-select-to",
  },
  {
    path: "/taxi/reservations/create/return-select-date",
  },
  {
    path: "/taxi/reservations/create/return-select-time",
  },
];

const TaxiReservationCreate: React.FC<React.PropsWithChildren> = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  useHeaderConfig({
    title: !isMobile ? "まちのクルマ Let's 予約" : "タクシー予約",
  });

  const { f } = useFormatMessage();
  const {
    formValues,
    setFormValues,
    selectedTaxiCompany,
    selectedEndTaxiLandmark,
    selectedStartTaxiLandmark,
    selectedRideDate,
    selectedRideTime,
    taxiRideDateTime,
    setTaxiRideDateTime,
    selectedReturnRideDate,
    selectedReturnRideTime,
    // selectedReturnStartTaxiLandmark,
    // selectedReturnEndTaxiLandmark,
  } = useTaxiReservationCreateState();

  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  // ステップが前後するタイミングで、現在位置更新
  const activeStepNumber = useMemo(
    () => menuItemConfigs.findIndex((conf) => conf.path === currentPath),
    [currentPath]
  );

  // 一覧の場合はメニュー閉じる
  useEffect(() => {
    if (
      currentPath === "/taxi/reservations/create" ||
      currentPath === "/taxi/reservations/create/"
    ) {
      setIsOpenMenu(false);
    } else {
      setIsOpenMenu(true);
    }
  }, [currentPath]);

  const handleClick = (path: string) => {
    navigate(path);
    setIsOpenMenu(true);
  };

  // 乗車場所と目的地を入れ替え
  const reverseLandmarkChange = () => {
    setFormValues({
      startTaxiLandmarkId: formValues.endTaxiLandmarkId,
      startLatLng: formValues.endLatLng,
      startAddress: formValues.endAddress,
      endTaxiLandmarkId: formValues.startTaxiLandmarkId,
      endLatLng: formValues.startLatLng,
      endAddress: formValues.startAddress,
    });
  };

  // 乗車場所と目的地を入れ替え（帰路）
  // const reverseReturnLandmarkChange = () => {
  //   setFormValues({
  //     returnStartTaxiLandmarkId: formValues.returnEndTaxiLandmarkId,
  //     returnStartLatLng: formValues.returnEndLatLng,
  //     returnStartAddress: formValues.returnEndAddress,
  //     returnEndTaxiLandmarkId: formValues.returnStartTaxiLandmarkId,
  //     returnEndLatLng: formValues.returnStartLatLng,
  //     returnEndAddress: formValues.returnStartAddress,
  //   })
  // }

  // 帰路の各選択肢の初期値として往路の値を設定
  const changeOnInBound = () => {
    setFormValues({
      isInBound: true,
      returnRideAt: "",
      returnStartTaxiLandmarkId: formValues.endTaxiLandmarkId,
      returnStartLatLng: formValues.endLatLng,
      returnStartAddress: formValues.endAddress,
      returnEndTaxiLandmarkId: formValues.startTaxiLandmarkId,
      returnEndLatLng: formValues.startLatLng,
      returnEndAddress: formValues.startAddress,
    });
    setTaxiRideDateTime({
      returnDate: {
        year: taxiRideDateTime.date.year,
        month: taxiRideDateTime.date.month,
        day: taxiRideDateTime.date.day,
      },
      returnTime: {
        hour: taxiRideDateTime.time.hour + 1, // 初期値は往路の1時間経過
        minute: taxiRideDateTime.time.minute,
      },
    });
  };

  // TODO サーバーサイドバリデーション
  const enableSubmit = useMemo<boolean>(() => {
    if (!formValues.endAddress && !formValues.endTaxiLandmarkId) {
      return false;
    }

    if (!formValues.startAddress && !formValues.startTaxiLandmarkId) {
      return false;
    }

    if (!formValues.taxiCompanyId) {
      return false;
    }

    if (!formValues.rideAt) {
      return false;
    }

    return true;
  }, [formValues]);

  return (
    <Flex
      backgroundColor="theme.background"
      minH="calc(100vh - 56px)"
      // direction={{ base: 'column', md: 'row' }}
    >
      <>
        {/* 選択中の値 */}
        <Flex
          display={isOpenMenu && isMobile ? "none" : "flex"}
          direction="column"
          w={isOpenMenu ? "34%" : "100%"}
          h="fit-content"
          rowGap="16px"
          p={isMobile ? "16px" : "32px"}
        >
          <Flex
            direction={isOpenMenu ? "column" : "row"}
            columnGap="24px"
            rowGap="16px"
          >
            <CreateStepItem icon={MdLocalTaxi} name={f("タクシー会社")}>
              <CreateStepItemButton
                isActive={activeStepNumber === 0}
                isEmpty={!selectedTaxiCompany?.name}
                onClick={() => handleClick(menuItemConfigs[0].path)}
              >
                {selectedTaxiCompany?.name || "未選択"}
              </CreateStepItemButton>
            </CreateStepItem>

            {!isOpenMenu && !isMobile && <Box boxSize={p2r(48)} />}

            <CreateStepItem icon={MdLocalTaxi} name={f("乗車人数")}>
              <RadioGroup
                onChange={(val: string) => {
                  setFormValues({ passengerCount: Number(val) });
                }}
                defaultValue={String(formValues.passengerCount)}
              >
                <Flex
                  direction="row"
                  wrap="wrap"
                  maxW="560px"
                  gap="4px"
                  backgroundColor="grand.white"
                  borderRadius="8px"
                  borderWidth="2px"
                  w="100%"
                  flex="1"
                  padding="4px"
                >
                  <RadioButton value="1">1人</RadioButton>
                  <RadioButton value="2">2人</RadioButton>
                  <RadioButton value="3">3人</RadioButton>
                  <RadioButton value="4">4人</RadioButton>
                  <RadioButton value="5">
                    <Stack direction="row" alignItems="baseline" spacing="0">
                      <Text>5</Text>
                      <Text fontSize="xs">人以上</Text>
                    </Stack>
                  </RadioButton>
                </Flex>
              </RadioGroup>
            </CreateStepItem>
          </Flex>
          <Flex
            direction={isOpenMenu ? "column" : "row"}
            columnGap="24px"
            rowGap="16px"
            align="flex-end"
          >
            <CreateStepItem icon={MdHail} name={f("乗車場所")}>
              <CreateStepItemButton
                isActive={activeStepNumber === 1}
                isEmpty={!selectedStartTaxiLandmark?.name}
                onClick={() => handleClick(menuItemConfigs[1].path)}
              >
                {selectedStartTaxiLandmark?.name || "未選択"}
              </CreateStepItemButton>
            </CreateStepItem>

            {/* 往路の乗車場所と目的地を入れ替え */}
            {!isOpenMenu && <ReverseButton onClick={reverseLandmarkChange} />}

            <CreateStepItem icon={MdFlag} name={f("目的地")}>
              <CreateStepItemButton
                isActive={activeStepNumber === 2}
                isEmpty={!selectedEndTaxiLandmark?.name}
                onClick={() => handleClick(menuItemConfigs[2].path)}
              >
                {selectedEndTaxiLandmark?.name || "未選択"}
              </CreateStepItemButton>
            </CreateStepItem>
          </Flex>

          <Flex
            direction={isOpenMenu || isMobile ? "column" : "row"}
            columnGap="24px"
            rowGap="16px"
            pb={isOpenMenu ? "0px" : "16px"}
          >
            <CreateStepItem icon={Calendar} name={f("日にち")}>
              <CreateStepItemButton
                isActive={activeStepNumber === 3}
                isEmpty={!selectedRideDate}
                onClick={() => handleClick(menuItemConfigs[3].path)}
              >
                {selectedRideDate || "未選択"}
              </CreateStepItemButton>
            </CreateStepItem>
            {!isOpenMenu && !isMobile && <Box boxSize={p2r(48)} />}
            <CreateStepItem icon={MdWatchLater} name={f("時間")}>
              <CreateStepItemButton
                isActive={activeStepNumber === 4}
                isEmpty={!selectedRideTime}
                onClick={() => handleClick(menuItemConfigs[4].path)}
              >
                {selectedRideTime || "未選択"}
              </CreateStepItemButton>
            </CreateStepItem>
          </Flex>

          <Box
            mt={isOpenMenu ? "16px" : "0px"}
            mx="auto"
            w="100%"
            maxW="800px"
            h="2px"
            backgroundColor="grand.grayLight"
          />

          <CreateStepItem
            icon={MdInfoOutline}
            name={f("タクシー会社への連絡事項")}
          >
            <Box w="100%">
              <InputText
                width="100%"
                variant="outlined"
                placeholder="大きな荷物がある・乗車位置の目印等を入力"
                value={formValues.userMessage || ""}
                onChange={(e) => setFormValues({ userMessage: e.target.value })}
                style={{
                  fontSize: isMobile ? p2r(16) : p2r(24),
                  color: "text.body",
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    borderColor: "grand.grayLight",
                    borderWidth: "2px",
                  },
                }}
              />
            </Box>
          </CreateStepItem>

          <CreateStepItem icon={Return} name={f("帰り道の予約")}>
            <Box position="relative">
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      as={Button}
                      rightIcon={
                        <Icon
                          as={MdArrowForwardIos}
                          color="grand.gray"
                          boxSize={p2r(24)}
                        />
                      }
                      pr="8px"
                      height={isMobile ? p2r(48) : p2r(52)}
                      fontSize={isMobile ? p2r(16) : p2r(24)}
                      borderWidth="2px"
                      borderColor={isOpen ? "theme.primary" : "grand.grayLight"}
                      backgroundColor="grand.white"
                      textAlign="left"
                      color="text.body"
                      fontWeight="700"
                      variant="ghost"
                      placeholder="未選択"
                      w="100%"
                      _active={{
                        background: "grand.white",
                      }}
                    >
                      {formValues.isInBound ? "予約する" : "予約しない"}
                    </MenuButton>
                    <MenuList
                      py="0px"
                      borderWidth="2px"
                      borderColor="grand.grayLight"
                    >
                      <MenuItem
                        onClick={() => {
                          setFormValues({
                            isInBound: false,
                          });
                          setTaxiRideDateTime({
                            returnTime: {
                              hour: 0,
                              minute: 0,
                            },
                          });
                        }}
                        fontSize={isMobile ? p2r(16) : p2r(24)}
                        px="32px"
                        py="16px"
                        color="text.body"
                        _notLast={{
                          borderBottomWidth: "1px",
                          borderBottomColor: "grand.grayLight",
                        }}
                        _focus={{
                          background: "grand.white",
                        }}
                      >
                        予約しない
                      </MenuItem>
                      <MenuItem
                        onClick={changeOnInBound}
                        fontSize={isMobile ? p2r(16) : p2r(24)}
                        px="32px"
                        py="16px"
                        color="text.body"
                        _notLast={{
                          borderBottomWidth: "1px",
                          borderBottomColor: "grand.grayLight",
                        }}
                        _focus={{
                          background: "grand.white",
                        }}
                      >
                        予約する
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>
          </CreateStepItem>

          {formValues.isInBound && (
            <Box mt={isMobile ? "0px" : "16px"}>
              {!isMobile && (
                <Text fontSize={p2r(24)} fontWeight="700" color="text.sub">
                  帰り道の予約
                </Text>
              )}
              <Flex
                mt={isMobile ? "0px" : "16px"}
                direction={isOpenMenu ? "column" : "row"}
                columnGap="24px"
                rowGap="16px"
                align="flex-end"
              >
                {/* <CreateStepItem icon={MdHail} name={f('乗車場所を選ぶ')}>
                  <CreateStepItemButton
                    isActive={activeStepNumber === 5}
                    isEmpty={!selectedReturnStartTaxiLandmark?.name}
                    onClick={() => handleClick(menuItemConfigs[5].path)}
                  >
                    {selectedReturnStartTaxiLandmark?.name || '未選択'}
                  </CreateStepItemButton>
                </CreateStepItem> */}

                {/* 帰路の乗車場所と目的地を入れ替え */}
                {/* {!isOpenMenu && (
                  <ReverseButton onClick={reverseReturnLandmarkChange} />
                )} */}

                {/* <CreateStepItem icon={MdFlag} name={f('目的地を選ぶ')}>
                  <CreateStepItemButton
                    isActive={activeStepNumber === 6}
                    isEmpty={!selectedReturnEndTaxiLandmark?.name}
                    onClick={() => handleClick(menuItemConfigs[6].path)}
                  >
                    {selectedReturnEndTaxiLandmark?.name || '未選択'}
                  </CreateStepItemButton>
                </CreateStepItem> */}
              </Flex>

              <Flex
                mt="16px"
                direction={isOpenMenu || isMobile ? "column" : "row"}
                columnGap="24px"
                rowGap="16px"
                pb={isOpenMenu ? "0px" : "16px"}
              >
                <CreateStepItem icon={Calendar} name={f("日にちを選ぶ")}>
                  <CreateStepItemButton
                    isActive={activeStepNumber === 7}
                    isEmpty={!selectedReturnRideDate}
                    onClick={() => handleClick(menuItemConfigs[7].path)}
                  >
                    {selectedReturnRideDate || "未選択"}
                  </CreateStepItemButton>
                </CreateStepItem>
                {!isOpenMenu && !isMobile && <Box boxSize={p2r(48)} />}
                <CreateStepItem icon={MdWatchLater} name={f("時間を選ぶ")}>
                  <CreateStepItemButton
                    isActive={activeStepNumber === 8}
                    isEmpty={!selectedReturnRideTime}
                    onClick={() => handleClick(menuItemConfigs[8].path)}
                  >
                    {selectedReturnRideTime || "未選択"}
                  </CreateStepItemButton>
                </CreateStepItem>
              </Flex>
            </Box>
          )}

          <Box mx="auto" mt="16px">
            <TaxiButton
              disabled={!enableSubmit}
              onClick={() => {
                navigate("/taxi/reservations/create/confirm");
              }}
            >
              {f("仮予約を申し込む")}
            </TaxiButton>
          </Box>
        </Flex>
        {/* メイン */}
        {isOpenMenu && (
          <Box
            flex="1"
            px={isMobile ? "0px" : "32px"}
            py={isMobile ? "16px" : "24px"}
            backgroundColor="grand.white"
            borderLeftWidth="1px"
            borderLeftColor="grand.grayLight"
          >
            {!isMobile && (
              <Button
                mb="16px"
                boxSize={p2r(48)}
                p="8px"
                borderRadius="8px"
                backgroundColor="grand.graySuperLight"
                onClick={() => {
                  navigate("/taxi/reservations/create");
                  setIsOpenMenu(false);
                }}
              >
                <Icon as={MdClose} boxSize={p2r(32)} color="grand.gray" />
              </Button>
            )}
            <Outlet />
          </Box>
        )}
      </>
    </Flex>
  );
};

export default TaxiReservationCreate;
