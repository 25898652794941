import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react'
import { MdPersonAddAlt1 } from 'react-icons/md'
import { Link, Navigate } from 'react-router-dom'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

const useSetVideoCategories = () => {
  const { f } = useFormatMessage()
  return [
    {
      id: 1,
      path: '/video/private',
      title: f('個別通話'),
      imgPath: '/images/video/video_1on1_img.svg',
      note: f('リストから友達を探す'),
    },
    {
      id: 2,
      path: '/video/group',
      title: f('グループ通話'),
      imgPath: '/images/video/video_group_img.svg',
      note: f('複数人でのビデオ通話はこちらから'),
    },
  ]
}

/**
 * イラスト付きのリンク
 * -> タクシー配車などで使用されていたためコンポーネント化しております
 */
type AppIllustLinkProps = {
  label: string
  image: string
  path: string
}
const AppIllustLink: React.FC<AppIllustLinkProps> = ({
  label,
  image,
  path,
}) => (
  <Link to={path}>
    <Box
      display="flex"
      flexDirection="column"
      backgroundColor="grand.white"
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
    >
      <Flex
        align="center"
        justify="center"
        h="238px"
        borderWidth="4px"
        borderColor="rgba(106, 164, 59, 0.8)"
        borderRadius="8px 8px 0 0"
        background="linear-gradient(180deg, #6DB038 0%, #8BD054 100%)"
      >
        <Image src={image} />
      </Flex>
      <Text
        py="4px"
        color="text.headline"
        fontSize={p2r(28)}
        textAlign="center"
        fontWeight="700"
        borderWidth="4px"
        borderTopWidth="0px"
        borderColor="rgba(106, 164, 59, 0.8)"
        borderRadius="0 0 8px 8px"
      >
        {label}
      </Text>
    </Box>
  </Link>
)

const VideoHome: React.FC = () => {
  useHeaderConfig({
    title: 'ビデオ通話',
  })
  const categories = useSetVideoCategories()
  const { f } = useFormatMessage()
  // スマホの場合、個別通話ページへ転送
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  if (isMobile) {
    return <Navigate to="/video/private" replace />
  }
  return (
    <>
      <Box pt="40px" mx="32px">
        <Box mb="20px" textAlign="center">
          <Text color="red" fontSize={p2r(14)} fontWeight="700">
            ビデオ通話機能の更新につき、アプリストアから「更新」を行ってください。
          </Text>
        </Box>
        <HStack spacing="24px" justify="center">
          {categories.map((category) => (
            <Box key={`videoCategory-${category.id}`} w="360px">
              <AppIllustLink
                label={category.title}
                image={category.imgPath}
                path={category.path}
              />
              <Text
                pt="16px"
                fontSize={p2r(18)}
                color="grand.gray"
                textAlign="center"
                fontWeight="700"
                letterSpacing="0.05em"
              >
                {category.note}
              </Text>
            </Box>
          ))}
        </HStack>
      </Box>
      <Box mx="auto" mt="40px" w={p2r(745)}>
        <Link to="/video/friend-add">
          <Flex
            py="8px"
            align="center"
            justify="center"
            columnGap="16px"
            backgroundColor="grand.white"
            borderWidth="4px"
            borderColor="rgba(106, 164, 59, 0.8)"
            borderRadius="8px"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
          >
            <Icon as={MdPersonAddAlt1} boxSize={p2r(56)} color="text.body" />
            <Text fontSize={p2r(28)} color="text.body" fontWeight="700">
              {f('友達を追加')}
            </Text>
          </Flex>
        </Link>
        <Text
          pt="16px"
          fontSize={p2r(18)}
          color="grand.gray"
          textAlign="center"
          fontWeight="700"
          letterSpacing="0.05em"
        >
          {f('新しい友達の追加はこちらから')}
        </Text>
      </Box>
    </>
  )
}

export default VideoHome
