/* tslint:disable */
/* eslint-disable */
/**
 * god-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AreaCreateRequest
 */
export interface AreaCreateRequest {
    /**
     * エリア名
     * @type {string}
     * @memberof AreaCreateRequest
     */
    'name': string;
    /**
     * エリアコード
     * @type {string}
     * @memberof AreaCreateRequest
     */
    'code': string;
    /**
     * 場所ID
     * @type {number}
     * @memberof AreaCreateRequest
     */
    'placeId': number;
}
/**
 * 
 * @export
 * @interface AreaCreateResponse
 */
export interface AreaCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof AreaCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface AreaListResponse
 */
export interface AreaListResponse {
    /**
     * 
     * @type {number}
     * @memberof AreaListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<AreaResponse>}
     * @memberof AreaListResponse
     */
    'areas': Array<AreaResponse>;
}
/**
 * 
 * @export
 * @interface AreaResponse
 */
export interface AreaResponse {
    /**
     * 
     * @type {number}
     * @memberof AreaResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AreaResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AreaUpdateRequest
 */
export interface AreaUpdateRequest {
    /**
     * エリア名
     * @type {string}
     * @memberof AreaUpdateRequest
     */
    'name': string;
    /**
     * エリアコード
     * @type {string}
     * @memberof AreaUpdateRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface AuthMessage
 */
export interface AuthMessage {
    /**
     * 
     * @type {string}
     * @memberof AuthMessage
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof AuthMessage
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface ContentAreaResponse
 */
export interface ContentAreaResponse {
    /**
     * 
     * @type {number}
     * @memberof ContentAreaResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentAreaResponse
     */
    'contentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentAreaResponse
     */
    'areaId'?: number;
}
/**
 * 
 * @export
 * @interface ContentCreateMusenRequest
 */
export interface ContentCreateMusenRequest {
    /**
     * タイトル(日本語)
     * @type {string}
     * @memberof ContentCreateMusenRequest
     */
    'titleJa': string;
    /**
     * ボディ(日本語)
     * @type {string}
     * @memberof ContentCreateMusenRequest
     */
    'bodyJa': string;
    /**
     * 警戒レベル（1〜5) (緊急情報のみ使用する)
     * @type {number}
     * @memberof ContentCreateMusenRequest
     */
    'kinkyuLevel': number | null;
    /**
     * 
     * @type {string}
     * @memberof ContentCreateMusenRequest
     */
    'musenAudioFileKey': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContentCreateMusenRequest
     */
    'bousaiPlaceCodes': Array<string>;
}
/**
 * 
 * @export
 * @interface ContentCreateMusenResponse
 */
export interface ContentCreateMusenResponse {
    /**
     * 
     * @type {number}
     * @memberof ContentCreateMusenResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ContentCreateRequest
 */
export interface ContentCreateRequest {
    /**
     * コンテンツのタイプ
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'type': ContentCreateRequestTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContentCreateRequest
     */
    'areaIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContentCreateRequest
     */
    'placeIds': Array<number>;
    /**
     * タイトル(日本語)
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'titleJa': string;
    /**
     * タイトル(英語)
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'titleEn': string;
    /**
     * ボディ(日本語)
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'bodyJa': string;
    /**
     * ボディ(英語)
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'bodyEn': string;
    /**
     * 配信時刻
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'releaseAt'?: string | null;
    /**
     * 詳細をどう表示するかについてのタイプ
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'detailType': string;
    /**
     * 投稿ステータス draft(下書き), public(公開)
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'status': string;
    /**
     * スラッグ
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'slug': string;
    /**
     * 警戒レベル（1〜5) (緊急情報のみ使用する)
     * @type {number}
     * @memberof ContentCreateRequest
     */
    'kinkyuLevel'?: number | null;
    /**
     * 動画のurl (まちの出来事のみ使用する)
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'dekigotoVideoUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'imageFileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'musenAudioFileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'kairanPdfFileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'bousaiPdfFileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentCreateRequest
     */
    'bousaiImageFileKey'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContentCreateRequest
     */
    'tagLabels'?: Array<string>;
}

export const ContentCreateRequestTypeEnum = {
    Musen: 'musen',
    Kairan: 'kairan',
    Dekigoto: 'dekigoto',
    Bousai: 'bousai',
    Kinkyu: 'kinkyu'
} as const;

export type ContentCreateRequestTypeEnum = typeof ContentCreateRequestTypeEnum[keyof typeof ContentCreateRequestTypeEnum];

/**
 * 
 * @export
 * @interface ContentCreateResponse
 */
export interface ContentCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof ContentCreateResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ContentLikeRequest
 */
export interface ContentLikeRequest {
    /**
     * Likeするかどうか
     * @type {boolean}
     * @memberof ContentLikeRequest
     */
    'isLiked': boolean;
}
/**
 * 
 * @export
 * @interface ContentListResponse
 */
export interface ContentListResponse {
    /**
     * 
     * @type {number}
     * @memberof ContentListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<ContentResponse>}
     * @memberof ContentListResponse
     */
    'contents': Array<ContentResponse>;
}
/**
 * 
 * @export
 * @interface ContentPlaceResponse
 */
export interface ContentPlaceResponse {
    /**
     * 
     * @type {number}
     * @memberof ContentPlaceResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentPlaceResponse
     */
    'contentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentPlaceResponse
     */
    'placeId'?: number;
    /**
     * 
     * @type {PlaceResponse}
     * @memberof ContentPlaceResponse
     */
    'place'?: PlaceResponse | null;
}
/**
 * 
 * @export
 * @interface ContentReadRequest
 */
export interface ContentReadRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ContentReadRequest
     */
    'contentIds': Array<number>;
}
/**
 * 
 * @export
 * @interface ContentResponse
 */
export interface ContentResponse {
    /**
     * コンテンツID
     * @type {number}
     * @memberof ContentResponse
     */
    'id': number;
    /**
     * スラッグ
     * @type {string}
     * @memberof ContentResponse
     */
    'slug': string;
    /**
     * コンテンツのタイプ
     * @type {string}
     * @memberof ContentResponse
     */
    'type': string;
    /**
     * 
     * @type {Array<ContentAreaResponse>}
     * @memberof ContentResponse
     */
    'contentAreas'?: Array<ContentAreaResponse>;
    /**
     * 
     * @type {Array<ContentPlaceResponse>}
     * @memberof ContentResponse
     */
    'contentPlaces'?: Array<ContentPlaceResponse>;
    /**
     * タイトル(日本語)
     * @type {string}
     * @memberof ContentResponse
     */
    'titleJa': string;
    /**
     * タイトル(英語)
     * @type {string}
     * @memberof ContentResponse
     */
    'titleEn': string;
    /**
     * ボディ(日本語)
     * @type {string}
     * @memberof ContentResponse
     */
    'bodyJa': string;
    /**
     * ボディ(英語)
     * @type {string}
     * @memberof ContentResponse
     */
    'bodyEn': string;
    /**
     * 配信時刻
     * @type {string}
     * @memberof ContentResponse
     */
    'releaseAt': string | null;
    /**
     * 詳細をどう表示するかについてのタイプ
     * @type {string}
     * @memberof ContentResponse
     */
    'detailType': string;
    /**
     * 
     * @type {UserResponse}
     * @memberof ContentResponse
     */
    'createdByUser'?: UserResponse | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof ContentResponse
     */
    'updatedByUser'?: UserResponse | null;
    /**
     * 投稿ステータス draft(下書き), public(公開)
     * @type {string}
     * @memberof ContentResponse
     */
    'status': string;
    /**
     * 警戒レベル（1〜5) (緊急情報のみ使用する)
     * @type {number}
     * @memberof ContentResponse
     */
    'kinkyuLevel'?: number | null;
    /**
     * 動画のurl (まちの出来事のみ使用する)
     * @type {string}
     * @memberof ContentResponse
     */
    'dekigotoVideoUrl'?: string | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof ContentResponse
     */
    'createdAt': string | null;
    /**
     * 更新日時
     * @type {string}
     * @memberof ContentResponse
     */
    'updatedAt': string | null;
    /**
     * ファイル一覧
     * @type {Array<FileResponse>}
     * @memberof ContentResponse
     */
    'files'?: Array<FileResponse>;
    /**
     * いいね数
     * @type {number}
     * @memberof ContentResponse
     */
    'likeCount'?: number | null;
    /**
     * いいね済みかどうか
     * @type {boolean}
     * @memberof ContentResponse
     */
    'isLiked'?: boolean | null;
    /**
     * 既読かどうか
     * @type {boolean}
     * @memberof ContentResponse
     */
    'isRead'?: boolean | null;
    /**
     * タグ一覧
     * @type {Array<ContentTagResponse>}
     * @memberof ContentResponse
     */
    'contentTags'?: Array<ContentTagResponse>;
}
/**
 * 
 * @export
 * @interface ContentTagListResponse
 */
export interface ContentTagListResponse {
    /**
     * 
     * @type {number}
     * @memberof ContentTagListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<ContentTagResponse>}
     * @memberof ContentTagListResponse
     */
    'contentTags': Array<ContentTagResponse>;
}
/**
 * 
 * @export
 * @interface ContentTagResponse
 */
export interface ContentTagResponse {
    /**
     * コンテンツタグID
     * @type {number}
     * @memberof ContentTagResponse
     */
    'id': number;
    /**
     * コンテンツタグのラベル
     * @type {string}
     * @memberof ContentTagResponse
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface ContentTagUpdateRequest
 */
export interface ContentTagUpdateRequest {
    /**
     * ラベル
     * @type {string}
     * @memberof ContentTagUpdateRequest
     */
    'label': string;
}
/**
 * 
 * @export
 * @interface ContentUpdateRequest
 */
export interface ContentUpdateRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ContentUpdateRequest
     */
    'areaIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContentUpdateRequest
     */
    'placeIds': Array<number>;
    /**
     * タイトル(日本語)
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'titleJa': string;
    /**
     * タイトル(英語)
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'titleEn': string;
    /**
     * ボディ(日本語)
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'bodyJa': string;
    /**
     * ボディ(英語)
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'bodyEn': string;
    /**
     * 配信時刻
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'releaseAt': string | null;
    /**
     * 詳細をどう表示するかについてのタイプ
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'detailType': string;
    /**
     * 投稿ステータス draft(下書き), public(公開)
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'status': string;
    /**
     * スラッグ
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'slug': string;
    /**
     * 警戒レベル（1〜5) (緊急情報のみ使用する)
     * @type {number}
     * @memberof ContentUpdateRequest
     */
    'kinkyuLevel'?: number | null;
    /**
     * 動画のurl (まちの出来事のみ使用する)
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'dekigotoVideoUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'imageFileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'musenAudioFileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'kairanPdfFileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'bousaiPdfFileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentUpdateRequest
     */
    'bousaiImageFileKey'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContentUpdateRequest
     */
    'tagLabels'?: Array<string>;
    /**
     * 削除するfileのid
     * @type {Array<number>}
     * @memberof ContentUpdateRequest
     */
    'removeFileIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeviceTokenCreateRequest
 */
export interface DeviceTokenCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceTokenCreateRequest
     */
    'channelType'?: DeviceTokenCreateRequestChannelTypeEnum;
    /**
     * デバイストークン
     * @type {string}
     * @memberof DeviceTokenCreateRequest
     */
    'token'?: string;
}

export const DeviceTokenCreateRequestChannelTypeEnum = {
    Apns: 'APNS',
    ApnsVoip: 'APNS_VOIP',
    Gcm: 'GCM'
} as const;

export type DeviceTokenCreateRequestChannelTypeEnum = typeof DeviceTokenCreateRequestChannelTypeEnum[keyof typeof DeviceTokenCreateRequestChannelTypeEnum];

/**
 * 
 * @export
 * @interface DeviceTokenDeleteRequest
 */
export interface DeviceTokenDeleteRequest {
    /**
     * デバイストークン
     * @type {string}
     * @memberof DeviceTokenDeleteRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface FilePresignPutUrlResponse
 */
export interface FilePresignPutUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof FilePresignPutUrlResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof FilePresignPutUrlResponse
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface FileResponse
 */
export interface FileResponse {
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'createdAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'updatedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    'contentId': number | null;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'ext': string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'cloudFrontUrl': string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'imgixUrl': string;
}
/**
 * 
 * @export
 * @interface FriendCreateRequest
 */
export interface FriendCreateRequest {
    /**
     * 友達のコード
     * @type {string}
     * @memberof FriendCreateRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface FriendCreateResponse
 */
export interface FriendCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof FriendCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface FriendResponse
 */
export interface FriendResponse {
    /**
     * ID
     * @type {number}
     * @memberof FriendResponse
     */
    'id': number;
    /**
     * ユーザーID
     * @type {number}
     * @memberof FriendResponse
     */
    'userId': number;
    /**
     * 友達IDユーザーID
     * @type {number}
     * @memberof FriendResponse
     */
    'friendUserId'?: number;
    /**
     * 非表示状態か
     * @type {boolean}
     * @memberof FriendResponse
     */
    'isHidden': boolean;
    /**
     * ブロック状態か
     * @type {boolean}
     * @memberof FriendResponse
     */
    'isBlocked': boolean;
    /**
     * ステータス
     * @type {string}
     * @memberof FriendResponse
     */
    'status': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof FriendResponse
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof FriendResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {UserResponse}
     * @memberof FriendResponse
     */
    'friendUser'?: UserResponse | null;
}
/**
 * 
 * @export
 * @interface FriendUpdateRequest
 */
export interface FriendUpdateRequest {
    /**
     * 非表示にするか
     * @type {boolean}
     * @memberof FriendUpdateRequest
     */
    'isHidden'?: boolean;
    /**
     * ブロックするか
     * @type {boolean}
     * @memberof FriendUpdateRequest
     */
    'isBlocked'?: boolean;
    /**
     * ステータス
     * @type {string}
     * @memberof FriendUpdateRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface MeDetailResponse
 */
export interface MeDetailResponse {
    /**
     * 
     * @type {UserResponse}
     * @memberof MeDetailResponse
     */
    'user'?: UserResponse | null;
}
/**
 * 
 * @export
 * @interface MeUpdateRequest
 */
export interface MeUpdateRequest {
    /**
     * お名前
     * @type {string}
     * @memberof MeUpdateRequest
     */
    'nickname'?: string | null;
    /**
     * お名前(カナ)
     * @type {string}
     * @memberof MeUpdateRequest
     */
    'nicknameKana'?: string | null;
    /**
     * プレイスID
     * @type {number}
     * @memberof MeUpdateRequest
     */
    'placeId'?: number | null;
    /**
     * フォントサイズ (1: 小, 2: 中, 3: 大)
     * @type {number}
     * @memberof MeUpdateRequest
     */
    'cnfFontSize'?: number | null;
    /**
     * 会議終了時に確認するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfConfirmAtLeavingMeeting'?: boolean | null;
    /**
     * 会議開始時にビデオを有効にするか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfEnableVideoAtStartOfMeeting'?: boolean | null;
    /**
     * 会議開始時にマイクを有効にするか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfEnableMicAtStartOfMeeting'?: boolean | null;
    /**
     * 友達からの招待を許可するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfAllowInviteFromFriends'?: boolean | null;
    /**
     * タクシー予約確定時に通知を送信するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfSendNotifTaxiReservationFixed'?: boolean | null;
    /**
     * タクシー予約キャンセル時に通知を送信するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfSendNotifTaxiReservationRejected'?: boolean | null;
    /**
     * タクシー確定予約リマインド通知を送信するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfSendNotifTaxiReservationReminder'?: boolean | null;
    /**
     * ゴミの日の通知(当日)を送信するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfSendNotifTrashDay'?: boolean | null;
    /**
     * ゴミの日の通知(前日)を送信するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfSendNotifTrashDayBefore'?: boolean | null;
    /**
     * 防災無線の通知を送信するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfSendNotifBousai'?: boolean | null;
    /**
     * 電子回覧板の通知を送信するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfSendNotifKairan'?: boolean | null;
    /**
     * まちの出来事の通知を送信するか
     * @type {boolean}
     * @memberof MeUpdateRequest
     */
    'cnfSendNotifDekigoto'?: boolean | null;
    /**
     * 言語
     * @type {string}
     * @memberof MeUpdateRequest
     */
    'cnfLanguage'?: string | null;
    /**
     * ゴミカレンダーID
     * @type {number}
     * @memberof MeUpdateRequest
     */
    'trashCalendarId'?: number | null;
}
/**
 * 
 * @export
 * @interface NotificationListResponse
 */
export interface NotificationListResponse {
    /**
     * 
     * @type {number}
     * @memberof NotificationListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<NotificationResponse>}
     * @memberof NotificationListResponse
     */
    'notifications': Array<NotificationResponse>;
}
/**
 * 
 * @export
 * @interface NotificationReadRequest
 */
export interface NotificationReadRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof NotificationReadRequest
     */
    'notificationIds': Array<number>;
}
/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * 通知ID
     * @type {number}
     * @memberof NotificationResponse
     */
    'id': number;
    /**
     * 通知のタイプ
     * @type {string}
     * @memberof NotificationResponse
     */
    'type': string;
    /**
     * タイトル(日本語)
     * @type {string}
     * @memberof NotificationResponse
     */
    'titleJa'?: string;
    /**
     * タイトル(英語)
     * @type {string}
     * @memberof NotificationResponse
     */
    'titleEn'?: string;
    /**
     * 本文(日本語)
     * @type {string}
     * @memberof NotificationResponse
     */
    'bodyJa'?: string;
    /**
     * 本文(英語)
     * @type {string}
     * @memberof NotificationResponse
     */
    'bodyEn'?: string;
    /**
     * 作成日時
     * @type {string}
     * @memberof NotificationResponse
     */
    'createdAt'?: string | null;
    /**
     * 更新日時
     * @type {string}
     * @memberof NotificationResponse
     */
    'updatedAt'?: string | null;
    /**
     * 通知日時
     * @type {string}
     * @memberof NotificationResponse
     */
    'scheduledTime'?: string;
    /**
     * 既読かどうか
     * @type {boolean}
     * @memberof NotificationResponse
     */
    'isRead'?: boolean | null;
}
/**
 * 
 * @export
 * @interface OauthAuthorizeRequest
 */
export interface OauthAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof OauthAuthorizeRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OauthAuthorizeRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof OauthAuthorizeRequest
     */
    'authCode': string;
}
/**
 * 
 * @export
 * @interface OauthAuthorizeResponse
 */
export interface OauthAuthorizeResponse {
    /**
     * 
     * @type {string}
     * @memberof OauthAuthorizeResponse
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface OauthTokenResponse
 */
export interface OauthTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof OauthTokenResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof OauthTokenResponse
     */
    'token_type': string;
    /**
     * 
     * @type {number}
     * @memberof OauthTokenResponse
     */
    'expires_in': number;
    /**
     * 
     * @type {string}
     * @memberof OauthTokenResponse
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface PlaceCreateRequest
 */
export interface PlaceCreateRequest {
    /**
     * 場所名
     * @type {string}
     * @memberof PlaceCreateRequest
     */
    'name': string;
    /**
     * 場所コード
     * @type {string}
     * @memberof PlaceCreateRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PlaceCreateResponse
 */
export interface PlaceCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof PlaceCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PlaceListResponse
 */
export interface PlaceListResponse {
    /**
     * 
     * @type {number}
     * @memberof PlaceListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<PlaceResponse>}
     * @memberof PlaceListResponse
     */
    'places': Array<PlaceResponse>;
}
/**
 * 
 * @export
 * @interface PlaceResponse
 */
export interface PlaceResponse {
    /**
     * 
     * @type {number}
     * @memberof PlaceResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PlaceResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceResponse
     */
    'code'?: string;
    /**
     * 
     * @type {Array<AreaResponse>}
     * @memberof PlaceResponse
     */
    'areas'?: Array<AreaResponse>;
}
/**
 * 
 * @export
 * @interface PlaceUpdateRequest
 */
export interface PlaceUpdateRequest {
    /**
     * 場所名
     * @type {string}
     * @memberof PlaceUpdateRequest
     */
    'name': string;
    /**
     * 場所コード
     * @type {string}
     * @memberof PlaceUpdateRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface RoomConnectionUpdateRequest
 */
export interface RoomConnectionUpdateRequest {
    /**
     * ルームID
     * @type {number}
     * @memberof RoomConnectionUpdateRequest
     */
    'roomId': number;
    /**
     * 
     * @type {string}
     * @memberof RoomConnectionUpdateRequest
     */
    'action': RoomConnectionUpdateRequestActionEnum;
}

export const RoomConnectionUpdateRequestActionEnum = {
    Join: 'join',
    Leave: 'leave'
} as const;

export type RoomConnectionUpdateRequestActionEnum = typeof RoomConnectionUpdateRequestActionEnum[keyof typeof RoomConnectionUpdateRequestActionEnum];

/**
 * 
 * @export
 * @interface RoomCreateRequest
 */
export interface RoomCreateRequest {
    /**
     * ルーム名
     * @type {string}
     * @memberof RoomCreateRequest
     */
    'name': string;
    /**
     * 開始日時
     * @type {string}
     * @memberof RoomCreateRequest
     */
    'startAt': string;
    /**
     * 終了日時
     * @type {string}
     * @memberof RoomCreateRequest
     */
    'endAt': string | null;
    /**
     * プライベートルームかどうか
     * @type {boolean}
     * @memberof RoomCreateRequest
     */
    'isPrivate': boolean;
}
/**
 * 
 * @export
 * @interface RoomCreateResponse
 */
export interface RoomCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof RoomCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface RoomJoinResponse
 */
export interface RoomJoinResponse {
    /**
     * アクセストークン（JWT）
     * @type {string}
     * @memberof RoomJoinResponse
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface RoomListResponse
 */
export interface RoomListResponse {
    /**
     * 
     * @type {number}
     * @memberof RoomListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<RoomResponse>}
     * @memberof RoomListResponse
     */
    'rooms': Array<RoomResponse>;
}
/**
 * 
 * @export
 * @interface RoomMeetingListResponse
 */
export interface RoomMeetingListResponse {
    /**
     * 
     * @type {number}
     * @memberof RoomMeetingListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<RoomMeetingResponse>}
     * @memberof RoomMeetingListResponse
     */
    'meetings': Array<RoomMeetingResponse>;
}
/**
 * 
 * @export
 * @interface RoomMeetingResponse
 */
export interface RoomMeetingResponse {
    /**
     * ルームミィーティングID
     * @type {number}
     * @memberof RoomMeetingResponse
     */
    'id': number;
    /**
     * テナントID
     * @type {number}
     * @memberof RoomMeetingResponse
     */
    'tenantId': number;
    /**
     * ルームID
     * @type {number}
     * @memberof RoomMeetingResponse
     */
    'roomId': number;
    /**
     * ルームSID
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'roomSid': string;
    /**
     * ルームステータス
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'roomStatus': string | null;
    /**
     * ルーム作成日時
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'roomCreatedAt': string | null;
    /**
     * ルーム終了日時
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'roomEndedAt': string | null;
    /**
     * コンポジションSID
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'compositionSid': string | null;
    /**
     * ステータス
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'compositionStatus': string | null;
    /**
     * メディアURI
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'MediaUri': string | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'updatedAt': string;
    /**
     * コンポジションURL
     * @type {string}
     * @memberof RoomMeetingResponse
     */
    'compositionVideoUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface RoomParticipantResponse
 */
export interface RoomParticipantResponse {
    /**
     * ID
     * @type {number}
     * @memberof RoomParticipantResponse
     */
    'id'?: number;
    /**
     * ルームID
     * @type {number}
     * @memberof RoomParticipantResponse
     */
    'roomId'?: number;
    /**
     * ユーザーID
     * @type {number}
     * @memberof RoomParticipantResponse
     */
    'userId'?: number;
    /**
     * 作成日時
     * @type {string}
     * @memberof RoomParticipantResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {UserResponse}
     * @memberof RoomParticipantResponse
     */
    'user'?: UserResponse | null;
}
/**
 * 
 * @export
 * @interface RoomResponse
 */
export interface RoomResponse {
    /**
     * ルームID
     * @type {number}
     * @memberof RoomResponse
     */
    'id': number;
    /**
     * ルーム名
     * @type {string}
     * @memberof RoomResponse
     */
    'name': string | null;
    /**
     * ルームタイプ
     * @type {string}
     * @memberof RoomResponse
     */
    'type': string;
    /**
     * プライベートルームかどうか
     * @type {boolean}
     * @memberof RoomResponse
     */
    'isPrivate': boolean;
    /**
     * 開始日時
     * @type {string}
     * @memberof RoomResponse
     */
    'startAt': string | null;
    /**
     * 終了日時
     * @type {string}
     * @memberof RoomResponse
     */
    'endAt': string | null;
    /**
     * 参加者
     * @type {Array<RoomParticipantResponse>}
     * @memberof RoomResponse
     */
    'roomParticipants': Array<RoomParticipantResponse>;
    /**
     * 作成日時
     * @type {string}
     * @memberof RoomResponse
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof RoomResponse
     */
    'updatedAt': string;
    /**
     * 参加中のユーザー数
     * @type {number}
     * @memberof RoomResponse
     */
    'activeUserCount'?: number | null;
}
/**
 * 
 * @export
 * @interface RoomUpdateRecordingRuleRequest
 */
export interface RoomUpdateRecordingRuleRequest {
    /**
     * 
     * @type {string}
     * @memberof RoomUpdateRecordingRuleRequest
     */
    'action': RoomUpdateRecordingRuleRequestActionEnum;
}

export const RoomUpdateRecordingRuleRequestActionEnum = {
    Start: 'start',
    Stop: 'stop'
} as const;

export type RoomUpdateRecordingRuleRequestActionEnum = typeof RoomUpdateRecordingRuleRequestActionEnum[keyof typeof RoomUpdateRecordingRuleRequestActionEnum];

/**
 * 
 * @export
 * @interface RoomUpdateRequest
 */
export interface RoomUpdateRequest {
    /**
     * ルーム名
     * @type {string}
     * @memberof RoomUpdateRequest
     */
    'name'?: string;
    /**
     * 開始日時
     * @type {string}
     * @memberof RoomUpdateRequest
     */
    'startAt'?: string;
    /**
     * 終了日時
     * @type {string}
     * @memberof RoomUpdateRequest
     */
    'endAt'?: string | null;
    /**
     * プライベートルームかどうか
     * @type {boolean}
     * @memberof RoomUpdateRequest
     */
    'isPrivate'?: boolean;
    /**
     * 
     * @type {Array<RoomUpdateRequestParticipantsInner>}
     * @memberof RoomUpdateRequest
     */
    'participants'?: Array<RoomUpdateRequestParticipantsInner>;
}
/**
 * 
 * @export
 * @interface RoomUpdateRequestParticipantsInner
 */
export interface RoomUpdateRequestParticipantsInner {
    /**
     * ユーザーID
     * @type {number}
     * @memberof RoomUpdateRequestParticipantsInner
     */
    'userId'?: number;
    /**
     * 操作
     * @type {string}
     * @memberof RoomUpdateRequestParticipantsInner
     */
    'operation'?: string;
}
/**
 * 
 * @export
 * @interface TaxiAchievementCreateRequest
 */
export interface TaxiAchievementCreateRequest {
    /**
     * officeユーザーの場合のみ指定可能
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'taxiCompanyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'taxiCarId': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'customerUserId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementCreateRequest
     */
    'rideAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementCreateRequest
     */
    'dropAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'taxPer': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'initialPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'discountPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'finalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'approachAutoDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'approachAdjustDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'approachAutoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'approachAdjustPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'rideAutoDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'rideAdjustDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'rideAutoPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'rideAdjustPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'waitAutoSec': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'waitAdjustSec': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'waitAutoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'waitAdjustPrice': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementCreateRequest
     */
    'payMethod': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementCreateRequest
     */
    'disabilityDiscount': boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'disabilityDiscountPrice': number;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementCreateRequest
     */
    'returningLicenseDiscount': boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'returningLicenseDiscountPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateRequest
     */
    'otherDiscount': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementCreateRequest
     */
    'tripLog': string;
}
/**
 * 
 * @export
 * @interface TaxiAchievementCreateResponse
 */
export interface TaxiAchievementCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TaxiAchievementListResponse
 */
export interface TaxiAchievementListResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<TaxiAchievementResponse>}
     * @memberof TaxiAchievementListResponse
     */
    'taxiAchievements': Array<TaxiAchievementResponse>;
}
/**
 * 
 * @export
 * @interface TaxiAchievementResponse
 */
export interface TaxiAchievementResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'taxiCompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'taxiCarId': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'driverUserId': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'customerUserId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementResponse
     */
    'rideAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementResponse
     */
    'dropAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'taxPer': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'initialPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'discountPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'finalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'approachAutoDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'approachAdjustDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'approachAutoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'approachAdjustPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'rideAutoDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'rideAdjustDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'rideAutoPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'rideAdjustPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'waitAutoSec': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'waitAdjustSec': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'waitAutoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'waitAdjustPrice': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementResponse
     */
    'payMethod': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementResponse
     */
    'disabilityDiscount': boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'disabilityDiscountPrice': number;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementResponse
     */
    'returningLicenseDiscount': boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'returningLicenseDiscountPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'otherDiscount': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementResponse
     */
    'tripLog': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'createdUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementResponse
     */
    'updatedUserId'?: number | null;
    /**
     * 
     * @type {TaxiCompanyResponse}
     * @memberof TaxiAchievementResponse
     */
    'taxiCompany'?: TaxiCompanyResponse | null;
    /**
     * 
     * @type {TaxiCarResponse}
     * @memberof TaxiAchievementResponse
     */
    'taxiCar'?: TaxiCarResponse | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof TaxiAchievementResponse
     */
    'driverUser'?: UserResponse | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof TaxiAchievementResponse
     */
    'customerUser'?: UserResponse | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof TaxiAchievementResponse
     */
    'createdUser'?: UserResponse | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof TaxiAchievementResponse
     */
    'updatedUser'?: UserResponse | null;
}
/**
 * 
 * @export
 * @interface TaxiAchievementUpdateRequest
 */
export interface TaxiAchievementUpdateRequest {
    /**
     * officeユーザーの場合のみ指定可能
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'taxiCompanyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'taxiCarId': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'customerUserId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementUpdateRequest
     */
    'rideAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementUpdateRequest
     */
    'dropAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'taxPer': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'initialPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'discountPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'finalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'approachAutoDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'approachAdjustDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'approachAutoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'approachAdjustPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'rideAutoDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'rideAdjustDistance': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'rideAutoPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'rideAdjustPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'waitAutoSec': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'waitAdjustSec': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'waitAutoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'waitAdjustPrice': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementUpdateRequest
     */
    'payMethod': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementUpdateRequest
     */
    'disabilityDiscount': boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'disabilityDiscountPrice': number;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementUpdateRequest
     */
    'returningLicenseDiscount': boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'returningLicenseDiscountPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateRequest
     */
    'otherDiscount': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementUpdateRequest
     */
    'tripLog': string;
}
/**
 * 
 * @export
 * @interface TaxiAchievementUpdateResponse
 */
export interface TaxiAchievementUpdateResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementUpdateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TaxiCarListResponse
 */
export interface TaxiCarListResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiCarListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<TaxiCarResponse>}
     * @memberof TaxiCarListResponse
     */
    'taxiCars': Array<TaxiCarResponse>;
}
/**
 * 
 * @export
 * @interface TaxiCarResponse
 */
export interface TaxiCarResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiCarResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiCarResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiCarResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiCarResponse
     */
    'taxiCompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiCarResponse
     */
    'name': string;
    /**
     * 
     * @type {TaxiCompanyResponse}
     * @memberof TaxiCarResponse
     */
    'taxiCompany'?: TaxiCompanyResponse | null;
}
/**
 * 
 * @export
 * @interface TaxiCompanyCreateRequest
 */
export interface TaxiCompanyCreateRequest {
    /**
     * 会社名
     * @type {string}
     * @memberof TaxiCompanyCreateRequest
     */
    'name': string;
    /**
     * 住所
     * @type {string}
     * @memberof TaxiCompanyCreateRequest
     */
    'address': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof TaxiCompanyCreateRequest
     */
    'phone': string;
    /**
     * 営業開始時間
     * @type {string}
     * @memberof TaxiCompanyCreateRequest
     */
    'startBusinessHour': string;
    /**
     * 営業終了時間
     * @type {string}
     * @memberof TaxiCompanyCreateRequest
     */
    'endBusinessHour': string;
}
/**
 * 
 * @export
 * @interface TaxiCompanyCreateResponse
 */
export interface TaxiCompanyCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiCompanyCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TaxiCompanyListResponse
 */
export interface TaxiCompanyListResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiCompanyListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<TaxiCompanyResponse>}
     * @memberof TaxiCompanyListResponse
     */
    'taxiCompanies': Array<TaxiCompanyResponse>;
}
/**
 * 
 * @export
 * @interface TaxiCompanyResponse
 */
export interface TaxiCompanyResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiCompanyResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiCompanyResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiCompanyResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiCompanyResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiCompanyResponse
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiCompanyResponse
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiCompanyResponse
     */
    'startBusinessHour': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiCompanyResponse
     */
    'endBusinessHour': string;
}
/**
 * 
 * @export
 * @interface TaxiCompanyUpdateRequest
 */
export interface TaxiCompanyUpdateRequest {
    /**
     * 会社名
     * @type {string}
     * @memberof TaxiCompanyUpdateRequest
     */
    'name': string;
    /**
     * 住所
     * @type {string}
     * @memberof TaxiCompanyUpdateRequest
     */
    'address': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof TaxiCompanyUpdateRequest
     */
    'phone': string;
    /**
     * 営業開始時間
     * @type {string}
     * @memberof TaxiCompanyUpdateRequest
     */
    'startBusinessHour': string;
    /**
     * 営業終了時間
     * @type {string}
     * @memberof TaxiCompanyUpdateRequest
     */
    'endBusinessHour': string;
}
/**
 * 
 * @export
 * @interface TaxiDailyScheduleListResponse
 */
export interface TaxiDailyScheduleListResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiDailyScheduleListResponse
     */
    'taxiCompanyId': number;
    /**
     * 
     * @type {Array<TaxiDailyScheduleResponse>}
     * @memberof TaxiDailyScheduleListResponse
     */
    'schedules': Array<TaxiDailyScheduleResponse>;
}
/**
 * 
 * @export
 * @interface TaxiDailyScheduleMergeResponse
 */
export interface TaxiDailyScheduleMergeResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiDailyScheduleMergeResponse
     */
    'times': Array<string>;
}
/**
 * 
 * @export
 * @interface TaxiDailyScheduleResponse
 */
export interface TaxiDailyScheduleResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxiDailyScheduleResponse
     */
    'date': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiDailyScheduleResponse
     */
    'times': Array<string>;
}
/**
 * 
 * @export
 * @interface TaxiDailyScheduleSaveRequest
 */
export interface TaxiDailyScheduleSaveRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiDailyScheduleSaveRequest
     */
    'taxiCompanyId': number;
    /**
     * 
     * @type {Array<TaxiDailyScheduleResponse>}
     * @memberof TaxiDailyScheduleSaveRequest
     */
    'schedules': Array<TaxiDailyScheduleResponse>;
}
/**
 * 
 * @export
 * @interface TaxiDailyScheduleSaveScheduleItem
 */
export interface TaxiDailyScheduleSaveScheduleItem {
    /**
     * 
     * @type {string}
     * @memberof TaxiDailyScheduleSaveScheduleItem
     */
    'date': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiDailyScheduleSaveScheduleItem
     */
    'times': Array<string>;
}
/**
 * 
 * @export
 * @interface TaxiLandmarkCreateRequest
 */
export interface TaxiLandmarkCreateRequest {
    /**
     * 名称
     * @type {string}
     * @memberof TaxiLandmarkCreateRequest
     */
    'name': string;
    /**
     * 住所
     * @type {string}
     * @memberof TaxiLandmarkCreateRequest
     */
    'address'?: string;
    /**
     * 地域ID
     * @type {number}
     * @memberof TaxiLandmarkCreateRequest
     */
    'placeId': number;
    /**
     * 緯度経度
     * @type {string}
     * @memberof TaxiLandmarkCreateRequest
     */
    'latLang'?: string;
    /**
     * テナント外
     * @type {boolean}
     * @memberof TaxiLandmarkCreateRequest
     */
    'isOutsideTenant'?: boolean;
}
/**
 * 
 * @export
 * @interface TaxiLandmarkCreateResponse
 */
export interface TaxiLandmarkCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiLandmarkCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TaxiLandmarkListResponse
 */
export interface TaxiLandmarkListResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiLandmarkListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<TaxiLandmarkResponse>}
     * @memberof TaxiLandmarkListResponse
     */
    'taxiLandmarks': Array<TaxiLandmarkResponse>;
}
/**
 * 
 * @export
 * @interface TaxiLandmarkResponse
 */
export interface TaxiLandmarkResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiLandmarkResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiLandmarkResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiLandmarkResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiLandmarkResponse
     */
    'placeId': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiLandmarkResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiLandmarkResponse
     */
    'latLng': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiLandmarkResponse
     */
    'isOutsideTenant': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaxiLandmarkResponse
     */
    'address': string;
    /**
     * 
     * @type {PlaceResponse}
     * @memberof TaxiLandmarkResponse
     */
    'place'?: PlaceResponse | null;
}
/**
 * 
 * @export
 * @interface TaxiLandmarkUpdateRequest
 */
export interface TaxiLandmarkUpdateRequest {
    /**
     * 名称
     * @type {string}
     * @memberof TaxiLandmarkUpdateRequest
     */
    'name': string;
    /**
     * 住所
     * @type {string}
     * @memberof TaxiLandmarkUpdateRequest
     */
    'address'?: string;
    /**
     * 地域ID
     * @type {number}
     * @memberof TaxiLandmarkUpdateRequest
     */
    'placeId': number;
    /**
     * 緯度経度
     * @type {string}
     * @memberof TaxiLandmarkUpdateRequest
     */
    'latLang'?: string;
    /**
     * テナント外
     * @type {boolean}
     * @memberof TaxiLandmarkUpdateRequest
     */
    'isOutsideTenant'?: boolean;
}
/**
 * 
 * @export
 * @interface TaxiReservationCreateByTaxiCompanyRequest
 */
export interface TaxiReservationCreateByTaxiCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'userPhone': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'rideAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'dropAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'startTaxiLandmarkId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'endTaxiLandmarkId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'startAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'endAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'startLatLng': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'endLatLng': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'isInBound': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'returnRideAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'returnDropAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'taxiCarId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'taxiCompanyMessage'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByTaxiCompanyRequest
     */
    'passengerCount': number;
}
/**
 * 
 * @export
 * @interface TaxiReservationCreateByTaxiCompanyResponse
 */
export interface TaxiReservationCreateByTaxiCompanyResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByTaxiCompanyResponse
     */
    'outBoundTaxiReservationId': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByTaxiCompanyResponse
     */
    'inBoundTaxiReservationId': number | null;
}
/**
 * 
 * @export
 * @interface TaxiReservationCreateByUserRequest
 */
export interface TaxiReservationCreateByUserRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'taxiCompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'rideAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'dropAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'startTaxiLandmarkId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'endTaxiLandmarkId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'startAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'endAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'startLatLng': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'endLatLng': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'isInBound': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'returnRideAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'returnDropAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'userMessage': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByUserRequest
     */
    'passengerCount': number;
}
/**
 * 
 * @export
 * @interface TaxiReservationCreateByUserResponse
 */
export interface TaxiReservationCreateByUserResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByUserResponse
     */
    'outBoundTaxiReservationId': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationCreateByUserResponse
     */
    'inBoundTaxiReservationId': number | null;
}
/**
 * 
 * @export
 * @interface TaxiReservationListResponse
 */
export interface TaxiReservationListResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<TaxiReservationResponse>}
     * @memberof TaxiReservationListResponse
     */
    'taxiReservations': Array<TaxiReservationResponse>;
}
/**
 * 
 * @export
 * @interface TaxiReservationResponse
 */
export interface TaxiReservationResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'taxiCompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'taxiCarId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'userPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'rideAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'dropAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'startTaxiLandmarkId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'endTaxiLandmarkId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'startAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'endAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'startLatLng': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'endLatLng': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'outBoundTaxiReservationId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'inBoundTaxiReservationId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'cancelReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'userMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationResponse
     */
    'taxiCompanyMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'passengerCount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TaxiReservationResponse
     */
    'isCreatedByTaxiCompany': boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'createdUserId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationResponse
     */
    'updatedUserId'?: number | null;
    /**
     * 
     * @type {TaxiCompanyResponse}
     * @memberof TaxiReservationResponse
     */
    'taxiCompany'?: TaxiCompanyResponse | null;
    /**
     * 
     * @type {TaxiCarResponse}
     * @memberof TaxiReservationResponse
     */
    'taxiCar'?: TaxiCarResponse | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof TaxiReservationResponse
     */
    'user'?: UserResponse | null;
    /**
     * 
     * @type {TaxiLandmarkResponse}
     * @memberof TaxiReservationResponse
     */
    'startTaxiLandmark'?: TaxiLandmarkResponse | null;
    /**
     * 
     * @type {TaxiLandmarkResponse}
     * @memberof TaxiReservationResponse
     */
    'endTaxiLandmark'?: TaxiLandmarkResponse | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof TaxiReservationResponse
     */
    'createdUser'?: UserResponse | null;
    /**
     * 
     * @type {UserResponse}
     * @memberof TaxiReservationResponse
     */
    'updatedUser'?: UserResponse | null;
}
/**
 * 
 * @export
 * @interface TaxiReservationUpdateByTaxiCompanyRequest
 */
export interface TaxiReservationUpdateByTaxiCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateByTaxiCompanyRequest
     */
    'status': TaxiReservationUpdateByTaxiCompanyRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateByTaxiCompanyRequest
     */
    'taxiCompanyMessage': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateByTaxiCompanyRequest
     */
    'cancelReason'?: string | null;
}

export const TaxiReservationUpdateByTaxiCompanyRequestStatusEnum = {
    Rejected: 'rejected',
    Fixed: 'fixed'
} as const;

export type TaxiReservationUpdateByTaxiCompanyRequestStatusEnum = typeof TaxiReservationUpdateByTaxiCompanyRequestStatusEnum[keyof typeof TaxiReservationUpdateByTaxiCompanyRequestStatusEnum];

/**
 * 
 * @export
 * @interface TaxiReservationUpdateByUserRequest
 */
export interface TaxiReservationUpdateByUserRequest {
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateByUserRequest
     */
    'status': TaxiReservationUpdateByUserRequestStatusEnum;
}

export const TaxiReservationUpdateByUserRequestStatusEnum = {
    Canceled: 'canceled'
} as const;

export type TaxiReservationUpdateByUserRequestStatusEnum = typeof TaxiReservationUpdateByUserRequestStatusEnum[keyof typeof TaxiReservationUpdateByUserRequestStatusEnum];

/**
 * 
 * @export
 * @interface TaxiReservationUpdateRequest
 */
export interface TaxiReservationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'userPhone': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationUpdateRequest
     */
    'taxiCompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'rideAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'dropAt': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationUpdateRequest
     */
    'startTaxiLandmarkId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationUpdateRequest
     */
    'endTaxiLandmarkId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'startAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'endAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'startLatLng': string;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'endLatLng': string;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationUpdateRequest
     */
    'taxiCarId': number | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'userMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxiReservationUpdateRequest
     */
    'taxiCompanyMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationUpdateRequest
     */
    'passengerCount'?: number | null;
}
/**
 * 
 * @export
 * @interface TaxiWeeklyScheduleDayOfWeeksResponse
 */
export interface TaxiWeeklyScheduleDayOfWeeksResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleDayOfWeeksResponse
     */
    'sunTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleDayOfWeeksResponse
     */
    'monTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleDayOfWeeksResponse
     */
    'tueTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleDayOfWeeksResponse
     */
    'wedTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleDayOfWeeksResponse
     */
    'thuTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleDayOfWeeksResponse
     */
    'friTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleDayOfWeeksResponse
     */
    'satTimes': Array<string>;
}
/**
 * 
 * @export
 * @interface TaxiWeeklyScheduleSaveRequest
 */
export interface TaxiWeeklyScheduleSaveRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiWeeklyScheduleSaveRequest
     */
    'taxiCompanyId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleSaveRequest
     */
    'sunTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleSaveRequest
     */
    'monTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleSaveRequest
     */
    'tueTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleSaveRequest
     */
    'wedTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleSaveRequest
     */
    'thuTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleSaveRequest
     */
    'friTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaxiWeeklyScheduleSaveRequest
     */
    'satTimes': Array<string>;
}
/**
 * 
 * @export
 * @interface TenantCreateRequest
 */
export interface TenantCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantCreateRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TenantCreateResponse
 */
export interface TenantCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TenantCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TenantListResponse
 */
export interface TenantListResponse {
    /**
     * 
     * @type {number}
     * @memberof TenantListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<TenantResponse>}
     * @memberof TenantListResponse
     */
    'tenants'?: Array<TenantResponse>;
}
/**
 * 
 * @export
 * @interface TenantResponse
 */
export interface TenantResponse {
    /**
     * 
     * @type {number}
     * @memberof TenantResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TenantResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<PlaceResponse>}
     * @memberof TenantResponse
     */
    'places'?: Array<PlaceResponse>;
}
/**
 * 
 * @export
 * @interface TenantUpdateRequest
 */
export interface TenantUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantUpdateRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TopicSaveByOfficeRequest
 */
export interface TopicSaveByOfficeRequest {
    /**
     * 
     * @type {number}
     * @memberof TopicSaveByOfficeRequest
     */
    'userId': number;
    /**
     * 氏名 最大 100 文字　基本4情報
     * @type {string}
     * @memberof TopicSaveByOfficeRequest
     */
    'commonName': string;
    /**
     * 
     * @type {string}
     * @memberof TopicSaveByOfficeRequest
     */
    'kana'?: string | null;
    /**
     * 住所 最大 200 文字　基本4情報
     * @type {string}
     * @memberof TopicSaveByOfficeRequest
     */
    'address': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof TopicSaveByOfficeRequest
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicSaveByOfficeRequest
     */
    'mailaddress'?: string | null;
    /**
     * 郵便番号
     * @type {string}
     * @memberof TopicSaveByOfficeRequest
     */
    'zipcode'?: string | null;
    /**
     * 割引適用フラグ
     * @type {boolean}
     * @memberof TopicSaveByOfficeRequest
     */
    'taxiDiscountFlag': boolean;
    /**
     * 申込日時
     * @type {string}
     * @memberof TopicSaveByOfficeRequest
     */
    'taxiDiscountAppliedDate': string;
}
/**
 * 
 * @export
 * @interface TopicSaveRequest
 */
export interface TopicSaveRequest {
    /**
     * topicId
     * @type {string}
     * @memberof TopicSaveRequest
     */
    'topicId': string;
    /**
     * 氏名 最大 100 文字　基本4情報
     * @type {string}
     * @memberof TopicSaveRequest
     */
    'commonName': string;
    /**
     * 生年月日 YYYY-MM-DD　基本4情報
     * @type {string}
     * @memberof TopicSaveRequest
     */
    'dateOfBirth': string;
    /**
     * 性別 1：男、2：女、3：不明　基本4情報
     * @type {number}
     * @memberof TopicSaveRequest
     */
    'gender': number;
    /**
     * 住所 最大 200 文字　基本4情報
     * @type {string}
     * @memberof TopicSaveRequest
     */
    'address': string;
    /**
     * カナ名
     * @type {string}
     * @memberof TopicSaveRequest
     */
    'kana'?: string | null;
    /**
     * メールアドレス
     * @type {string}
     * @memberof TopicSaveRequest
     */
    'mailaddress'?: string | null;
    /**
     * 電話番号
     * @type {string}
     * @memberof TopicSaveRequest
     */
    'telephone'?: string | null;
}
/**
 * 
 * @export
 * @interface TopicVerifyRequest
 */
export interface TopicVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof TopicVerifyRequest
     */
    'topicId': string;
}
/**
 * 
 * @export
 * @interface TopicVerifyResponse
 */
export interface TopicVerifyResponse {
    /**
     * タクシー乗車実績登録時に利用する内部ユーザーID
     * @type {number}
     * @memberof TopicVerifyResponse
     */
    'userId': number;
    /**
     * 電脳交通アプリ起動時に利用するID（さあ・くるID）
     * @type {string}
     * @memberof TopicVerifyResponse
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface TrashCalendarCreateRequest
 */
export interface TrashCalendarCreateRequest {
    /**
     * 地区名
     * @type {string}
     * @memberof TrashCalendarCreateRequest
     */
    'name': string;
    /**
     * googleカレンダーID
     * @type {string}
     * @memberof TrashCalendarCreateRequest
     */
    'googleCalendarId': string;
}
/**
 * 
 * @export
 * @interface TrashCalendarCreateResponse
 */
export interface TrashCalendarCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof TrashCalendarCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TrashCalendarDateResponse
 */
export interface TrashCalendarDateResponse {
    /**
     * 
     * @type {number}
     * @memberof TrashCalendarDateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrashCalendarDateResponse
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrashCalendarDateResponse
     */
    'trashType'?: string;
}
/**
 * 
 * @export
 * @interface TrashCalendarListResponse
 */
export interface TrashCalendarListResponse {
    /**
     * 
     * @type {number}
     * @memberof TrashCalendarListResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<TrashCalendarResponse>}
     * @memberof TrashCalendarListResponse
     */
    'trashCalendars': Array<TrashCalendarResponse>;
}
/**
 * 
 * @export
 * @interface TrashCalendarResponse
 */
export interface TrashCalendarResponse {
    /**
     * 
     * @type {number}
     * @memberof TrashCalendarResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrashCalendarResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrashCalendarResponse
     */
    'googleCalendarId'?: string;
    /**
     * 
     * @type {Array<TrashCalendarDateResponse>}
     * @memberof TrashCalendarResponse
     */
    'trashCalendarDates'?: Array<TrashCalendarDateResponse>;
}
/**
 * 
 * @export
 * @interface TrashCalendarUpdateRequest
 */
export interface TrashCalendarUpdateRequest {
    /**
     * 地区名
     * @type {string}
     * @memberof TrashCalendarUpdateRequest
     */
    'name': string;
    /**
     * googleカレンダーID
     * @type {string}
     * @memberof TrashCalendarUpdateRequest
     */
    'googleCalendarId': string;
}
/**
 * 
 * @export
 * @interface UserAuthorizeRequest
 */
export interface UserAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizeRequest
     */
    'emailOrPhone': string;
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizeRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserAuthorizeResponse
 */
export interface UserAuthorizeResponse {
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizeResponse
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'nicknameKana': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserCreateRequest
     */
    'placeId': number;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'manualPassword'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateRequest
     */
    'isOffice'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserCreateRequest
     */
    'taxiCompanyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserCreateRequest
     */
    'trashCalendarId'?: number | null;
}
/**
 * 
 * @export
 * @interface UserCreateResponse
 */
export interface UserCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof UserCreateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface UserInfoResponse
 */
export interface UserInfoResponse {
    /**
     * ユーザーID
     * @type {number}
     * @memberof UserInfoResponse
     */
    'id': number;
    /**
     * 作成日時
     * @type {string}
     * @memberof UserInfoResponse
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof UserInfoResponse
     */
    'updatedAt': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof UserInfoResponse
     */
    'email': string | null;
    /**
     * 電話番号
     * @type {string}
     * @memberof UserInfoResponse
     */
    'phone': string | null;
    /**
     * TOPICID
     * @type {string}
     * @memberof UserInfoResponse
     */
    'topicId'?: string | null;
    /**
     * 氏名
     * @type {string}
     * @memberof UserInfoResponse
     */
    'commonName'?: string;
    /**
     * 氏名(カナ)
     * @type {string}
     * @memberof UserInfoResponse
     */
    'kana'?: string | null;
    /**
     * 住所
     * @type {string}
     * @memberof UserInfoResponse
     */
    'address'?: string;
    /**
     * 連絡先メールアドレス
     * @type {string}
     * @memberof UserInfoResponse
     */
    'mailaddress'?: string | null;
    /**
     * 連絡先電話番号
     * @type {string}
     * @memberof UserInfoResponse
     */
    'telephone'?: string | null;
    /**
     * 郵便番号
     * @type {string}
     * @memberof UserInfoResponse
     */
    'zipcode'?: string | null;
    /**
     * コード
     * @type {string}
     * @memberof UserInfoResponse
     */
    'code': string | null;
}
/**
 * 
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof UserListResponse
     */
    'users'?: Array<UserResponse>;
}
/**
 * 
 * @export
 * @interface UserLoginCodeRequest
 */
export interface UserLoginCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof UserLoginCodeRequest
     */
    'emailOrPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginCodeRequest
     */
    'emailOrPhoneOrCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginCodeRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginCodeRequest
     */
    'authCode': string;
}
/**
 * 
 * @export
 * @interface UserLoginCodeResponse
 */
export interface UserLoginCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof UserLoginCodeResponse
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginCodeResponse
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface UserLoginRequest
 */
export interface UserLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'emailOrPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'emailOrPhoneOrCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserResetPasswordRequest
 */
export interface UserResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordRequest
     */
    'emailOrPhone': string;
}
/**
 * 
 * @export
 * @interface UserResetPasswordVerifyRequest
 */
export interface UserResetPasswordVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordVerifyRequest
     */
    'emailOrPhone': string;
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordVerifyRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordVerifyRequest
     */
    'passwordConfirmation': string;
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordVerifyRequest
     */
    'authCode': string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * ユーザーID
     * @type {number}
     * @memberof UserResponse
     */
    'id': number;
    /**
     * お名前
     * @type {string}
     * @memberof UserResponse
     */
    'nickname': string;
    /**
     * お名前(カナ)
     * @type {string}
     * @memberof UserResponse
     */
    'nicknameKana'?: string;
    /**
     * タクシー割引適用フラグ
     * @type {boolean}
     * @memberof UserResponse
     */
    'taxiDiscountFlag'?: boolean;
    /**
     * タクシー割引適用日時
     * @type {string}
     * @memberof UserResponse
     */
    'taxiDiscountAppliedDate'?: string | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof UserResponse
     */
    'createdAt': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof UserResponse
     */
    'updatedAt': string;
    /**
     * プレイスID
     * @type {number}
     * @memberof UserResponse
     */
    'placeId'?: number | null;
    /**
     * 役場職員フラグ
     * @type {boolean}
     * @memberof UserResponse
     */
    'isOffice'?: boolean;
    /**
     * フォントサイズ (1: 小, 2: 中, 3: 大)
     * @type {number}
     * @memberof UserResponse
     */
    'cnfFontSize'?: number;
    /**
     * 会議終了時に確認するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfConfirmAtLeavingMeeting'?: boolean;
    /**
     * 会議開始時にビデオを有効にするか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfEnableVideoAtStartOfMeeting'?: boolean;
    /**
     * 会議開始時にマイクを有効にするか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfEnableMicAtStartOfMeeting'?: boolean;
    /**
     * 友達からの招待を許可するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfAllowInviteFromFriends'?: boolean;
    /**
     * タクシー予約確定時に通知を送信するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfSendNotifTaxiReservationFixed'?: boolean;
    /**
     * タクシー予約キャンセル時に通知を送信するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfSendNotifTaxiReservationRejected'?: boolean;
    /**
     * タクシー確定予約リマインド通知を送信するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfSendNotifTaxiReservationReminder'?: boolean;
    /**
     * ゴミの日の通知（当日）を送信するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfSendNotifTrashDay'?: boolean;
    /**
     * ゴミの日の通知（前日）を送信するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfSendNotifTrashDayBefore'?: boolean;
    /**
     * 防災無線の通知を送信するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfSendNotifBousai'?: boolean;
    /**
     * 電子回覧板の通知を送信するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfSendNotifKairan'?: boolean;
    /**
     * まちの出来事の通知を送信するか
     * @type {boolean}
     * @memberof UserResponse
     */
    'cnfSendNotifDekigoto'?: boolean;
    /**
     * 言語
     * @type {string}
     * @memberof UserResponse
     */
    'cndLanguage'?: string;
    /**
     * タクシー会社ID
     * @type {number}
     * @memberof UserResponse
     */
    'taxiCompanyId'?: number | null;
    /**
     * ゴミカレンダーID
     * @type {number}
     * @memberof UserResponse
     */
    'trashCalendarId'?: number | null;
    /**
     * 
     * @type {Array<FriendResponse>}
     * @memberof UserResponse
     */
    'friends'?: Array<FriendResponse>;
    /**
     * 
     * @type {TaxiCompanyResponse}
     * @memberof UserResponse
     */
    'taxiCompany'?: TaxiCompanyResponse | null;
    /**
     * 
     * @type {UserInfoResponse}
     * @memberof UserResponse
     */
    'userInfo'?: UserInfoResponse | null;
    /**
     * 
     * @type {PlaceResponse}
     * @memberof UserResponse
     */
    'place'?: PlaceResponse | null;
    /**
     * 
     * @type {TrashCalendarResponse}
     * @memberof UserResponse
     */
    'trashCalendar'?: TrashCalendarResponse | null;
}
/**
 * 
 * @export
 * @interface UserSignupRequest
 */
export interface UserSignupRequest {
    /**
     * 
     * @type {string}
     * @memberof UserSignupRequest
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof UserSignupRequest
     */
    'nicknameKana': string;
    /**
     * 
     * @type {string}
     * @memberof UserSignupRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSignupRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSignupRequest
     */
    'placeId': number;
    /**
     * 
     * @type {string}
     * @memberof UserSignupRequest
     */
    'manualPassword': string;
}
/**
 * 
 * @export
 * @interface UserSignupResponse
 */
export interface UserSignupResponse {
    /**
     * 
     * @type {number}
     * @memberof UserSignupResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface UserTokenRequest
 */
export interface UserTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof UserTokenRequest
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface UserTokenResponse
 */
export interface UserTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof UserTokenResponse
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof UserTokenResponse
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface UserUpdateEmailRequest
 */
export interface UserUpdateEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateEmailRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserUpdateEmailVerifyRequest
 */
export interface UserUpdateEmailVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateEmailVerifyRequest
     */
    'authCode': string;
}
/**
 * 
 * @export
 * @interface UserUpdatePhoneRequest
 */
export interface UserUpdatePhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdatePhoneRequest
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface UserUpdatePhoneVerifyRequest
 */
export interface UserUpdatePhoneVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdatePhoneVerifyRequest
     */
    'authCode': string;
}
/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'nicknameKana': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateRequest
     */
    'placeId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateRequest
     */
    'isOffice'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateRequest
     */
    'taxiCompanyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateRequest
     */
    'trashCalendarId'?: number | null;
}
/**
 * 
 * @export
 * @interface UserUpdateResponse
 */
export interface UserUpdateResponse {
    /**
     * 
     * @type {number}
     * @memberof UserUpdateResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface UserVerifyCodeForTaxiRequest
 */
export interface UserVerifyCodeForTaxiRequest {
    /**
     * 
     * @type {string}
     * @memberof UserVerifyCodeForTaxiRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface UserVerifyCodeForTaxiResponse
 */
export interface UserVerifyCodeForTaxiResponse {
    /**
     * 
     * @type {number}
     * @memberof UserVerifyCodeForTaxiResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface WeatherPointwfResponse
 */
export interface WeatherPointwfResponse {
    /**
     * 
     * @type {string}
     * @memberof WeatherPointwfResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof WeatherPointwfResponse
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof WeatherPointwfResponse
     */
    'lon': number;
    /**
     * 
     * @type {Array<WeatherPointwfResponseData>}
     * @memberof WeatherPointwfResponse
     */
    'data': Array<WeatherPointwfResponseData>;
}
/**
 * 
 * @export
 * @interface WeatherPointwfResponseData
 */
export interface WeatherPointwfResponseData {
    /**
     * 予測対象日時 (YYYY/MM/DD hh:mm:ss)
     * @type {string}
     * @memberof WeatherPointwfResponseData
     */
    'date': string;
    /**
     * 天気（天気コード一覧参照）
     * @type {string}
     * @memberof WeatherPointwfResponseData
     */
    'wth': string;
    /**
     * 気温(℃) 0.1℃単位
     * @type {number}
     * @memberof WeatherPointwfResponseData
     */
    'temp': number;
    /**
     * 前 1 時間降水量(mm/h) 0mm/h 超 1mm/h 未満：0.1mm/h 単位 1mm/h 以上：1mm/h 単位
     * @type {number}
     * @memberof WeatherPointwfResponseData
     */
    'rain_1h': number;
    /**
     * 風向(16 方位)（風向番号一覧参照）
     * @type {number}
     * @memberof WeatherPointwfResponseData
     */
    'wind_d': number;
    /**
     * 風速(m/s) 1m/s 単位
     * @type {number}
     * @memberof WeatherPointwfResponseData
     */
    'wind_s': number;
    /**
     * 相対湿度(%) 1%単位
     * @type {number}
     * @memberof WeatherPointwfResponseData
     */
    'hum': number;
}
/**
 * 
 * @export
 * @interface WeatherWth10Response
 */
export interface WeatherWth10Response {
    /**
     * 
     * @type {string}
     * @memberof WeatherWth10Response
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof WeatherWth10Response
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof WeatherWth10Response
     */
    'lon': number;
    /**
     * 
     * @type {Array<WeatherWth10ResponseDaily>}
     * @memberof WeatherWth10Response
     */
    'daily': Array<WeatherWth10ResponseDaily>;
    /**
     * 
     * @type {Array<WeatherWth10ResponseData>}
     * @memberof WeatherWth10Response
     */
    'data': Array<WeatherWth10ResponseData>;
}
/**
 * 
 * @export
 * @interface WeatherWth10ResponseDaily
 */
export interface WeatherWth10ResponseDaily {
    /**
     * 予測対象日時 (YYYY/MM/DD hh:mm:ss)
     * @type {string}
     * @memberof WeatherWth10ResponseDaily
     */
    'date': string;
    /**
     * 降水確率(％)
     * @type {number}
     * @memberof WeatherWth10ResponseDaily
     */
    'r_prob': number;
    /**
     * 天気テロップコード
     * @type {string}
     * @memberof WeatherWth10ResponseDaily
     */
    'wthc': string;
    /**
     * 天気
     * @type {string}
     * @memberof WeatherWth10ResponseDaily
     */
    'wthw': string;
    /**
     * 最高気温(℃)
     * @type {number}
     * @memberof WeatherWth10ResponseDaily
     */
    'temp_max': number;
    /**
     * 最低気温(℃)
     * @type {number}
     * @memberof WeatherWth10ResponseDaily
     */
    'temp_min': number;
}
/**
 * 
 * @export
 * @interface WeatherWth10ResponseData
 */
export interface WeatherWth10ResponseData {
    /**
     * 予測対象日時 (YYYY/MM/DD hh:mm:ss)
     * @type {string}
     * @memberof WeatherWth10ResponseData
     */
    'date': string;
    /**
     * 天気（天気コード一覧参照）
     * @type {string}
     * @memberof WeatherWth10ResponseData
     */
    'wth': string;
    /**
     * 気温(℃) 0.1℃単位
     * @type {number}
     * @memberof WeatherWth10ResponseData
     */
    'temp': number;
    /**
     * 前 3 時間降水量(mm/3h) 0mm/3h 超 1mm/3h 未満：0.1mm/3h 単位 1mm/3h 以上：1mm/3h 単位
     * @type {number}
     * @memberof WeatherWth10ResponseData
     */
    'rain': number;
    /**
     * 降水確率(％)
     * @type {number}
     * @memberof WeatherWth10ResponseData
     */
    'r_prob': number;
    /**
     * 風向(16 方位)（風向番号一覧参照）
     * @type {number}
     * @memberof WeatherWth10ResponseData
     */
    'wind_d': number;
    /**
     * 風速(m/s) 1m/s 単位
     * @type {number}
     * @memberof WeatherWth10ResponseData
     */
    'wind_s': number;
    /**
     * 相対湿度(%) 1%単位
     * @type {number}
     * @memberof WeatherWth10ResponseData
     */
    'hum': number;
}
/**
 * 
 * @export
 * @interface WsAuthMessage
 */
export interface WsAuthMessage {
    /**
     * 
     * @type {string}
     * @memberof WsAuthMessage
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof WsAuthMessage
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface WsCallCancelEstablishedMessage
 */
export interface WsCallCancelEstablishedMessage {
    /**
     * 
     * @type {string}
     * @memberof WsCallCancelEstablishedMessage
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof WsCallCancelEstablishedMessage
     */
    'callId': number;
}
/**
 * 
 * @export
 * @interface WsCallCancelMessage
 */
export interface WsCallCancelMessage {
    /**
     * 
     * @type {string}
     * @memberof WsCallCancelMessage
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof WsCallCancelMessage
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof WsCallCancelMessage
     */
    'callId': number;
}
/**
 * 
 * @export
 * @interface WsCallReceiveEstablishedMessage
 */
export interface WsCallReceiveEstablishedMessage {
    /**
     * 
     * @type {string}
     * @memberof WsCallReceiveEstablishedMessage
     */
    'action': string;
    /**
     * 
     * @type {boolean}
     * @memberof WsCallReceiveEstablishedMessage
     */
    'isReceived': boolean;
    /**
     * 
     * @type {number}
     * @memberof WsCallReceiveEstablishedMessage
     */
    'callId': number;
}
/**
 * 
 * @export
 * @interface WsCallReceiveMessage
 */
export interface WsCallReceiveMessage {
    /**
     * 
     * @type {string}
     * @memberof WsCallReceiveMessage
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof WsCallReceiveMessage
     */
    'action': string;
    /**
     * 
     * @type {boolean}
     * @memberof WsCallReceiveMessage
     */
    'isReceived': boolean;
    /**
     * 
     * @type {number}
     * @memberof WsCallReceiveMessage
     */
    'callId': number;
}
/**
 * 
 * @export
 * @interface WsCallStartEstablishedMessage
 */
export interface WsCallStartEstablishedMessage {
    /**
     * 
     * @type {string}
     * @memberof WsCallStartEstablishedMessage
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof WsCallStartEstablishedMessage
     */
    'callId': number;
    /**
     * 
     * @type {number}
     * @memberof WsCallStartEstablishedMessage
     */
    'roomId': number;
    /**
     * 
     * @type {number}
     * @memberof WsCallStartEstablishedMessage
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WsCallStartEstablishedMessage
     */
    'targetUserId'?: number;
}
/**
 * 
 * @export
 * @interface WsCallStartMessage
 */
export interface WsCallStartMessage {
    /**
     * 
     * @type {string}
     * @memberof WsCallStartMessage
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof WsCallStartMessage
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof WsCallStartMessage
     */
    'userId': number;
}

/**
 * AreaApi - axios parameter creator
 * @export
 */
export const AreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary エリアを作成する
         * @param {AreaCreateRequest} areaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaCreate: async (areaCreateRequest: AreaCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaCreateRequest' is not null or undefined
            assertParamExists('areaCreate', 'areaCreateRequest', areaCreateRequest)
            const localVarPath = `/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary エリアを削除する
         * @param {number} id 削除するエリアのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaDelete', 'id', id)
            const localVarPath = `/areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary エリア一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaList: async (id?: number, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary エリアを更新する
         * @param {number} id 更新するエリアのID
         * @param {AreaUpdateRequest} areaUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaUpdate: async (id: number, areaUpdateRequest: AreaUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaUpdate', 'id', id)
            // verify required parameter 'areaUpdateRequest' is not null or undefined
            assertParamExists('areaUpdate', 'areaUpdateRequest', areaUpdateRequest)
            const localVarPath = `/areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreaApi - functional programming interface
 * @export
 */
export const AreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary エリアを作成する
         * @param {AreaCreateRequest} areaCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaCreate(areaCreateRequest: AreaCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaCreate(areaCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary エリアを削除する
         * @param {number} id 削除するエリアのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary エリア一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaList(id?: number, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaList(id, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary エリアを更新する
         * @param {number} id 更新するエリアのID
         * @param {AreaUpdateRequest} areaUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaUpdate(id: number, areaUpdateRequest: AreaUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaUpdate(id, areaUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreaApi - factory interface
 * @export
 */
export const AreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreaApiFp(configuration)
    return {
        /**
         * 
         * @summary エリアを作成する
         * @param {AreaApiAreaCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaCreate(requestParameters: AreaApiAreaCreateRequest, options?: AxiosRequestConfig): AxiosPromise<AreaCreateResponse> {
            return localVarFp.areaCreate(requestParameters.areaCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary エリアを削除する
         * @param {AreaApiAreaDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDelete(requestParameters: AreaApiAreaDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.areaDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary エリア一覧を取得する
         * @param {AreaApiAreaListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaList(requestParameters: AreaApiAreaListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AreaListResponse> {
            return localVarFp.areaList(requestParameters.id, requestParameters.page, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary エリアを更新する
         * @param {AreaApiAreaUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaUpdate(requestParameters: AreaApiAreaUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.areaUpdate(requestParameters.id, requestParameters.areaUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for areaCreate operation in AreaApi.
 * @export
 * @interface AreaApiAreaCreateRequest
 */
export interface AreaApiAreaCreateRequest {
    /**
     * 
     * @type {AreaCreateRequest}
     * @memberof AreaApiAreaCreate
     */
    readonly areaCreateRequest: AreaCreateRequest
}

/**
 * Request parameters for areaDelete operation in AreaApi.
 * @export
 * @interface AreaApiAreaDeleteRequest
 */
export interface AreaApiAreaDeleteRequest {
    /**
     * 削除するエリアのID
     * @type {number}
     * @memberof AreaApiAreaDelete
     */
    readonly id: number
}

/**
 * Request parameters for areaList operation in AreaApi.
 * @export
 * @interface AreaApiAreaListRequest
 */
export interface AreaApiAreaListRequest {
    /**
     * 
     * @type {number}
     * @memberof AreaApiAreaList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof AreaApiAreaList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AreaApiAreaList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for areaUpdate operation in AreaApi.
 * @export
 * @interface AreaApiAreaUpdateRequest
 */
export interface AreaApiAreaUpdateRequest {
    /**
     * 更新するエリアのID
     * @type {number}
     * @memberof AreaApiAreaUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AreaUpdateRequest}
     * @memberof AreaApiAreaUpdate
     */
    readonly areaUpdateRequest: AreaUpdateRequest
}

/**
 * AreaApi - object-oriented interface
 * @export
 * @class AreaApi
 * @extends {BaseAPI}
 */
export class AreaApi extends BaseAPI {
    /**
     * 
     * @summary エリアを作成する
     * @param {AreaApiAreaCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaCreate(requestParameters: AreaApiAreaCreateRequest, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaCreate(requestParameters.areaCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary エリアを削除する
     * @param {AreaApiAreaDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaDelete(requestParameters: AreaApiAreaDeleteRequest, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary エリア一覧を取得する
     * @param {AreaApiAreaListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaList(requestParameters: AreaApiAreaListRequest = {}, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaList(requestParameters.id, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary エリアを更新する
     * @param {AreaApiAreaUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaUpdate(requestParameters: AreaApiAreaUpdateRequest, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaUpdate(requestParameters.id, requestParameters.areaUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 新しいコンテンツを作成する
         * @param {ContentCreateRequest} contentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentCreate: async (contentCreateRequest: ContentCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentCreateRequest' is not null or undefined
            assertParamExists('contentCreate', 'contentCreateRequest', contentCreateRequest)
            const localVarPath = `/contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 新しいコンテンツ(SFTP SERVER)を作成する
         * @param {ContentCreateMusenRequest} contentCreateMusenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentCreateMusen: async (contentCreateMusenRequest: ContentCreateMusenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentCreateMusenRequest' is not null or undefined
            assertParamExists('contentCreateMusen', 'contentCreateMusenRequest', contentCreateMusenRequest)
            const localVarPath = `/contents/createMusen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentCreateMusenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コンテンツを削除する
         * @param {number} id 更新するコンテンツのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentDelete', 'id', id)
            const localVarPath = `/contents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コンテンツのLike
         * @param {number} id 
         * @param {ContentLikeRequest} contentLikeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentLike: async (id: number, contentLikeRequest: ContentLikeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentLike', 'id', id)
            // verify required parameter 'contentLikeRequest' is not null or undefined
            assertParamExists('contentLike', 'contentLikeRequest', contentLikeRequest)
            const localVarPath = `/contents/{id}/like`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentLikeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コンテンツ一覧を取得する
         * @param {number} [id] 
         * @param {string} [type] 
         * @param {Array<string>} [types] 
         * @param {string} [search] 
         * @param {Array<number>} [placeIds] 
         * @param {Array<string>} [tags] 
         * @param {Array<number>} [contentTagIds] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {ContentListSortEnum} [sort] 
         * @param {boolean} [withTenant] 
         * @param {boolean} [withCreatedByUser] 
         * @param {boolean} [withUpdatedByUser] 
         * @param {boolean} [withFiles] 
         * @param {boolean} [withLikeCount] 
         * @param {boolean} [withIsLiked] 
         * @param {boolean} [withIsRead] 
         * @param {boolean} [withContentPlaces] 
         * @param {boolean} [withContentPlacesPlace] 
         * @param {boolean} [withContentTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentList: async (id?: number, type?: string, types?: Array<string>, search?: string, placeIds?: Array<number>, tags?: Array<string>, contentTagIds?: Array<number>, startDate?: string, endDate?: string, page?: number, pageSize?: number, sort?: ContentListSortEnum, withTenant?: boolean, withCreatedByUser?: boolean, withUpdatedByUser?: boolean, withFiles?: boolean, withLikeCount?: boolean, withIsLiked?: boolean, withIsRead?: boolean, withContentPlaces?: boolean, withContentPlacesPlace?: boolean, withContentTags?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (placeIds) {
                localVarQueryParameter['placeIds'] = placeIds;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (contentTagIds) {
                localVarQueryParameter['contentTagIds'] = contentTagIds;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (withTenant !== undefined) {
                localVarQueryParameter['withTenant'] = withTenant;
            }

            if (withCreatedByUser !== undefined) {
                localVarQueryParameter['withCreatedByUser'] = withCreatedByUser;
            }

            if (withUpdatedByUser !== undefined) {
                localVarQueryParameter['withUpdatedByUser'] = withUpdatedByUser;
            }

            if (withFiles !== undefined) {
                localVarQueryParameter['withFiles'] = withFiles;
            }

            if (withLikeCount !== undefined) {
                localVarQueryParameter['withLikeCount'] = withLikeCount;
            }

            if (withIsLiked !== undefined) {
                localVarQueryParameter['withIsLiked'] = withIsLiked;
            }

            if (withIsRead !== undefined) {
                localVarQueryParameter['withIsRead'] = withIsRead;
            }

            if (withContentPlaces !== undefined) {
                localVarQueryParameter['withContentPlaces'] = withContentPlaces;
            }

            if (withContentPlacesPlace !== undefined) {
                localVarQueryParameter['withContentPlacesPlace'] = withContentPlacesPlace;
            }

            if (withContentTags !== undefined) {
                localVarQueryParameter['withContentTags'] = withContentTags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コンテンツを既読にする
         * @param {ContentReadRequest} contentReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentRead: async (contentReadRequest: ContentReadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentReadRequest' is not null or undefined
            assertParamExists('contentRead', 'contentReadRequest', contentReadRequest)
            const localVarPath = `/contents/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentReadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コンテンツを更新する
         * @param {number} id 更新するコンテンツのID
         * @param {ContentUpdateRequest} contentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentUpdate: async (id: number, contentUpdateRequest: ContentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentUpdate', 'id', id)
            // verify required parameter 'contentUpdateRequest' is not null or undefined
            assertParamExists('contentUpdate', 'contentUpdateRequest', contentUpdateRequest)
            const localVarPath = `/contents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 新しいコンテンツを作成する
         * @param {ContentCreateRequest} contentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentCreate(contentCreateRequest: ContentCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentCreate(contentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 新しいコンテンツ(SFTP SERVER)を作成する
         * @param {ContentCreateMusenRequest} contentCreateMusenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentCreateMusen(contentCreateMusenRequest: ContentCreateMusenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentCreateMusenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentCreateMusen(contentCreateMusenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コンテンツを削除する
         * @param {number} id 更新するコンテンツのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コンテンツのLike
         * @param {number} id 
         * @param {ContentLikeRequest} contentLikeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentLike(id: number, contentLikeRequest: ContentLikeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentLike(id, contentLikeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コンテンツ一覧を取得する
         * @param {number} [id] 
         * @param {string} [type] 
         * @param {Array<string>} [types] 
         * @param {string} [search] 
         * @param {Array<number>} [placeIds] 
         * @param {Array<string>} [tags] 
         * @param {Array<number>} [contentTagIds] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {ContentListSortEnum} [sort] 
         * @param {boolean} [withTenant] 
         * @param {boolean} [withCreatedByUser] 
         * @param {boolean} [withUpdatedByUser] 
         * @param {boolean} [withFiles] 
         * @param {boolean} [withLikeCount] 
         * @param {boolean} [withIsLiked] 
         * @param {boolean} [withIsRead] 
         * @param {boolean} [withContentPlaces] 
         * @param {boolean} [withContentPlacesPlace] 
         * @param {boolean} [withContentTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentList(id?: number, type?: string, types?: Array<string>, search?: string, placeIds?: Array<number>, tags?: Array<string>, contentTagIds?: Array<number>, startDate?: string, endDate?: string, page?: number, pageSize?: number, sort?: ContentListSortEnum, withTenant?: boolean, withCreatedByUser?: boolean, withUpdatedByUser?: boolean, withFiles?: boolean, withLikeCount?: boolean, withIsLiked?: boolean, withIsRead?: boolean, withContentPlaces?: boolean, withContentPlacesPlace?: boolean, withContentTags?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentList(id, type, types, search, placeIds, tags, contentTagIds, startDate, endDate, page, pageSize, sort, withTenant, withCreatedByUser, withUpdatedByUser, withFiles, withLikeCount, withIsLiked, withIsRead, withContentPlaces, withContentPlacesPlace, withContentTags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コンテンツを既読にする
         * @param {ContentReadRequest} contentReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentRead(contentReadRequest: ContentReadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentRead(contentReadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コンテンツを更新する
         * @param {number} id 更新するコンテンツのID
         * @param {ContentUpdateRequest} contentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentUpdate(id: number, contentUpdateRequest: ContentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentUpdate(id, contentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * 
         * @summary 新しいコンテンツを作成する
         * @param {ContentApiContentCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentCreate(requestParameters: ContentApiContentCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ContentCreateResponse> {
            return localVarFp.contentCreate(requestParameters.contentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 新しいコンテンツ(SFTP SERVER)を作成する
         * @param {ContentApiContentCreateMusenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentCreateMusen(requestParameters: ContentApiContentCreateMusenRequest, options?: AxiosRequestConfig): AxiosPromise<ContentCreateMusenResponse> {
            return localVarFp.contentCreateMusen(requestParameters.contentCreateMusenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コンテンツを削除する
         * @param {ContentApiContentDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentDelete(requestParameters: ContentApiContentDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.contentDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コンテンツのLike
         * @param {ContentApiContentLikeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentLike(requestParameters: ContentApiContentLikeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.contentLike(requestParameters.id, requestParameters.contentLikeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コンテンツ一覧を取得する
         * @param {ContentApiContentListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentList(requestParameters: ContentApiContentListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ContentListResponse> {
            return localVarFp.contentList(requestParameters.id, requestParameters.type, requestParameters.types, requestParameters.search, requestParameters.placeIds, requestParameters.tags, requestParameters.contentTagIds, requestParameters.startDate, requestParameters.endDate, requestParameters.page, requestParameters.pageSize, requestParameters.sort, requestParameters.withTenant, requestParameters.withCreatedByUser, requestParameters.withUpdatedByUser, requestParameters.withFiles, requestParameters.withLikeCount, requestParameters.withIsLiked, requestParameters.withIsRead, requestParameters.withContentPlaces, requestParameters.withContentPlacesPlace, requestParameters.withContentTags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コンテンツを既読にする
         * @param {ContentApiContentReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentRead(requestParameters: ContentApiContentReadRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.contentRead(requestParameters.contentReadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コンテンツを更新する
         * @param {ContentApiContentUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentUpdate(requestParameters: ContentApiContentUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.contentUpdate(requestParameters.id, requestParameters.contentUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for contentCreate operation in ContentApi.
 * @export
 * @interface ContentApiContentCreateRequest
 */
export interface ContentApiContentCreateRequest {
    /**
     * 
     * @type {ContentCreateRequest}
     * @memberof ContentApiContentCreate
     */
    readonly contentCreateRequest: ContentCreateRequest
}

/**
 * Request parameters for contentCreateMusen operation in ContentApi.
 * @export
 * @interface ContentApiContentCreateMusenRequest
 */
export interface ContentApiContentCreateMusenRequest {
    /**
     * 
     * @type {ContentCreateMusenRequest}
     * @memberof ContentApiContentCreateMusen
     */
    readonly contentCreateMusenRequest: ContentCreateMusenRequest
}

/**
 * Request parameters for contentDelete operation in ContentApi.
 * @export
 * @interface ContentApiContentDeleteRequest
 */
export interface ContentApiContentDeleteRequest {
    /**
     * 更新するコンテンツのID
     * @type {number}
     * @memberof ContentApiContentDelete
     */
    readonly id: number
}

/**
 * Request parameters for contentLike operation in ContentApi.
 * @export
 * @interface ContentApiContentLikeRequest
 */
export interface ContentApiContentLikeRequest {
    /**
     * 
     * @type {number}
     * @memberof ContentApiContentLike
     */
    readonly id: number

    /**
     * 
     * @type {ContentLikeRequest}
     * @memberof ContentApiContentLike
     */
    readonly contentLikeRequest: ContentLikeRequest
}

/**
 * Request parameters for contentList operation in ContentApi.
 * @export
 * @interface ContentApiContentListRequest
 */
export interface ContentApiContentListRequest {
    /**
     * 
     * @type {number}
     * @memberof ContentApiContentList
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof ContentApiContentList
     */
    readonly type?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContentApiContentList
     */
    readonly types?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ContentApiContentList
     */
    readonly search?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ContentApiContentList
     */
    readonly placeIds?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof ContentApiContentList
     */
    readonly tags?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof ContentApiContentList
     */
    readonly contentTagIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof ContentApiContentList
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof ContentApiContentList
     */
    readonly endDate?: string

    /**
     * 
     * @type {number}
     * @memberof ContentApiContentList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ContentApiContentList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {'releaseAtAsc' | 'releaseAtDesc' | 'createdAtAsc' | 'createdAtDesc'}
     * @memberof ContentApiContentList
     */
    readonly sort?: ContentListSortEnum

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withTenant?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withCreatedByUser?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withUpdatedByUser?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withFiles?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withLikeCount?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withIsLiked?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withIsRead?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withContentPlaces?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withContentPlacesPlace?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContentApiContentList
     */
    readonly withContentTags?: boolean
}

/**
 * Request parameters for contentRead operation in ContentApi.
 * @export
 * @interface ContentApiContentReadRequest
 */
export interface ContentApiContentReadRequest {
    /**
     * 
     * @type {ContentReadRequest}
     * @memberof ContentApiContentRead
     */
    readonly contentReadRequest: ContentReadRequest
}

/**
 * Request parameters for contentUpdate operation in ContentApi.
 * @export
 * @interface ContentApiContentUpdateRequest
 */
export interface ContentApiContentUpdateRequest {
    /**
     * 更新するコンテンツのID
     * @type {number}
     * @memberof ContentApiContentUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ContentUpdateRequest}
     * @memberof ContentApiContentUpdate
     */
    readonly contentUpdateRequest: ContentUpdateRequest
}

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * 
     * @summary 新しいコンテンツを作成する
     * @param {ContentApiContentCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentCreate(requestParameters: ContentApiContentCreateRequest, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentCreate(requestParameters.contentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 新しいコンテンツ(SFTP SERVER)を作成する
     * @param {ContentApiContentCreateMusenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentCreateMusen(requestParameters: ContentApiContentCreateMusenRequest, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentCreateMusen(requestParameters.contentCreateMusenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コンテンツを削除する
     * @param {ContentApiContentDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentDelete(requestParameters: ContentApiContentDeleteRequest, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コンテンツのLike
     * @param {ContentApiContentLikeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentLike(requestParameters: ContentApiContentLikeRequest, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentLike(requestParameters.id, requestParameters.contentLikeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コンテンツ一覧を取得する
     * @param {ContentApiContentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentList(requestParameters: ContentApiContentListRequest = {}, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentList(requestParameters.id, requestParameters.type, requestParameters.types, requestParameters.search, requestParameters.placeIds, requestParameters.tags, requestParameters.contentTagIds, requestParameters.startDate, requestParameters.endDate, requestParameters.page, requestParameters.pageSize, requestParameters.sort, requestParameters.withTenant, requestParameters.withCreatedByUser, requestParameters.withUpdatedByUser, requestParameters.withFiles, requestParameters.withLikeCount, requestParameters.withIsLiked, requestParameters.withIsRead, requestParameters.withContentPlaces, requestParameters.withContentPlacesPlace, requestParameters.withContentTags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コンテンツを既読にする
     * @param {ContentApiContentReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentRead(requestParameters: ContentApiContentReadRequest, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentRead(requestParameters.contentReadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コンテンツを更新する
     * @param {ContentApiContentUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentUpdate(requestParameters: ContentApiContentUpdateRequest, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentUpdate(requestParameters.id, requestParameters.contentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ContentListSortEnum = {
    ReleaseAtAsc: 'releaseAtAsc',
    ReleaseAtDesc: 'releaseAtDesc',
    CreatedAtAsc: 'createdAtAsc',
    CreatedAtDesc: 'createdAtDesc'
} as const;
export type ContentListSortEnum = typeof ContentListSortEnum[keyof typeof ContentListSortEnum];


/**
 * ContentTagApi - axios parameter creator
 * @export
 */
export const ContentTagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary コンテンツタグを削除する
         * @param {number} id 更新するコンテンツタグID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentTagDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentTagDelete', 'id', id)
            const localVarPath = `/contentTags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コンテンツタグ一覧を取得する
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentTagList: async (contentType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contentTags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コンテンツタグを更新する
         * @param {number} id 更新するコンテンツタグID
         * @param {ContentTagUpdateRequest} contentTagUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentTagUpdate: async (id: number, contentTagUpdateRequest: ContentTagUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentTagUpdate', 'id', id)
            // verify required parameter 'contentTagUpdateRequest' is not null or undefined
            assertParamExists('contentTagUpdate', 'contentTagUpdateRequest', contentTagUpdateRequest)
            const localVarPath = `/contentTags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentTagUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentTagApi - functional programming interface
 * @export
 */
export const ContentTagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentTagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary コンテンツタグを削除する
         * @param {number} id 更新するコンテンツタグID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentTagDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentTagDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コンテンツタグ一覧を取得する
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentTagList(contentType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentTagListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentTagList(contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コンテンツタグを更新する
         * @param {number} id 更新するコンテンツタグID
         * @param {ContentTagUpdateRequest} contentTagUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentTagUpdate(id: number, contentTagUpdateRequest: ContentTagUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentTagUpdate(id, contentTagUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentTagApi - factory interface
 * @export
 */
export const ContentTagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentTagApiFp(configuration)
    return {
        /**
         * 
         * @summary コンテンツタグを削除する
         * @param {ContentTagApiContentTagDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentTagDelete(requestParameters: ContentTagApiContentTagDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.contentTagDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コンテンツタグ一覧を取得する
         * @param {ContentTagApiContentTagListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentTagList(requestParameters: ContentTagApiContentTagListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ContentTagListResponse> {
            return localVarFp.contentTagList(requestParameters.contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コンテンツタグを更新する
         * @param {ContentTagApiContentTagUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentTagUpdate(requestParameters: ContentTagApiContentTagUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.contentTagUpdate(requestParameters.id, requestParameters.contentTagUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for contentTagDelete operation in ContentTagApi.
 * @export
 * @interface ContentTagApiContentTagDeleteRequest
 */
export interface ContentTagApiContentTagDeleteRequest {
    /**
     * 更新するコンテンツタグID
     * @type {number}
     * @memberof ContentTagApiContentTagDelete
     */
    readonly id: number
}

/**
 * Request parameters for contentTagList operation in ContentTagApi.
 * @export
 * @interface ContentTagApiContentTagListRequest
 */
export interface ContentTagApiContentTagListRequest {
    /**
     * 
     * @type {string}
     * @memberof ContentTagApiContentTagList
     */
    readonly contentType?: string
}

/**
 * Request parameters for contentTagUpdate operation in ContentTagApi.
 * @export
 * @interface ContentTagApiContentTagUpdateRequest
 */
export interface ContentTagApiContentTagUpdateRequest {
    /**
     * 更新するコンテンツタグID
     * @type {number}
     * @memberof ContentTagApiContentTagUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ContentTagUpdateRequest}
     * @memberof ContentTagApiContentTagUpdate
     */
    readonly contentTagUpdateRequest: ContentTagUpdateRequest
}

/**
 * ContentTagApi - object-oriented interface
 * @export
 * @class ContentTagApi
 * @extends {BaseAPI}
 */
export class ContentTagApi extends BaseAPI {
    /**
     * 
     * @summary コンテンツタグを削除する
     * @param {ContentTagApiContentTagDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentTagApi
     */
    public contentTagDelete(requestParameters: ContentTagApiContentTagDeleteRequest, options?: AxiosRequestConfig) {
        return ContentTagApiFp(this.configuration).contentTagDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コンテンツタグ一覧を取得する
     * @param {ContentTagApiContentTagListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentTagApi
     */
    public contentTagList(requestParameters: ContentTagApiContentTagListRequest = {}, options?: AxiosRequestConfig) {
        return ContentTagApiFp(this.configuration).contentTagList(requestParameters.contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コンテンツタグを更新する
     * @param {ContentTagApiContentTagUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentTagApi
     */
    public contentTagUpdate(requestParameters: ContentTagApiContentTagUpdateRequest, options?: AxiosRequestConfig) {
        return ContentTagApiFp(this.configuration).contentTagUpdate(requestParameters.id, requestParameters.contentTagUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceTokenApi - axios parameter creator
 * @export
 */
export const DeviceTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary デバイストークンを登録する
         * @param {DeviceTokenCreateRequest} deviceTokenCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceTokenCreate: async (deviceTokenCreateRequest: DeviceTokenCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceTokenCreateRequest' is not null or undefined
            assertParamExists('deviceTokenCreate', 'deviceTokenCreateRequest', deviceTokenCreateRequest)
            const localVarPath = `/deviceTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceTokenCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary デバイストークンを削除する
         * @param {DeviceTokenDeleteRequest} [deviceTokenDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceTokenDelete: async (deviceTokenDeleteRequest?: DeviceTokenDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deviceTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceTokenDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceTokenApi - functional programming interface
 * @export
 */
export const DeviceTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary デバイストークンを登録する
         * @param {DeviceTokenCreateRequest} deviceTokenCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceTokenCreate(deviceTokenCreateRequest: DeviceTokenCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceTokenCreate(deviceTokenCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary デバイストークンを削除する
         * @param {DeviceTokenDeleteRequest} [deviceTokenDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceTokenDelete(deviceTokenDeleteRequest?: DeviceTokenDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceTokenDelete(deviceTokenDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceTokenApi - factory interface
 * @export
 */
export const DeviceTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary デバイストークンを登録する
         * @param {DeviceTokenApiDeviceTokenCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceTokenCreate(requestParameters: DeviceTokenApiDeviceTokenCreateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deviceTokenCreate(requestParameters.deviceTokenCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary デバイストークンを削除する
         * @param {DeviceTokenApiDeviceTokenDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceTokenDelete(requestParameters: DeviceTokenApiDeviceTokenDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deviceTokenDelete(requestParameters.deviceTokenDeleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deviceTokenCreate operation in DeviceTokenApi.
 * @export
 * @interface DeviceTokenApiDeviceTokenCreateRequest
 */
export interface DeviceTokenApiDeviceTokenCreateRequest {
    /**
     * 
     * @type {DeviceTokenCreateRequest}
     * @memberof DeviceTokenApiDeviceTokenCreate
     */
    readonly deviceTokenCreateRequest: DeviceTokenCreateRequest
}

/**
 * Request parameters for deviceTokenDelete operation in DeviceTokenApi.
 * @export
 * @interface DeviceTokenApiDeviceTokenDeleteRequest
 */
export interface DeviceTokenApiDeviceTokenDeleteRequest {
    /**
     * 
     * @type {DeviceTokenDeleteRequest}
     * @memberof DeviceTokenApiDeviceTokenDelete
     */
    readonly deviceTokenDeleteRequest?: DeviceTokenDeleteRequest
}

/**
 * DeviceTokenApi - object-oriented interface
 * @export
 * @class DeviceTokenApi
 * @extends {BaseAPI}
 */
export class DeviceTokenApi extends BaseAPI {
    /**
     * 
     * @summary デバイストークンを登録する
     * @param {DeviceTokenApiDeviceTokenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTokenApi
     */
    public deviceTokenCreate(requestParameters: DeviceTokenApiDeviceTokenCreateRequest, options?: AxiosRequestConfig) {
        return DeviceTokenApiFp(this.configuration).deviceTokenCreate(requestParameters.deviceTokenCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary デバイストークンを削除する
     * @param {DeviceTokenApiDeviceTokenDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTokenApi
     */
    public deviceTokenDelete(requestParameters: DeviceTokenApiDeviceTokenDeleteRequest = {}, options?: AxiosRequestConfig) {
        return DeviceTokenApiFp(this.configuration).deviceTokenDelete(requestParameters.deviceTokenDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filePresignPutUrl: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files/presignPutUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filePresignPutUrl(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilePresignPutUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filePresignPutUrl(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {FileApiFilePresignPutUrlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filePresignPutUrl(requestParameters: FileApiFilePresignPutUrlRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FilePresignPutUrlResponse> {
            return localVarFp.filePresignPutUrl(requestParameters.fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for filePresignPutUrl operation in FileApi.
 * @export
 * @interface FileApiFilePresignPutUrlRequest
 */
export interface FileApiFilePresignPutUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof FileApiFilePresignPutUrl
     */
    readonly fileName?: string
}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {FileApiFilePresignPutUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public filePresignPutUrl(requestParameters: FileApiFilePresignPutUrlRequest = {}, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).filePresignPutUrl(requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FriendApi - axios parameter creator
 * @export
 */
export const FriendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 友達を追加する
         * @param {FriendCreateRequest} friendCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendCreate: async (friendCreateRequest: FriendCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'friendCreateRequest' is not null or undefined
            assertParamExists('friendCreate', 'friendCreateRequest', friendCreateRequest)
            const localVarPath = `/friends`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(friendCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 友達を削除する
         * @param {number} id 削除する友達のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('friendDelete', 'id', id)
            const localVarPath = `/friends/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 友達を更新する
         * @param {number} id 更新する友達のID
         * @param {FriendUpdateRequest} friendUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendUpdate: async (id: number, friendUpdateRequest: FriendUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('friendUpdate', 'id', id)
            // verify required parameter 'friendUpdateRequest' is not null or undefined
            assertParamExists('friendUpdate', 'friendUpdateRequest', friendUpdateRequest)
            const localVarPath = `/friends/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(friendUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FriendApi - functional programming interface
 * @export
 */
export const FriendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FriendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 友達を追加する
         * @param {FriendCreateRequest} friendCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendCreate(friendCreateRequest: FriendCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FriendCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendCreate(friendCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 友達を削除する
         * @param {number} id 削除する友達のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 友達を更新する
         * @param {number} id 更新する友達のID
         * @param {FriendUpdateRequest} friendUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendUpdate(id: number, friendUpdateRequest: FriendUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendUpdate(id, friendUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FriendApi - factory interface
 * @export
 */
export const FriendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FriendApiFp(configuration)
    return {
        /**
         * 
         * @summary 友達を追加する
         * @param {FriendApiFriendCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendCreate(requestParameters: FriendApiFriendCreateRequest, options?: AxiosRequestConfig): AxiosPromise<FriendCreateResponse> {
            return localVarFp.friendCreate(requestParameters.friendCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 友達を削除する
         * @param {FriendApiFriendDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendDelete(requestParameters: FriendApiFriendDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.friendDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 友達を更新する
         * @param {FriendApiFriendUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendUpdate(requestParameters: FriendApiFriendUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.friendUpdate(requestParameters.id, requestParameters.friendUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for friendCreate operation in FriendApi.
 * @export
 * @interface FriendApiFriendCreateRequest
 */
export interface FriendApiFriendCreateRequest {
    /**
     * 
     * @type {FriendCreateRequest}
     * @memberof FriendApiFriendCreate
     */
    readonly friendCreateRequest: FriendCreateRequest
}

/**
 * Request parameters for friendDelete operation in FriendApi.
 * @export
 * @interface FriendApiFriendDeleteRequest
 */
export interface FriendApiFriendDeleteRequest {
    /**
     * 削除する友達のID
     * @type {number}
     * @memberof FriendApiFriendDelete
     */
    readonly id: number
}

/**
 * Request parameters for friendUpdate operation in FriendApi.
 * @export
 * @interface FriendApiFriendUpdateRequest
 */
export interface FriendApiFriendUpdateRequest {
    /**
     * 更新する友達のID
     * @type {number}
     * @memberof FriendApiFriendUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FriendUpdateRequest}
     * @memberof FriendApiFriendUpdate
     */
    readonly friendUpdateRequest: FriendUpdateRequest
}

/**
 * FriendApi - object-oriented interface
 * @export
 * @class FriendApi
 * @extends {BaseAPI}
 */
export class FriendApi extends BaseAPI {
    /**
     * 
     * @summary 友達を追加する
     * @param {FriendApiFriendCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FriendApi
     */
    public friendCreate(requestParameters: FriendApiFriendCreateRequest, options?: AxiosRequestConfig) {
        return FriendApiFp(this.configuration).friendCreate(requestParameters.friendCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 友達を削除する
     * @param {FriendApiFriendDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FriendApi
     */
    public friendDelete(requestParameters: FriendApiFriendDeleteRequest, options?: AxiosRequestConfig) {
        return FriendApiFp(this.configuration).friendDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 友達を更新する
     * @param {FriendApiFriendUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FriendApi
     */
    public friendUpdate(requestParameters: FriendApiFriendUpdateRequest, options?: AxiosRequestConfig) {
        return FriendApiFp(this.configuration).friendUpdate(requestParameters.id, requestParameters.friendUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeApi - axios parameter creator
 * @export
 */
export const MeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 自分のユーザー情報を取得する
         * @param {boolean} [withFriends] 
         * @param {boolean} [withTaxiCompany] 
         * @param {boolean} [withUserInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meDetail: async (withFriends?: boolean, withTaxiCompany?: boolean, withUserInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (withFriends !== undefined) {
                localVarQueryParameter['withFriends'] = withFriends;
            }

            if (withTaxiCompany !== undefined) {
                localVarQueryParameter['withTaxiCompany'] = withTaxiCompany;
            }

            if (withUserInfo !== undefined) {
                localVarQueryParameter['withUserInfo'] = withUserInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自分のユーザー情報を更新する
         * @param {MeUpdateRequest} meUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meUpdate: async (meUpdateRequest: MeUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meUpdateRequest' is not null or undefined
            assertParamExists('meUpdate', 'meUpdateRequest', meUpdateRequest)
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeApi - functional programming interface
 * @export
 */
export const MeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 自分のユーザー情報を取得する
         * @param {boolean} [withFriends] 
         * @param {boolean} [withTaxiCompany] 
         * @param {boolean} [withUserInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meDetail(withFriends?: boolean, withTaxiCompany?: boolean, withUserInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meDetail(withFriends, withTaxiCompany, withUserInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自分のユーザー情報を更新する
         * @param {MeUpdateRequest} meUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meUpdate(meUpdateRequest: MeUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meUpdate(meUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeApi - factory interface
 * @export
 */
export const MeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeApiFp(configuration)
    return {
        /**
         * 
         * @summary 自分のユーザー情報を取得する
         * @param {MeApiMeDetailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meDetail(requestParameters: MeApiMeDetailRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MeDetailResponse> {
            return localVarFp.meDetail(requestParameters.withFriends, requestParameters.withTaxiCompany, requestParameters.withUserInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自分のユーザー情報を更新する
         * @param {MeApiMeUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meUpdate(requestParameters: MeApiMeUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.meUpdate(requestParameters.meUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for meDetail operation in MeApi.
 * @export
 * @interface MeApiMeDetailRequest
 */
export interface MeApiMeDetailRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MeApiMeDetail
     */
    readonly withFriends?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MeApiMeDetail
     */
    readonly withTaxiCompany?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MeApiMeDetail
     */
    readonly withUserInfo?: boolean
}

/**
 * Request parameters for meUpdate operation in MeApi.
 * @export
 * @interface MeApiMeUpdateRequest
 */
export interface MeApiMeUpdateRequest {
    /**
     * 
     * @type {MeUpdateRequest}
     * @memberof MeApiMeUpdate
     */
    readonly meUpdateRequest: MeUpdateRequest
}

/**
 * MeApi - object-oriented interface
 * @export
 * @class MeApi
 * @extends {BaseAPI}
 */
export class MeApi extends BaseAPI {
    /**
     * 
     * @summary 自分のユーザー情報を取得する
     * @param {MeApiMeDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public meDetail(requestParameters: MeApiMeDetailRequest = {}, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).meDetail(requestParameters.withFriends, requestParameters.withTaxiCompany, requestParameters.withUserInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自分のユーザー情報を更新する
     * @param {MeApiMeUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public meUpdate(requestParameters: MeApiMeUpdateRequest, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).meUpdate(requestParameters.meUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 通知一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [scheduledTimeFrom] 
         * @param {string} [scheduledTimeTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationList: async (id?: number, page?: number, pageSize?: number, scheduledTimeFrom?: string, scheduledTimeTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (scheduledTimeFrom !== undefined) {
                localVarQueryParameter['scheduledTimeFrom'] = scheduledTimeFrom;
            }

            if (scheduledTimeTo !== undefined) {
                localVarQueryParameter['scheduledTimeTo'] = scheduledTimeTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 通知を既読にする
         * @param {NotificationReadRequest} notificationReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationRead: async (notificationReadRequest: NotificationReadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationReadRequest' is not null or undefined
            assertParamExists('notificationRead', 'notificationReadRequest', notificationReadRequest)
            const localVarPath = `/notification/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationReadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 通知一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [scheduledTimeFrom] 
         * @param {string} [scheduledTimeTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationList(id?: number, page?: number, pageSize?: number, scheduledTimeFrom?: string, scheduledTimeTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationList(id, page, pageSize, scheduledTimeFrom, scheduledTimeTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 通知を既読にする
         * @param {NotificationReadRequest} notificationReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationRead(notificationReadRequest: NotificationReadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationRead(notificationReadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary 通知一覧を取得する
         * @param {NotificationApiNotificationListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationList(requestParameters: NotificationApiNotificationListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<NotificationListResponse> {
            return localVarFp.notificationList(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.scheduledTimeFrom, requestParameters.scheduledTimeTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 通知を既読にする
         * @param {NotificationApiNotificationReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationRead(requestParameters: NotificationApiNotificationReadRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.notificationRead(requestParameters.notificationReadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for notificationList operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationListRequest
 */
export interface NotificationApiNotificationListRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NotificationApiNotificationList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationList
     */
    readonly scheduledTimeFrom?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationApiNotificationList
     */
    readonly scheduledTimeTo?: string
}

/**
 * Request parameters for notificationRead operation in NotificationApi.
 * @export
 * @interface NotificationApiNotificationReadRequest
 */
export interface NotificationApiNotificationReadRequest {
    /**
     * 
     * @type {NotificationReadRequest}
     * @memberof NotificationApiNotificationRead
     */
    readonly notificationReadRequest: NotificationReadRequest
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary 通知一覧を取得する
     * @param {NotificationApiNotificationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationList(requestParameters: NotificationApiNotificationListRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationList(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.scheduledTimeFrom, requestParameters.scheduledTimeTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 通知を既読にする
     * @param {NotificationApiNotificationReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationRead(requestParameters: NotificationApiNotificationReadRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationRead(requestParameters.notificationReadRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lambdaからアクセス用の認証ログインの1段階目の認証。 成功すると認証コードが返され、これを2段階目の認証で使用する。
         * @param {OauthAuthorizeRequest} [oauthAuthorizeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthAuthorize: async (oauthAuthorizeRequest?: OauthAuthorizeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oauthAuthorizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lambdaからアクセス用の認証ログインの2段階目の認証。
         * @param {string} [grantType] 
         * @param {string} [code] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthToken: async (grantType?: string, code?: string, clientId?: string, clientSecret?: string, redirectUri?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.set('code', code as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
            if (redirectUri !== undefined) { 
                localVarFormParams.set('redirect_uri', redirectUri as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration)
    return {
        /**
         * Lambdaからアクセス用の認証ログインの1段階目の認証。 成功すると認証コードが返され、これを2段階目の認証で使用する。
         * @param {OauthAuthorizeRequest} [oauthAuthorizeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthAuthorize(oauthAuthorizeRequest?: OauthAuthorizeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthAuthorizeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthAuthorize(oauthAuthorizeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lambdaからアクセス用の認証ログインの2段階目の認証。
         * @param {string} [grantType] 
         * @param {string} [code] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthToken(grantType?: string, code?: string, clientId?: string, clientSecret?: string, redirectUri?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthToken(grantType, code, clientId, clientSecret, redirectUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OauthApiFp(configuration)
    return {
        /**
         * Lambdaからアクセス用の認証ログインの1段階目の認証。 成功すると認証コードが返され、これを2段階目の認証で使用する。
         * @param {OauthApiOauthAuthorizeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthAuthorize(requestParameters: OauthApiOauthAuthorizeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<OauthAuthorizeResponse> {
            return localVarFp.oauthAuthorize(requestParameters.oauthAuthorizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Lambdaからアクセス用の認証ログインの2段階目の認証。
         * @param {OauthApiOauthTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthToken(requestParameters: OauthApiOauthTokenRequest = {}, options?: AxiosRequestConfig): AxiosPromise<OauthTokenResponse> {
            return localVarFp.oauthToken(requestParameters.grantType, requestParameters.code, requestParameters.clientId, requestParameters.clientSecret, requestParameters.redirectUri, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for oauthAuthorize operation in OauthApi.
 * @export
 * @interface OauthApiOauthAuthorizeRequest
 */
export interface OauthApiOauthAuthorizeRequest {
    /**
     * 
     * @type {OauthAuthorizeRequest}
     * @memberof OauthApiOauthAuthorize
     */
    readonly oauthAuthorizeRequest?: OauthAuthorizeRequest
}

/**
 * Request parameters for oauthToken operation in OauthApi.
 * @export
 * @interface OauthApiOauthTokenRequest
 */
export interface OauthApiOauthTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof OauthApiOauthToken
     */
    readonly grantType?: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiOauthToken
     */
    readonly code?: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiOauthToken
     */
    readonly clientId?: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiOauthToken
     */
    readonly clientSecret?: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiOauthToken
     */
    readonly redirectUri?: string
}

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
    /**
     * Lambdaからアクセス用の認証ログインの1段階目の認証。 成功すると認証コードが返され、これを2段階目の認証で使用する。
     * @param {OauthApiOauthAuthorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthAuthorize(requestParameters: OauthApiOauthAuthorizeRequest = {}, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthAuthorize(requestParameters.oauthAuthorizeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lambdaからアクセス用の認証ログインの2段階目の認証。
     * @param {OauthApiOauthTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthToken(requestParameters: OauthApiOauthTokenRequest = {}, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).oauthToken(requestParameters.grantType, requestParameters.code, requestParameters.clientId, requestParameters.clientSecret, requestParameters.redirectUri, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PlaceApi - axios parameter creator
 * @export
 */
export const PlaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 場所を作成する
         * @param {PlaceCreateRequest} placeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeCreate: async (placeCreateRequest: PlaceCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeCreateRequest' is not null or undefined
            assertParamExists('placeCreate', 'placeCreateRequest', placeCreateRequest)
            const localVarPath = `/places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(placeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 場所を削除する
         * @param {number} id 削除する場所のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('placeDelete', 'id', id)
            const localVarPath = `/places/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 場所一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeList: async (id?: number, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 場所を更新する
         * @param {number} id 更新する場所のID
         * @param {PlaceUpdateRequest} placeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeUpdate: async (id: number, placeUpdateRequest: PlaceUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('placeUpdate', 'id', id)
            // verify required parameter 'placeUpdateRequest' is not null or undefined
            assertParamExists('placeUpdate', 'placeUpdateRequest', placeUpdateRequest)
            const localVarPath = `/places/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(placeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaceApi - functional programming interface
 * @export
 */
export const PlaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 場所を作成する
         * @param {PlaceCreateRequest} placeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeCreate(placeCreateRequest: PlaceCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaceCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placeCreate(placeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 場所を削除する
         * @param {number} id 削除する場所のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 場所一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeList(id?: number, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placeList(id, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 場所を更新する
         * @param {number} id 更新する場所のID
         * @param {PlaceUpdateRequest} placeUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeUpdate(id: number, placeUpdateRequest: PlaceUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placeUpdate(id, placeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlaceApi - factory interface
 * @export
 */
export const PlaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlaceApiFp(configuration)
    return {
        /**
         * 
         * @summary 場所を作成する
         * @param {PlaceApiPlaceCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeCreate(requestParameters: PlaceApiPlaceCreateRequest, options?: AxiosRequestConfig): AxiosPromise<PlaceCreateResponse> {
            return localVarFp.placeCreate(requestParameters.placeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 場所を削除する
         * @param {PlaceApiPlaceDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeDelete(requestParameters: PlaceApiPlaceDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.placeDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 場所一覧を取得する
         * @param {PlaceApiPlaceListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeList(requestParameters: PlaceApiPlaceListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PlaceListResponse> {
            return localVarFp.placeList(requestParameters.id, requestParameters.page, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 場所を更新する
         * @param {PlaceApiPlaceUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeUpdate(requestParameters: PlaceApiPlaceUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.placeUpdate(requestParameters.id, requestParameters.placeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for placeCreate operation in PlaceApi.
 * @export
 * @interface PlaceApiPlaceCreateRequest
 */
export interface PlaceApiPlaceCreateRequest {
    /**
     * 
     * @type {PlaceCreateRequest}
     * @memberof PlaceApiPlaceCreate
     */
    readonly placeCreateRequest: PlaceCreateRequest
}

/**
 * Request parameters for placeDelete operation in PlaceApi.
 * @export
 * @interface PlaceApiPlaceDeleteRequest
 */
export interface PlaceApiPlaceDeleteRequest {
    /**
     * 削除する場所のID
     * @type {number}
     * @memberof PlaceApiPlaceDelete
     */
    readonly id: number
}

/**
 * Request parameters for placeList operation in PlaceApi.
 * @export
 * @interface PlaceApiPlaceListRequest
 */
export interface PlaceApiPlaceListRequest {
    /**
     * 
     * @type {number}
     * @memberof PlaceApiPlaceList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof PlaceApiPlaceList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PlaceApiPlaceList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for placeUpdate operation in PlaceApi.
 * @export
 * @interface PlaceApiPlaceUpdateRequest
 */
export interface PlaceApiPlaceUpdateRequest {
    /**
     * 更新する場所のID
     * @type {number}
     * @memberof PlaceApiPlaceUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PlaceUpdateRequest}
     * @memberof PlaceApiPlaceUpdate
     */
    readonly placeUpdateRequest: PlaceUpdateRequest
}

/**
 * PlaceApi - object-oriented interface
 * @export
 * @class PlaceApi
 * @extends {BaseAPI}
 */
export class PlaceApi extends BaseAPI {
    /**
     * 
     * @summary 場所を作成する
     * @param {PlaceApiPlaceCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public placeCreate(requestParameters: PlaceApiPlaceCreateRequest, options?: AxiosRequestConfig) {
        return PlaceApiFp(this.configuration).placeCreate(requestParameters.placeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 場所を削除する
     * @param {PlaceApiPlaceDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public placeDelete(requestParameters: PlaceApiPlaceDeleteRequest, options?: AxiosRequestConfig) {
        return PlaceApiFp(this.configuration).placeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 場所一覧を取得する
     * @param {PlaceApiPlaceListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public placeList(requestParameters: PlaceApiPlaceListRequest = {}, options?: AxiosRequestConfig) {
        return PlaceApiFp(this.configuration).placeList(requestParameters.id, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 場所を更新する
     * @param {PlaceApiPlaceUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    public placeUpdate(requestParameters: PlaceApiPlaceUpdateRequest, options?: AxiosRequestConfig) {
        return PlaceApiFp(this.configuration).placeUpdate(requestParameters.id, requestParameters.placeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoomApi - axios parameter creator
 * @export
 */
export const RoomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 新しいルームを作成する
         * @param {RoomCreateRequest} roomCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomCreate: async (roomCreateRequest: RoomCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomCreateRequest' is not null or undefined
            assertParamExists('roomCreate', 'roomCreateRequest', roomCreateRequest)
            const localVarPath = `/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ルームを削除する
         * @param {number} id 更新するルームのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roomDelete', 'id', id)
            const localVarPath = `/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ルームに参加する
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomJoinRoom: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roomJoinRoom', 'id', id)
            const localVarPath = `/rooms/{id}/joinRoom`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ルーム一覧を取得する
         * @param {string} [search] 
         * @param {number} [id] 
         * @param {string} [type] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withRoomParticipants] 
         * @param {boolean} [withRoomParticipantsUser] 
         * @param {boolean} [withActiveUserCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomList: async (search?: string, id?: number, type?: string, page?: number, pageSize?: number, withRoomParticipants?: boolean, withRoomParticipantsUser?: boolean, withActiveUserCount?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (withRoomParticipants !== undefined) {
                localVarQueryParameter['withRoomParticipants'] = withRoomParticipants;
            }

            if (withRoomParticipantsUser !== undefined) {
                localVarQueryParameter['withRoomParticipantsUser'] = withRoomParticipantsUser;
            }

            if (withActiveUserCount !== undefined) {
                localVarQueryParameter['withActiveUserCount'] = withActiveUserCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ルームを更新する
         * @param {number} id 更新するルームのID
         * @param {RoomUpdateRequest} roomUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomUpdate: async (id: number, roomUpdateRequest: RoomUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roomUpdate', 'id', id)
            // verify required parameter 'roomUpdateRequest' is not null or undefined
            assertParamExists('roomUpdate', 'roomUpdateRequest', roomUpdateRequest)
            const localVarPath = `/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ルームの録画設定を更新する
         * @param {number} id 
         * @param {RoomUpdateRecordingRuleRequest} roomUpdateRecordingRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomUpdateRecordingRule: async (id: number, roomUpdateRecordingRuleRequest: RoomUpdateRecordingRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roomUpdateRecordingRule', 'id', id)
            // verify required parameter 'roomUpdateRecordingRuleRequest' is not null or undefined
            assertParamExists('roomUpdateRecordingRule', 'roomUpdateRecordingRuleRequest', roomUpdateRecordingRuleRequest)
            const localVarPath = `/rooms/{id}/updateRecordingRule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomUpdateRecordingRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomApi - functional programming interface
 * @export
 */
export const RoomApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 新しいルームを作成する
         * @param {RoomCreateRequest} roomCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomCreate(roomCreateRequest: RoomCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomCreate(roomCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ルームを削除する
         * @param {number} id 更新するルームのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ルームに参加する
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomJoinRoom(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomJoinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomJoinRoom(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ルーム一覧を取得する
         * @param {string} [search] 
         * @param {number} [id] 
         * @param {string} [type] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withRoomParticipants] 
         * @param {boolean} [withRoomParticipantsUser] 
         * @param {boolean} [withActiveUserCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomList(search?: string, id?: number, type?: string, page?: number, pageSize?: number, withRoomParticipants?: boolean, withRoomParticipantsUser?: boolean, withActiveUserCount?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomList(search, id, type, page, pageSize, withRoomParticipants, withRoomParticipantsUser, withActiveUserCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ルームを更新する
         * @param {number} id 更新するルームのID
         * @param {RoomUpdateRequest} roomUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomUpdate(id: number, roomUpdateRequest: RoomUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomUpdate(id, roomUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ルームの録画設定を更新する
         * @param {number} id 
         * @param {RoomUpdateRecordingRuleRequest} roomUpdateRecordingRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomUpdateRecordingRule(id: number, roomUpdateRecordingRuleRequest: RoomUpdateRecordingRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomUpdateRecordingRule(id, roomUpdateRecordingRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomApi - factory interface
 * @export
 */
export const RoomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomApiFp(configuration)
    return {
        /**
         * 
         * @summary 新しいルームを作成する
         * @param {RoomApiRoomCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomCreate(requestParameters: RoomApiRoomCreateRequest, options?: AxiosRequestConfig): AxiosPromise<RoomCreateResponse> {
            return localVarFp.roomCreate(requestParameters.roomCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ルームを削除する
         * @param {RoomApiRoomDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomDelete(requestParameters: RoomApiRoomDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.roomDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ルームに参加する
         * @param {RoomApiRoomJoinRoomRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomJoinRoom(requestParameters: RoomApiRoomJoinRoomRequest, options?: AxiosRequestConfig): AxiosPromise<RoomJoinResponse> {
            return localVarFp.roomJoinRoom(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ルーム一覧を取得する
         * @param {RoomApiRoomListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomList(requestParameters: RoomApiRoomListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RoomListResponse> {
            return localVarFp.roomList(requestParameters.search, requestParameters.id, requestParameters.type, requestParameters.page, requestParameters.pageSize, requestParameters.withRoomParticipants, requestParameters.withRoomParticipantsUser, requestParameters.withActiveUserCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ルームを更新する
         * @param {RoomApiRoomUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomUpdate(requestParameters: RoomApiRoomUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.roomUpdate(requestParameters.id, requestParameters.roomUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ルームの録画設定を更新する
         * @param {RoomApiRoomUpdateRecordingRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomUpdateRecordingRule(requestParameters: RoomApiRoomUpdateRecordingRuleRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.roomUpdateRecordingRule(requestParameters.id, requestParameters.roomUpdateRecordingRuleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for roomCreate operation in RoomApi.
 * @export
 * @interface RoomApiRoomCreateRequest
 */
export interface RoomApiRoomCreateRequest {
    /**
     * 
     * @type {RoomCreateRequest}
     * @memberof RoomApiRoomCreate
     */
    readonly roomCreateRequest: RoomCreateRequest
}

/**
 * Request parameters for roomDelete operation in RoomApi.
 * @export
 * @interface RoomApiRoomDeleteRequest
 */
export interface RoomApiRoomDeleteRequest {
    /**
     * 更新するルームのID
     * @type {number}
     * @memberof RoomApiRoomDelete
     */
    readonly id: number
}

/**
 * Request parameters for roomJoinRoom operation in RoomApi.
 * @export
 * @interface RoomApiRoomJoinRoomRequest
 */
export interface RoomApiRoomJoinRoomRequest {
    /**
     * 
     * @type {number}
     * @memberof RoomApiRoomJoinRoom
     */
    readonly id: number
}

/**
 * Request parameters for roomList operation in RoomApi.
 * @export
 * @interface RoomApiRoomListRequest
 */
export interface RoomApiRoomListRequest {
    /**
     * 
     * @type {string}
     * @memberof RoomApiRoomList
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof RoomApiRoomList
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof RoomApiRoomList
     */
    readonly type?: string

    /**
     * 
     * @type {number}
     * @memberof RoomApiRoomList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof RoomApiRoomList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof RoomApiRoomList
     */
    readonly withRoomParticipants?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RoomApiRoomList
     */
    readonly withRoomParticipantsUser?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RoomApiRoomList
     */
    readonly withActiveUserCount?: boolean
}

/**
 * Request parameters for roomUpdate operation in RoomApi.
 * @export
 * @interface RoomApiRoomUpdateRequest
 */
export interface RoomApiRoomUpdateRequest {
    /**
     * 更新するルームのID
     * @type {number}
     * @memberof RoomApiRoomUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RoomUpdateRequest}
     * @memberof RoomApiRoomUpdate
     */
    readonly roomUpdateRequest: RoomUpdateRequest
}

/**
 * Request parameters for roomUpdateRecordingRule operation in RoomApi.
 * @export
 * @interface RoomApiRoomUpdateRecordingRuleRequest
 */
export interface RoomApiRoomUpdateRecordingRuleRequest {
    /**
     * 
     * @type {number}
     * @memberof RoomApiRoomUpdateRecordingRule
     */
    readonly id: number

    /**
     * 
     * @type {RoomUpdateRecordingRuleRequest}
     * @memberof RoomApiRoomUpdateRecordingRule
     */
    readonly roomUpdateRecordingRuleRequest: RoomUpdateRecordingRuleRequest
}

/**
 * RoomApi - object-oriented interface
 * @export
 * @class RoomApi
 * @extends {BaseAPI}
 */
export class RoomApi extends BaseAPI {
    /**
     * 
     * @summary 新しいルームを作成する
     * @param {RoomApiRoomCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomCreate(requestParameters: RoomApiRoomCreateRequest, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomCreate(requestParameters.roomCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ルームを削除する
     * @param {RoomApiRoomDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomDelete(requestParameters: RoomApiRoomDeleteRequest, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ルームに参加する
     * @param {RoomApiRoomJoinRoomRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomJoinRoom(requestParameters: RoomApiRoomJoinRoomRequest, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomJoinRoom(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ルーム一覧を取得する
     * @param {RoomApiRoomListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomList(requestParameters: RoomApiRoomListRequest = {}, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomList(requestParameters.search, requestParameters.id, requestParameters.type, requestParameters.page, requestParameters.pageSize, requestParameters.withRoomParticipants, requestParameters.withRoomParticipantsUser, requestParameters.withActiveUserCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ルームを更新する
     * @param {RoomApiRoomUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomUpdate(requestParameters: RoomApiRoomUpdateRequest, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomUpdate(requestParameters.id, requestParameters.roomUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ルームの録画設定を更新する
     * @param {RoomApiRoomUpdateRecordingRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomUpdateRecordingRule(requestParameters: RoomApiRoomUpdateRecordingRuleRequest, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomUpdateRecordingRule(requestParameters.id, requestParameters.roomUpdateRecordingRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoomConnectionApi - axios parameter creator
 * @export
 */
export const RoomConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ルームの入退出をする
         * @param {RoomConnectionUpdateRequest} roomConnectionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomConnectionUpdate: async (roomConnectionUpdateRequest: RoomConnectionUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomConnectionUpdateRequest' is not null or undefined
            assertParamExists('roomConnectionUpdate', 'roomConnectionUpdateRequest', roomConnectionUpdateRequest)
            const localVarPath = `/roomConnections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomConnectionUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomConnectionApi - functional programming interface
 * @export
 */
export const RoomConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ルームの入退出をする
         * @param {RoomConnectionUpdateRequest} roomConnectionUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomConnectionUpdate(roomConnectionUpdateRequest: RoomConnectionUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomConnectionUpdate(roomConnectionUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomConnectionApi - factory interface
 * @export
 */
export const RoomConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomConnectionApiFp(configuration)
    return {
        /**
         * 
         * @summary ルームの入退出をする
         * @param {RoomConnectionApiRoomConnectionUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomConnectionUpdate(requestParameters: RoomConnectionApiRoomConnectionUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.roomConnectionUpdate(requestParameters.roomConnectionUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for roomConnectionUpdate operation in RoomConnectionApi.
 * @export
 * @interface RoomConnectionApiRoomConnectionUpdateRequest
 */
export interface RoomConnectionApiRoomConnectionUpdateRequest {
    /**
     * 
     * @type {RoomConnectionUpdateRequest}
     * @memberof RoomConnectionApiRoomConnectionUpdate
     */
    readonly roomConnectionUpdateRequest: RoomConnectionUpdateRequest
}

/**
 * RoomConnectionApi - object-oriented interface
 * @export
 * @class RoomConnectionApi
 * @extends {BaseAPI}
 */
export class RoomConnectionApi extends BaseAPI {
    /**
     * 
     * @summary ルームの入退出をする
     * @param {RoomConnectionApiRoomConnectionUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomConnectionApi
     */
    public roomConnectionUpdate(requestParameters: RoomConnectionApiRoomConnectionUpdateRequest, options?: AxiosRequestConfig) {
        return RoomConnectionApiFp(this.configuration).roomConnectionUpdate(requestParameters.roomConnectionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoomMeetingApi - axios parameter creator
 * @export
 */
export const RoomMeetingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ルーム録画のコンポジションを作成する
         * @param {number} id コンポジションを作成するルーム録画のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomMeetingCreateComposition: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roomMeetingCreateComposition', 'id', id)
            const localVarPath = `/roomMeetings/{id}/createComposition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ルームミーティング一覧を取得する
         * @param {number} [id] 
         * @param {number} [roomId] 
         * @param {string} [roomSid] 
         * @param {string} [compositionSid] 
         * @param {string} [status] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withCompositionVideoUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomMeetingList: async (id?: number, roomId?: number, roomSid?: string, compositionSid?: string, status?: string, page?: number, pageSize?: number, withCompositionVideoUrl?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roomMeetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (roomId !== undefined) {
                localVarQueryParameter['roomId'] = roomId;
            }

            if (roomSid !== undefined) {
                localVarQueryParameter['roomSid'] = roomSid;
            }

            if (compositionSid !== undefined) {
                localVarQueryParameter['compositionSid'] = compositionSid;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (withCompositionVideoUrl !== undefined) {
                localVarQueryParameter['withCompositionVideoUrl'] = withCompositionVideoUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomMeetingApi - functional programming interface
 * @export
 */
export const RoomMeetingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomMeetingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ルーム録画のコンポジションを作成する
         * @param {number} id コンポジションを作成するルーム録画のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomMeetingCreateComposition(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomMeetingCreateComposition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ルームミーティング一覧を取得する
         * @param {number} [id] 
         * @param {number} [roomId] 
         * @param {string} [roomSid] 
         * @param {string} [compositionSid] 
         * @param {string} [status] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withCompositionVideoUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomMeetingList(id?: number, roomId?: number, roomSid?: string, compositionSid?: string, status?: string, page?: number, pageSize?: number, withCompositionVideoUrl?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomMeetingListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomMeetingList(id, roomId, roomSid, compositionSid, status, page, pageSize, withCompositionVideoUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomMeetingApi - factory interface
 * @export
 */
export const RoomMeetingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomMeetingApiFp(configuration)
    return {
        /**
         * 
         * @summary ルーム録画のコンポジションを作成する
         * @param {RoomMeetingApiRoomMeetingCreateCompositionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomMeetingCreateComposition(requestParameters: RoomMeetingApiRoomMeetingCreateCompositionRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.roomMeetingCreateComposition(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ルームミーティング一覧を取得する
         * @param {RoomMeetingApiRoomMeetingListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomMeetingList(requestParameters: RoomMeetingApiRoomMeetingListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RoomMeetingListResponse> {
            return localVarFp.roomMeetingList(requestParameters.id, requestParameters.roomId, requestParameters.roomSid, requestParameters.compositionSid, requestParameters.status, requestParameters.page, requestParameters.pageSize, requestParameters.withCompositionVideoUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for roomMeetingCreateComposition operation in RoomMeetingApi.
 * @export
 * @interface RoomMeetingApiRoomMeetingCreateCompositionRequest
 */
export interface RoomMeetingApiRoomMeetingCreateCompositionRequest {
    /**
     * コンポジションを作成するルーム録画のID
     * @type {number}
     * @memberof RoomMeetingApiRoomMeetingCreateComposition
     */
    readonly id: number
}

/**
 * Request parameters for roomMeetingList operation in RoomMeetingApi.
 * @export
 * @interface RoomMeetingApiRoomMeetingListRequest
 */
export interface RoomMeetingApiRoomMeetingListRequest {
    /**
     * 
     * @type {number}
     * @memberof RoomMeetingApiRoomMeetingList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof RoomMeetingApiRoomMeetingList
     */
    readonly roomId?: number

    /**
     * 
     * @type {string}
     * @memberof RoomMeetingApiRoomMeetingList
     */
    readonly roomSid?: string

    /**
     * 
     * @type {string}
     * @memberof RoomMeetingApiRoomMeetingList
     */
    readonly compositionSid?: string

    /**
     * 
     * @type {string}
     * @memberof RoomMeetingApiRoomMeetingList
     */
    readonly status?: string

    /**
     * 
     * @type {number}
     * @memberof RoomMeetingApiRoomMeetingList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof RoomMeetingApiRoomMeetingList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof RoomMeetingApiRoomMeetingList
     */
    readonly withCompositionVideoUrl?: boolean
}

/**
 * RoomMeetingApi - object-oriented interface
 * @export
 * @class RoomMeetingApi
 * @extends {BaseAPI}
 */
export class RoomMeetingApi extends BaseAPI {
    /**
     * 
     * @summary ルーム録画のコンポジションを作成する
     * @param {RoomMeetingApiRoomMeetingCreateCompositionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomMeetingApi
     */
    public roomMeetingCreateComposition(requestParameters: RoomMeetingApiRoomMeetingCreateCompositionRequest, options?: AxiosRequestConfig) {
        return RoomMeetingApiFp(this.configuration).roomMeetingCreateComposition(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ルームミーティング一覧を取得する
     * @param {RoomMeetingApiRoomMeetingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomMeetingApi
     */
    public roomMeetingList(requestParameters: RoomMeetingApiRoomMeetingListRequest = {}, options?: AxiosRequestConfig) {
        return RoomMeetingApiFp(this.configuration).roomMeetingList(requestParameters.id, requestParameters.roomId, requestParameters.roomSid, requestParameters.compositionSid, requestParameters.status, requestParameters.page, requestParameters.pageSize, requestParameters.withCompositionVideoUrl, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaxiAchievementApi - axios parameter creator
 * @export
 */
export const TaxiAchievementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary タクシ実績を作成する
         * @param {TaxiAchievementCreateRequest} taxiAchievementCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiAchievementCreate: async (taxiAchievementCreateRequest: TaxiAchievementCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxiAchievementCreateRequest' is not null or undefined
            assertParamExists('taxiAchievementCreate', 'taxiAchievementCreateRequest', taxiAchievementCreateRequest)
            const localVarPath = `/taxiAchievements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiAchievementCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiAchievementDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiAchievementDelete', 'id', id)
            const localVarPath = `/taxiAchievements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシ実績一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTaxiCompany] 
         * @param {boolean} [withTaxiCar] 
         * @param {boolean} [withDriverUser] 
         * @param {boolean} [withCustomerUser] 
         * @param {string} [rideAtFrom] 
         * @param {string} [rideAtTo] 
         * @param {string} [dropAtFrom] 
         * @param {string} [dropAtTo] 
         * @param {number} [taxiCompanyId] 
         * @param {number} [customerUserId] 
         * @param {boolean} [withTripLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiAchievementList: async (id?: number, page?: number, pageSize?: number, withTaxiCompany?: boolean, withTaxiCar?: boolean, withDriverUser?: boolean, withCustomerUser?: boolean, rideAtFrom?: string, rideAtTo?: string, dropAtFrom?: string, dropAtTo?: string, taxiCompanyId?: number, customerUserId?: number, withTripLog?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxiAchievements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (withTaxiCompany !== undefined) {
                localVarQueryParameter['withTaxiCompany'] = withTaxiCompany;
            }

            if (withTaxiCar !== undefined) {
                localVarQueryParameter['withTaxiCar'] = withTaxiCar;
            }

            if (withDriverUser !== undefined) {
                localVarQueryParameter['withDriverUser'] = withDriverUser;
            }

            if (withCustomerUser !== undefined) {
                localVarQueryParameter['withCustomerUser'] = withCustomerUser;
            }

            if (rideAtFrom !== undefined) {
                localVarQueryParameter['rideAtFrom'] = (rideAtFrom as any instanceof Date) ?
                    (rideAtFrom as any).toISOString() :
                    rideAtFrom;
            }

            if (rideAtTo !== undefined) {
                localVarQueryParameter['rideAtTo'] = (rideAtTo as any instanceof Date) ?
                    (rideAtTo as any).toISOString() :
                    rideAtTo;
            }

            if (dropAtFrom !== undefined) {
                localVarQueryParameter['dropAtFrom'] = (dropAtFrom as any instanceof Date) ?
                    (dropAtFrom as any).toISOString() :
                    dropAtFrom;
            }

            if (dropAtTo !== undefined) {
                localVarQueryParameter['dropAtTo'] = (dropAtTo as any instanceof Date) ?
                    (dropAtTo as any).toISOString() :
                    dropAtTo;
            }

            if (taxiCompanyId !== undefined) {
                localVarQueryParameter['taxiCompanyId'] = taxiCompanyId;
            }

            if (customerUserId !== undefined) {
                localVarQueryParameter['customerUserId'] = customerUserId;
            }

            if (withTripLog !== undefined) {
                localVarQueryParameter['withTripLog'] = withTripLog;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシー実績を更新
         * @param {number} id 更新するタクシー実績のID
         * @param {TaxiAchievementUpdateRequest} taxiAchievementUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiAchievementUpdate: async (id: number, taxiAchievementUpdateRequest: TaxiAchievementUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiAchievementUpdate', 'id', id)
            // verify required parameter 'taxiAchievementUpdateRequest' is not null or undefined
            assertParamExists('taxiAchievementUpdate', 'taxiAchievementUpdateRequest', taxiAchievementUpdateRequest)
            const localVarPath = `/taxiAchievements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiAchievementUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxiAchievementApi - functional programming interface
 * @export
 */
export const TaxiAchievementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxiAchievementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary タクシ実績を作成する
         * @param {TaxiAchievementCreateRequest} taxiAchievementCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiAchievementCreate(taxiAchievementCreateRequest: TaxiAchievementCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiAchievementCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiAchievementCreate(taxiAchievementCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiAchievementDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiAchievementDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシ実績一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTaxiCompany] 
         * @param {boolean} [withTaxiCar] 
         * @param {boolean} [withDriverUser] 
         * @param {boolean} [withCustomerUser] 
         * @param {string} [rideAtFrom] 
         * @param {string} [rideAtTo] 
         * @param {string} [dropAtFrom] 
         * @param {string} [dropAtTo] 
         * @param {number} [taxiCompanyId] 
         * @param {number} [customerUserId] 
         * @param {boolean} [withTripLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiAchievementList(id?: number, page?: number, pageSize?: number, withTaxiCompany?: boolean, withTaxiCar?: boolean, withDriverUser?: boolean, withCustomerUser?: boolean, rideAtFrom?: string, rideAtTo?: string, dropAtFrom?: string, dropAtTo?: string, taxiCompanyId?: number, customerUserId?: number, withTripLog?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiAchievementListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiAchievementList(id, page, pageSize, withTaxiCompany, withTaxiCar, withDriverUser, withCustomerUser, rideAtFrom, rideAtTo, dropAtFrom, dropAtTo, taxiCompanyId, customerUserId, withTripLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシー実績を更新
         * @param {number} id 更新するタクシー実績のID
         * @param {TaxiAchievementUpdateRequest} taxiAchievementUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiAchievementUpdate(id: number, taxiAchievementUpdateRequest: TaxiAchievementUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiAchievementUpdate(id, taxiAchievementUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxiAchievementApi - factory interface
 * @export
 */
export const TaxiAchievementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxiAchievementApiFp(configuration)
    return {
        /**
         * 
         * @summary タクシ実績を作成する
         * @param {TaxiAchievementApiTaxiAchievementCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiAchievementCreate(requestParameters: TaxiAchievementApiTaxiAchievementCreateRequest, options?: AxiosRequestConfig): AxiosPromise<TaxiAchievementCreateResponse> {
            return localVarFp.taxiAchievementCreate(requestParameters.taxiAchievementCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaxiAchievementApiTaxiAchievementDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiAchievementDelete(requestParameters: TaxiAchievementApiTaxiAchievementDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiAchievementDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシ実績一覧を取得する
         * @param {TaxiAchievementApiTaxiAchievementListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiAchievementList(requestParameters: TaxiAchievementApiTaxiAchievementListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxiAchievementListResponse> {
            return localVarFp.taxiAchievementList(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.withTaxiCompany, requestParameters.withTaxiCar, requestParameters.withDriverUser, requestParameters.withCustomerUser, requestParameters.rideAtFrom, requestParameters.rideAtTo, requestParameters.dropAtFrom, requestParameters.dropAtTo, requestParameters.taxiCompanyId, requestParameters.customerUserId, requestParameters.withTripLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシー実績を更新
         * @param {TaxiAchievementApiTaxiAchievementUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiAchievementUpdate(requestParameters: TaxiAchievementApiTaxiAchievementUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiAchievementUpdate(requestParameters.id, requestParameters.taxiAchievementUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taxiAchievementCreate operation in TaxiAchievementApi.
 * @export
 * @interface TaxiAchievementApiTaxiAchievementCreateRequest
 */
export interface TaxiAchievementApiTaxiAchievementCreateRequest {
    /**
     * 
     * @type {TaxiAchievementCreateRequest}
     * @memberof TaxiAchievementApiTaxiAchievementCreate
     */
    readonly taxiAchievementCreateRequest: TaxiAchievementCreateRequest
}

/**
 * Request parameters for taxiAchievementDelete operation in TaxiAchievementApi.
 * @export
 * @interface TaxiAchievementApiTaxiAchievementDeleteRequest
 */
export interface TaxiAchievementApiTaxiAchievementDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementApiTaxiAchievementDelete
     */
    readonly id: number
}

/**
 * Request parameters for taxiAchievementList operation in TaxiAchievementApi.
 * @export
 * @interface TaxiAchievementApiTaxiAchievementListRequest
 */
export interface TaxiAchievementApiTaxiAchievementListRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly withTaxiCompany?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly withTaxiCar?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly withDriverUser?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly withCustomerUser?: boolean

    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly rideAtFrom?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly rideAtTo?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly dropAtFrom?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly dropAtTo?: string

    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly taxiCompanyId?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly customerUserId?: number

    /**
     * 
     * @type {boolean}
     * @memberof TaxiAchievementApiTaxiAchievementList
     */
    readonly withTripLog?: boolean
}

/**
 * Request parameters for taxiAchievementUpdate operation in TaxiAchievementApi.
 * @export
 * @interface TaxiAchievementApiTaxiAchievementUpdateRequest
 */
export interface TaxiAchievementApiTaxiAchievementUpdateRequest {
    /**
     * 更新するタクシー実績のID
     * @type {number}
     * @memberof TaxiAchievementApiTaxiAchievementUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TaxiAchievementUpdateRequest}
     * @memberof TaxiAchievementApiTaxiAchievementUpdate
     */
    readonly taxiAchievementUpdateRequest: TaxiAchievementUpdateRequest
}

/**
 * TaxiAchievementApi - object-oriented interface
 * @export
 * @class TaxiAchievementApi
 * @extends {BaseAPI}
 */
export class TaxiAchievementApi extends BaseAPI {
    /**
     * 
     * @summary タクシ実績を作成する
     * @param {TaxiAchievementApiTaxiAchievementCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiAchievementApi
     */
    public taxiAchievementCreate(requestParameters: TaxiAchievementApiTaxiAchievementCreateRequest, options?: AxiosRequestConfig) {
        return TaxiAchievementApiFp(this.configuration).taxiAchievementCreate(requestParameters.taxiAchievementCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaxiAchievementApiTaxiAchievementDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiAchievementApi
     */
    public taxiAchievementDelete(requestParameters: TaxiAchievementApiTaxiAchievementDeleteRequest, options?: AxiosRequestConfig) {
        return TaxiAchievementApiFp(this.configuration).taxiAchievementDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシ実績一覧を取得する
     * @param {TaxiAchievementApiTaxiAchievementListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiAchievementApi
     */
    public taxiAchievementList(requestParameters: TaxiAchievementApiTaxiAchievementListRequest = {}, options?: AxiosRequestConfig) {
        return TaxiAchievementApiFp(this.configuration).taxiAchievementList(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.withTaxiCompany, requestParameters.withTaxiCar, requestParameters.withDriverUser, requestParameters.withCustomerUser, requestParameters.rideAtFrom, requestParameters.rideAtTo, requestParameters.dropAtFrom, requestParameters.dropAtTo, requestParameters.taxiCompanyId, requestParameters.customerUserId, requestParameters.withTripLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシー実績を更新
     * @param {TaxiAchievementApiTaxiAchievementUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiAchievementApi
     */
    public taxiAchievementUpdate(requestParameters: TaxiAchievementApiTaxiAchievementUpdateRequest, options?: AxiosRequestConfig) {
        return TaxiAchievementApiFp(this.configuration).taxiAchievementUpdate(requestParameters.id, requestParameters.taxiAchievementUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaxiCarApi - axios parameter creator
 * @export
 */
export const TaxiCarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary タクシ車一覧を取得する
         * @param {number} [id] 
         * @param {number} [taxiCompanyId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCarList: async (id?: number, taxiCompanyId?: number, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxiCars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (taxiCompanyId !== undefined) {
                localVarQueryParameter['taxiCompanyId'] = taxiCompanyId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxiCarApi - functional programming interface
 * @export
 */
export const TaxiCarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxiCarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary タクシ車一覧を取得する
         * @param {number} [id] 
         * @param {number} [taxiCompanyId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiCarList(id?: number, taxiCompanyId?: number, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiCarListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiCarList(id, taxiCompanyId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxiCarApi - factory interface
 * @export
 */
export const TaxiCarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxiCarApiFp(configuration)
    return {
        /**
         * 
         * @summary タクシ車一覧を取得する
         * @param {TaxiCarApiTaxiCarListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCarList(requestParameters: TaxiCarApiTaxiCarListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxiCarListResponse> {
            return localVarFp.taxiCarList(requestParameters.id, requestParameters.taxiCompanyId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taxiCarList operation in TaxiCarApi.
 * @export
 * @interface TaxiCarApiTaxiCarListRequest
 */
export interface TaxiCarApiTaxiCarListRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiCarApiTaxiCarList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiCarApiTaxiCarList
     */
    readonly taxiCompanyId?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiCarApiTaxiCarList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiCarApiTaxiCarList
     */
    readonly pageSize?: number
}

/**
 * TaxiCarApi - object-oriented interface
 * @export
 * @class TaxiCarApi
 * @extends {BaseAPI}
 */
export class TaxiCarApi extends BaseAPI {
    /**
     * 
     * @summary タクシ車一覧を取得する
     * @param {TaxiCarApiTaxiCarListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiCarApi
     */
    public taxiCarList(requestParameters: TaxiCarApiTaxiCarListRequest = {}, options?: AxiosRequestConfig) {
        return TaxiCarApiFp(this.configuration).taxiCarList(requestParameters.id, requestParameters.taxiCompanyId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaxiCompanyApi - axios parameter creator
 * @export
 */
export const TaxiCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary タクシー会社を削除する
         * @param {number} id 削除するタクシー会社のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCompanyDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiCompanyDelete', 'id', id)
            const localVarPath = `/taxiCompanies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシ会社一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCompanyList: async (id?: number, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxiCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシー会社を更新する
         * @param {number} id 更新する場所のID
         * @param {TaxiCompanyUpdateRequest} taxiCompanyUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCompanyUpdate: async (id: number, taxiCompanyUpdateRequest: TaxiCompanyUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiCompanyUpdate', 'id', id)
            // verify required parameter 'taxiCompanyUpdateRequest' is not null or undefined
            assertParamExists('taxiCompanyUpdate', 'taxiCompanyUpdateRequest', taxiCompanyUpdateRequest)
            const localVarPath = `/taxiCompanies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiCompanyUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシー会社を作成する
         * @param {TaxiCompanyCreateRequest} taxiCompanyCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCopmpanyCreate: async (taxiCompanyCreateRequest: TaxiCompanyCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxiCompanyCreateRequest' is not null or undefined
            assertParamExists('taxiCopmpanyCreate', 'taxiCompanyCreateRequest', taxiCompanyCreateRequest)
            const localVarPath = `/taxiCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiCompanyCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxiCompanyApi - functional programming interface
 * @export
 */
export const TaxiCompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxiCompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary タクシー会社を削除する
         * @param {number} id 削除するタクシー会社のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiCompanyDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiCompanyDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシ会社一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiCompanyList(id?: number, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiCompanyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiCompanyList(id, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシー会社を更新する
         * @param {number} id 更新する場所のID
         * @param {TaxiCompanyUpdateRequest} taxiCompanyUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiCompanyUpdate(id: number, taxiCompanyUpdateRequest: TaxiCompanyUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiCompanyUpdate(id, taxiCompanyUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシー会社を作成する
         * @param {TaxiCompanyCreateRequest} taxiCompanyCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiCopmpanyCreate(taxiCompanyCreateRequest: TaxiCompanyCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiCompanyCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiCopmpanyCreate(taxiCompanyCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxiCompanyApi - factory interface
 * @export
 */
export const TaxiCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxiCompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary タクシー会社を削除する
         * @param {TaxiCompanyApiTaxiCompanyDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCompanyDelete(requestParameters: TaxiCompanyApiTaxiCompanyDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiCompanyDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシ会社一覧を取得する
         * @param {TaxiCompanyApiTaxiCompanyListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCompanyList(requestParameters: TaxiCompanyApiTaxiCompanyListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxiCompanyListResponse> {
            return localVarFp.taxiCompanyList(requestParameters.id, requestParameters.page, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシー会社を更新する
         * @param {TaxiCompanyApiTaxiCompanyUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCompanyUpdate(requestParameters: TaxiCompanyApiTaxiCompanyUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiCompanyUpdate(requestParameters.id, requestParameters.taxiCompanyUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシー会社を作成する
         * @param {TaxiCompanyApiTaxiCopmpanyCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiCopmpanyCreate(requestParameters: TaxiCompanyApiTaxiCopmpanyCreateRequest, options?: AxiosRequestConfig): AxiosPromise<TaxiCompanyCreateResponse> {
            return localVarFp.taxiCopmpanyCreate(requestParameters.taxiCompanyCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taxiCompanyDelete operation in TaxiCompanyApi.
 * @export
 * @interface TaxiCompanyApiTaxiCompanyDeleteRequest
 */
export interface TaxiCompanyApiTaxiCompanyDeleteRequest {
    /**
     * 削除するタクシー会社のID
     * @type {number}
     * @memberof TaxiCompanyApiTaxiCompanyDelete
     */
    readonly id: number
}

/**
 * Request parameters for taxiCompanyList operation in TaxiCompanyApi.
 * @export
 * @interface TaxiCompanyApiTaxiCompanyListRequest
 */
export interface TaxiCompanyApiTaxiCompanyListRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiCompanyApiTaxiCompanyList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiCompanyApiTaxiCompanyList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiCompanyApiTaxiCompanyList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for taxiCompanyUpdate operation in TaxiCompanyApi.
 * @export
 * @interface TaxiCompanyApiTaxiCompanyUpdateRequest
 */
export interface TaxiCompanyApiTaxiCompanyUpdateRequest {
    /**
     * 更新する場所のID
     * @type {number}
     * @memberof TaxiCompanyApiTaxiCompanyUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TaxiCompanyUpdateRequest}
     * @memberof TaxiCompanyApiTaxiCompanyUpdate
     */
    readonly taxiCompanyUpdateRequest: TaxiCompanyUpdateRequest
}

/**
 * Request parameters for taxiCopmpanyCreate operation in TaxiCompanyApi.
 * @export
 * @interface TaxiCompanyApiTaxiCopmpanyCreateRequest
 */
export interface TaxiCompanyApiTaxiCopmpanyCreateRequest {
    /**
     * 
     * @type {TaxiCompanyCreateRequest}
     * @memberof TaxiCompanyApiTaxiCopmpanyCreate
     */
    readonly taxiCompanyCreateRequest: TaxiCompanyCreateRequest
}

/**
 * TaxiCompanyApi - object-oriented interface
 * @export
 * @class TaxiCompanyApi
 * @extends {BaseAPI}
 */
export class TaxiCompanyApi extends BaseAPI {
    /**
     * 
     * @summary タクシー会社を削除する
     * @param {TaxiCompanyApiTaxiCompanyDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiCompanyApi
     */
    public taxiCompanyDelete(requestParameters: TaxiCompanyApiTaxiCompanyDeleteRequest, options?: AxiosRequestConfig) {
        return TaxiCompanyApiFp(this.configuration).taxiCompanyDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシ会社一覧を取得する
     * @param {TaxiCompanyApiTaxiCompanyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiCompanyApi
     */
    public taxiCompanyList(requestParameters: TaxiCompanyApiTaxiCompanyListRequest = {}, options?: AxiosRequestConfig) {
        return TaxiCompanyApiFp(this.configuration).taxiCompanyList(requestParameters.id, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシー会社を更新する
     * @param {TaxiCompanyApiTaxiCompanyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiCompanyApi
     */
    public taxiCompanyUpdate(requestParameters: TaxiCompanyApiTaxiCompanyUpdateRequest, options?: AxiosRequestConfig) {
        return TaxiCompanyApiFp(this.configuration).taxiCompanyUpdate(requestParameters.id, requestParameters.taxiCompanyUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシー会社を作成する
     * @param {TaxiCompanyApiTaxiCopmpanyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiCompanyApi
     */
    public taxiCopmpanyCreate(requestParameters: TaxiCompanyApiTaxiCopmpanyCreateRequest, options?: AxiosRequestConfig) {
        return TaxiCompanyApiFp(this.configuration).taxiCopmpanyCreate(requestParameters.taxiCompanyCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaxiDailyScheduleApi - axios parameter creator
 * @export
 */
export const TaxiDailyScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [taxiCompanyId] 
         * @param {string} [date] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiDailyScheduleList: async (taxiCompanyId?: number, date?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxiDailySchedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (taxiCompanyId !== undefined) {
                localVarQueryParameter['taxiCompanyId'] = taxiCompanyId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [taxiCompanyId] 
         * @param {string} [date] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiDailyScheduleMerge: async (taxiCompanyId?: number, date?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxiDailySchedules/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (taxiCompanyId !== undefined) {
                localVarQueryParameter['taxiCompanyId'] = taxiCompanyId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TaxiDailyScheduleSaveRequest} taxiDailyScheduleSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiDailyScheduleSave: async (taxiDailyScheduleSaveRequest: TaxiDailyScheduleSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxiDailyScheduleSaveRequest' is not null or undefined
            assertParamExists('taxiDailyScheduleSave', 'taxiDailyScheduleSaveRequest', taxiDailyScheduleSaveRequest)
            const localVarPath = `/taxiDailySchedules/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiDailyScheduleSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxiDailyScheduleApi - functional programming interface
 * @export
 */
export const TaxiDailyScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxiDailyScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [taxiCompanyId] 
         * @param {string} [date] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiDailyScheduleList(taxiCompanyId?: number, date?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiDailyScheduleListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiDailyScheduleList(taxiCompanyId, date, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [taxiCompanyId] 
         * @param {string} [date] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiDailyScheduleMerge(taxiCompanyId?: number, date?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiDailyScheduleMergeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiDailyScheduleMerge(taxiCompanyId, date, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TaxiDailyScheduleSaveRequest} taxiDailyScheduleSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiDailyScheduleSave(taxiDailyScheduleSaveRequest: TaxiDailyScheduleSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiDailyScheduleSave(taxiDailyScheduleSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxiDailyScheduleApi - factory interface
 * @export
 */
export const TaxiDailyScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxiDailyScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {TaxiDailyScheduleApiTaxiDailyScheduleListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiDailyScheduleList(requestParameters: TaxiDailyScheduleApiTaxiDailyScheduleListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxiDailyScheduleListResponse> {
            return localVarFp.taxiDailyScheduleList(requestParameters.taxiCompanyId, requestParameters.date, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaxiDailyScheduleApiTaxiDailyScheduleMergeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiDailyScheduleMerge(requestParameters: TaxiDailyScheduleApiTaxiDailyScheduleMergeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxiDailyScheduleMergeResponse> {
            return localVarFp.taxiDailyScheduleMerge(requestParameters.taxiCompanyId, requestParameters.date, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaxiDailyScheduleApiTaxiDailyScheduleSaveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiDailyScheduleSave(requestParameters: TaxiDailyScheduleApiTaxiDailyScheduleSaveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiDailyScheduleSave(requestParameters.taxiDailyScheduleSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taxiDailyScheduleList operation in TaxiDailyScheduleApi.
 * @export
 * @interface TaxiDailyScheduleApiTaxiDailyScheduleListRequest
 */
export interface TaxiDailyScheduleApiTaxiDailyScheduleListRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleList
     */
    readonly taxiCompanyId?: number

    /**
     * 
     * @type {string}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleList
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleList
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleList
     */
    readonly endDate?: string
}

/**
 * Request parameters for taxiDailyScheduleMerge operation in TaxiDailyScheduleApi.
 * @export
 * @interface TaxiDailyScheduleApiTaxiDailyScheduleMergeRequest
 */
export interface TaxiDailyScheduleApiTaxiDailyScheduleMergeRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleMerge
     */
    readonly taxiCompanyId?: number

    /**
     * 
     * @type {string}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleMerge
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleMerge
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleMerge
     */
    readonly endDate?: string
}

/**
 * Request parameters for taxiDailyScheduleSave operation in TaxiDailyScheduleApi.
 * @export
 * @interface TaxiDailyScheduleApiTaxiDailyScheduleSaveRequest
 */
export interface TaxiDailyScheduleApiTaxiDailyScheduleSaveRequest {
    /**
     * 
     * @type {TaxiDailyScheduleSaveRequest}
     * @memberof TaxiDailyScheduleApiTaxiDailyScheduleSave
     */
    readonly taxiDailyScheduleSaveRequest: TaxiDailyScheduleSaveRequest
}

/**
 * TaxiDailyScheduleApi - object-oriented interface
 * @export
 * @class TaxiDailyScheduleApi
 * @extends {BaseAPI}
 */
export class TaxiDailyScheduleApi extends BaseAPI {
    /**
     * 
     * @param {TaxiDailyScheduleApiTaxiDailyScheduleListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiDailyScheduleApi
     */
    public taxiDailyScheduleList(requestParameters: TaxiDailyScheduleApiTaxiDailyScheduleListRequest = {}, options?: AxiosRequestConfig) {
        return TaxiDailyScheduleApiFp(this.configuration).taxiDailyScheduleList(requestParameters.taxiCompanyId, requestParameters.date, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaxiDailyScheduleApiTaxiDailyScheduleMergeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiDailyScheduleApi
     */
    public taxiDailyScheduleMerge(requestParameters: TaxiDailyScheduleApiTaxiDailyScheduleMergeRequest = {}, options?: AxiosRequestConfig) {
        return TaxiDailyScheduleApiFp(this.configuration).taxiDailyScheduleMerge(requestParameters.taxiCompanyId, requestParameters.date, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaxiDailyScheduleApiTaxiDailyScheduleSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiDailyScheduleApi
     */
    public taxiDailyScheduleSave(requestParameters: TaxiDailyScheduleApiTaxiDailyScheduleSaveRequest, options?: AxiosRequestConfig) {
        return TaxiDailyScheduleApiFp(this.configuration).taxiDailyScheduleSave(requestParameters.taxiDailyScheduleSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaxiLandmarkApi - axios parameter creator
 * @export
 */
export const TaxiLandmarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ランドマークを作成する
         * @param {TaxiLandmarkCreateRequest} taxiLandmarkCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiLandmarkCreate: async (taxiLandmarkCreateRequest: TaxiLandmarkCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxiLandmarkCreateRequest' is not null or undefined
            assertParamExists('taxiLandmarkCreate', 'taxiLandmarkCreateRequest', taxiLandmarkCreateRequest)
            const localVarPath = `/taxiLandmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiLandmarkCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ランドマークを削除する
         * @param {number} id 削除するランドマークのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiLandmarkDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiLandmarkDelete', 'id', id)
            const localVarPath = `/taxiLandmarks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシ乗り場一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiLandmarkList: async (id?: number, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxiLandmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ランドマークを更新する
         * @param {number} id 更新する場所のID
         * @param {TaxiLandmarkUpdateRequest} taxiLandmarkUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiLandmarkUpdate: async (id: number, taxiLandmarkUpdateRequest: TaxiLandmarkUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiLandmarkUpdate', 'id', id)
            // verify required parameter 'taxiLandmarkUpdateRequest' is not null or undefined
            assertParamExists('taxiLandmarkUpdate', 'taxiLandmarkUpdateRequest', taxiLandmarkUpdateRequest)
            const localVarPath = `/taxiLandmarks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiLandmarkUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxiLandmarkApi - functional programming interface
 * @export
 */
export const TaxiLandmarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxiLandmarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ランドマークを作成する
         * @param {TaxiLandmarkCreateRequest} taxiLandmarkCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiLandmarkCreate(taxiLandmarkCreateRequest: TaxiLandmarkCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiLandmarkCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiLandmarkCreate(taxiLandmarkCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ランドマークを削除する
         * @param {number} id 削除するランドマークのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiLandmarkDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiLandmarkDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシ乗り場一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiLandmarkList(id?: number, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiLandmarkListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiLandmarkList(id, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ランドマークを更新する
         * @param {number} id 更新する場所のID
         * @param {TaxiLandmarkUpdateRequest} taxiLandmarkUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiLandmarkUpdate(id: number, taxiLandmarkUpdateRequest: TaxiLandmarkUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiLandmarkUpdate(id, taxiLandmarkUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxiLandmarkApi - factory interface
 * @export
 */
export const TaxiLandmarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxiLandmarkApiFp(configuration)
    return {
        /**
         * 
         * @summary ランドマークを作成する
         * @param {TaxiLandmarkApiTaxiLandmarkCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiLandmarkCreate(requestParameters: TaxiLandmarkApiTaxiLandmarkCreateRequest, options?: AxiosRequestConfig): AxiosPromise<TaxiLandmarkCreateResponse> {
            return localVarFp.taxiLandmarkCreate(requestParameters.taxiLandmarkCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ランドマークを削除する
         * @param {TaxiLandmarkApiTaxiLandmarkDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiLandmarkDelete(requestParameters: TaxiLandmarkApiTaxiLandmarkDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiLandmarkDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシ乗り場一覧を取得する
         * @param {TaxiLandmarkApiTaxiLandmarkListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiLandmarkList(requestParameters: TaxiLandmarkApiTaxiLandmarkListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxiLandmarkListResponse> {
            return localVarFp.taxiLandmarkList(requestParameters.id, requestParameters.page, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ランドマークを更新する
         * @param {TaxiLandmarkApiTaxiLandmarkUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiLandmarkUpdate(requestParameters: TaxiLandmarkApiTaxiLandmarkUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiLandmarkUpdate(requestParameters.id, requestParameters.taxiLandmarkUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taxiLandmarkCreate operation in TaxiLandmarkApi.
 * @export
 * @interface TaxiLandmarkApiTaxiLandmarkCreateRequest
 */
export interface TaxiLandmarkApiTaxiLandmarkCreateRequest {
    /**
     * 
     * @type {TaxiLandmarkCreateRequest}
     * @memberof TaxiLandmarkApiTaxiLandmarkCreate
     */
    readonly taxiLandmarkCreateRequest: TaxiLandmarkCreateRequest
}

/**
 * Request parameters for taxiLandmarkDelete operation in TaxiLandmarkApi.
 * @export
 * @interface TaxiLandmarkApiTaxiLandmarkDeleteRequest
 */
export interface TaxiLandmarkApiTaxiLandmarkDeleteRequest {
    /**
     * 削除するランドマークのID
     * @type {number}
     * @memberof TaxiLandmarkApiTaxiLandmarkDelete
     */
    readonly id: number
}

/**
 * Request parameters for taxiLandmarkList operation in TaxiLandmarkApi.
 * @export
 * @interface TaxiLandmarkApiTaxiLandmarkListRequest
 */
export interface TaxiLandmarkApiTaxiLandmarkListRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiLandmarkApiTaxiLandmarkList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiLandmarkApiTaxiLandmarkList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiLandmarkApiTaxiLandmarkList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for taxiLandmarkUpdate operation in TaxiLandmarkApi.
 * @export
 * @interface TaxiLandmarkApiTaxiLandmarkUpdateRequest
 */
export interface TaxiLandmarkApiTaxiLandmarkUpdateRequest {
    /**
     * 更新する場所のID
     * @type {number}
     * @memberof TaxiLandmarkApiTaxiLandmarkUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TaxiLandmarkUpdateRequest}
     * @memberof TaxiLandmarkApiTaxiLandmarkUpdate
     */
    readonly taxiLandmarkUpdateRequest: TaxiLandmarkUpdateRequest
}

/**
 * TaxiLandmarkApi - object-oriented interface
 * @export
 * @class TaxiLandmarkApi
 * @extends {BaseAPI}
 */
export class TaxiLandmarkApi extends BaseAPI {
    /**
     * 
     * @summary ランドマークを作成する
     * @param {TaxiLandmarkApiTaxiLandmarkCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiLandmarkApi
     */
    public taxiLandmarkCreate(requestParameters: TaxiLandmarkApiTaxiLandmarkCreateRequest, options?: AxiosRequestConfig) {
        return TaxiLandmarkApiFp(this.configuration).taxiLandmarkCreate(requestParameters.taxiLandmarkCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ランドマークを削除する
     * @param {TaxiLandmarkApiTaxiLandmarkDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiLandmarkApi
     */
    public taxiLandmarkDelete(requestParameters: TaxiLandmarkApiTaxiLandmarkDeleteRequest, options?: AxiosRequestConfig) {
        return TaxiLandmarkApiFp(this.configuration).taxiLandmarkDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシ乗り場一覧を取得する
     * @param {TaxiLandmarkApiTaxiLandmarkListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiLandmarkApi
     */
    public taxiLandmarkList(requestParameters: TaxiLandmarkApiTaxiLandmarkListRequest = {}, options?: AxiosRequestConfig) {
        return TaxiLandmarkApiFp(this.configuration).taxiLandmarkList(requestParameters.id, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ランドマークを更新する
     * @param {TaxiLandmarkApiTaxiLandmarkUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiLandmarkApi
     */
    public taxiLandmarkUpdate(requestParameters: TaxiLandmarkApiTaxiLandmarkUpdateRequest, options?: AxiosRequestConfig) {
        return TaxiLandmarkApiFp(this.configuration).taxiLandmarkUpdate(requestParameters.id, requestParameters.taxiLandmarkUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaxiReservationApi - axios parameter creator
 * @export
 */
export const TaxiReservationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary タクシ予約を登録する
         * @param {TaxiReservationCreateByTaxiCompanyRequest} taxiReservationCreateByTaxiCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationCreateByTaxiCompany: async (taxiReservationCreateByTaxiCompanyRequest: TaxiReservationCreateByTaxiCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxiReservationCreateByTaxiCompanyRequest' is not null or undefined
            assertParamExists('taxiReservationCreateByTaxiCompany', 'taxiReservationCreateByTaxiCompanyRequest', taxiReservationCreateByTaxiCompanyRequest)
            const localVarPath = `/taxiReservations/createByTaxiCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiReservationCreateByTaxiCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシ予約を登録する
         * @param {TaxiReservationCreateByUserRequest} taxiReservationCreateByUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationCreateByUser: async (taxiReservationCreateByUserRequest: TaxiReservationCreateByUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxiReservationCreateByUserRequest' is not null or undefined
            assertParamExists('taxiReservationCreateByUser', 'taxiReservationCreateByUserRequest', taxiReservationCreateByUserRequest)
            const localVarPath = `/taxiReservations/createByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiReservationCreateByUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiReservationDelete', 'id', id)
            const localVarPath = `/taxiReservations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシ予約一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTaxiCar] 
         * @param {boolean} [withTaxiCompany] 
         * @param {boolean} [withUser] 
         * @param {boolean} [withStartTaxiLandmark] 
         * @param {boolean} [withEndTaxiLandmark] 
         * @param {string} [rideAtFrom] 
         * @param {string} [rideAtTo] 
         * @param {string} [dropAtFrom] 
         * @param {string} [dropAtTo] 
         * @param {number} [taxiCompanyId] 
         * @param {string} [status] 
         * @param {string} [userInfoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationList: async (id?: number, page?: number, pageSize?: number, withTaxiCar?: boolean, withTaxiCompany?: boolean, withUser?: boolean, withStartTaxiLandmark?: boolean, withEndTaxiLandmark?: boolean, rideAtFrom?: string, rideAtTo?: string, dropAtFrom?: string, dropAtTo?: string, taxiCompanyId?: number, status?: string, userInfoCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxiReservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (withTaxiCar !== undefined) {
                localVarQueryParameter['withTaxiCar'] = withTaxiCar;
            }

            if (withTaxiCompany !== undefined) {
                localVarQueryParameter['withTaxiCompany'] = withTaxiCompany;
            }

            if (withUser !== undefined) {
                localVarQueryParameter['withUser'] = withUser;
            }

            if (withStartTaxiLandmark !== undefined) {
                localVarQueryParameter['withStartTaxiLandmark'] = withStartTaxiLandmark;
            }

            if (withEndTaxiLandmark !== undefined) {
                localVarQueryParameter['withEndTaxiLandmark'] = withEndTaxiLandmark;
            }

            if (rideAtFrom !== undefined) {
                localVarQueryParameter['rideAtFrom'] = (rideAtFrom as any instanceof Date) ?
                    (rideAtFrom as any).toISOString() :
                    rideAtFrom;
            }

            if (rideAtTo !== undefined) {
                localVarQueryParameter['rideAtTo'] = (rideAtTo as any instanceof Date) ?
                    (rideAtTo as any).toISOString() :
                    rideAtTo;
            }

            if (dropAtFrom !== undefined) {
                localVarQueryParameter['dropAtFrom'] = (dropAtFrom as any instanceof Date) ?
                    (dropAtFrom as any).toISOString() :
                    dropAtFrom;
            }

            if (dropAtTo !== undefined) {
                localVarQueryParameter['dropAtTo'] = (dropAtTo as any instanceof Date) ?
                    (dropAtTo as any).toISOString() :
                    dropAtTo;
            }

            if (taxiCompanyId !== undefined) {
                localVarQueryParameter['taxiCompanyId'] = taxiCompanyId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (userInfoCode !== undefined) {
                localVarQueryParameter['userInfoCode'] = userInfoCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TaxiReservationUpdateRequest} [taxiReservationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationUpdate: async (id: number, taxiReservationUpdateRequest?: TaxiReservationUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiReservationUpdate', 'id', id)
            const localVarPath = `/taxiReservations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiReservationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシ予約を更新する
         * @param {number} id 
         * @param {TaxiReservationUpdateByTaxiCompanyRequest} taxiReservationUpdateByTaxiCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationUpdateByTaxiCompany: async (id: number, taxiReservationUpdateByTaxiCompanyRequest: TaxiReservationUpdateByTaxiCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiReservationUpdateByTaxiCompany', 'id', id)
            // verify required parameter 'taxiReservationUpdateByTaxiCompanyRequest' is not null or undefined
            assertParamExists('taxiReservationUpdateByTaxiCompany', 'taxiReservationUpdateByTaxiCompanyRequest', taxiReservationUpdateByTaxiCompanyRequest)
            const localVarPath = `/taxiReservations/{id}/updateStatusByTaxiCompany`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiReservationUpdateByTaxiCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タクシ予約を更新する
         * @param {number} id 
         * @param {TaxiReservationUpdateByUserRequest} taxiReservationUpdateByUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationUpdateByUser: async (id: number, taxiReservationUpdateByUserRequest: TaxiReservationUpdateByUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taxiReservationUpdateByUser', 'id', id)
            // verify required parameter 'taxiReservationUpdateByUserRequest' is not null or undefined
            assertParamExists('taxiReservationUpdateByUser', 'taxiReservationUpdateByUserRequest', taxiReservationUpdateByUserRequest)
            const localVarPath = `/taxiReservations/{id}/updateStatusByUser`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiReservationUpdateByUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxiReservationApi - functional programming interface
 * @export
 */
export const TaxiReservationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxiReservationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary タクシ予約を登録する
         * @param {TaxiReservationCreateByTaxiCompanyRequest} taxiReservationCreateByTaxiCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiReservationCreateByTaxiCompany(taxiReservationCreateByTaxiCompanyRequest: TaxiReservationCreateByTaxiCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiReservationCreateByTaxiCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiReservationCreateByTaxiCompany(taxiReservationCreateByTaxiCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシ予約を登録する
         * @param {TaxiReservationCreateByUserRequest} taxiReservationCreateByUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiReservationCreateByUser(taxiReservationCreateByUserRequest: TaxiReservationCreateByUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiReservationCreateByUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiReservationCreateByUser(taxiReservationCreateByUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiReservationDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiReservationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシ予約一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTaxiCar] 
         * @param {boolean} [withTaxiCompany] 
         * @param {boolean} [withUser] 
         * @param {boolean} [withStartTaxiLandmark] 
         * @param {boolean} [withEndTaxiLandmark] 
         * @param {string} [rideAtFrom] 
         * @param {string} [rideAtTo] 
         * @param {string} [dropAtFrom] 
         * @param {string} [dropAtTo] 
         * @param {number} [taxiCompanyId] 
         * @param {string} [status] 
         * @param {string} [userInfoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiReservationList(id?: number, page?: number, pageSize?: number, withTaxiCar?: boolean, withTaxiCompany?: boolean, withUser?: boolean, withStartTaxiLandmark?: boolean, withEndTaxiLandmark?: boolean, rideAtFrom?: string, rideAtTo?: string, dropAtFrom?: string, dropAtTo?: string, taxiCompanyId?: number, status?: string, userInfoCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiReservationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiReservationList(id, page, pageSize, withTaxiCar, withTaxiCompany, withUser, withStartTaxiLandmark, withEndTaxiLandmark, rideAtFrom, rideAtTo, dropAtFrom, dropAtTo, taxiCompanyId, status, userInfoCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TaxiReservationUpdateRequest} [taxiReservationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiReservationUpdate(id: number, taxiReservationUpdateRequest?: TaxiReservationUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiReservationUpdate(id, taxiReservationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシ予約を更新する
         * @param {number} id 
         * @param {TaxiReservationUpdateByTaxiCompanyRequest} taxiReservationUpdateByTaxiCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiReservationUpdateByTaxiCompany(id: number, taxiReservationUpdateByTaxiCompanyRequest: TaxiReservationUpdateByTaxiCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiReservationUpdateByTaxiCompany(id, taxiReservationUpdateByTaxiCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary タクシ予約を更新する
         * @param {number} id 
         * @param {TaxiReservationUpdateByUserRequest} taxiReservationUpdateByUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiReservationUpdateByUser(id: number, taxiReservationUpdateByUserRequest: TaxiReservationUpdateByUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiReservationUpdateByUser(id, taxiReservationUpdateByUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxiReservationApi - factory interface
 * @export
 */
export const TaxiReservationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxiReservationApiFp(configuration)
    return {
        /**
         * 
         * @summary タクシ予約を登録する
         * @param {TaxiReservationApiTaxiReservationCreateByTaxiCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationCreateByTaxiCompany(requestParameters: TaxiReservationApiTaxiReservationCreateByTaxiCompanyRequest, options?: AxiosRequestConfig): AxiosPromise<TaxiReservationCreateByTaxiCompanyResponse> {
            return localVarFp.taxiReservationCreateByTaxiCompany(requestParameters.taxiReservationCreateByTaxiCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシ予約を登録する
         * @param {TaxiReservationApiTaxiReservationCreateByUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationCreateByUser(requestParameters: TaxiReservationApiTaxiReservationCreateByUserRequest, options?: AxiosRequestConfig): AxiosPromise<TaxiReservationCreateByUserResponse> {
            return localVarFp.taxiReservationCreateByUser(requestParameters.taxiReservationCreateByUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaxiReservationApiTaxiReservationDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationDelete(requestParameters: TaxiReservationApiTaxiReservationDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiReservationDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシ予約一覧を取得する
         * @param {TaxiReservationApiTaxiReservationListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationList(requestParameters: TaxiReservationApiTaxiReservationListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxiReservationListResponse> {
            return localVarFp.taxiReservationList(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.withTaxiCar, requestParameters.withTaxiCompany, requestParameters.withUser, requestParameters.withStartTaxiLandmark, requestParameters.withEndTaxiLandmark, requestParameters.rideAtFrom, requestParameters.rideAtTo, requestParameters.dropAtFrom, requestParameters.dropAtTo, requestParameters.taxiCompanyId, requestParameters.status, requestParameters.userInfoCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaxiReservationApiTaxiReservationUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationUpdate(requestParameters: TaxiReservationApiTaxiReservationUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiReservationUpdate(requestParameters.id, requestParameters.taxiReservationUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシ予約を更新する
         * @param {TaxiReservationApiTaxiReservationUpdateByTaxiCompanyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationUpdateByTaxiCompany(requestParameters: TaxiReservationApiTaxiReservationUpdateByTaxiCompanyRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiReservationUpdateByTaxiCompany(requestParameters.id, requestParameters.taxiReservationUpdateByTaxiCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タクシ予約を更新する
         * @param {TaxiReservationApiTaxiReservationUpdateByUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiReservationUpdateByUser(requestParameters: TaxiReservationApiTaxiReservationUpdateByUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiReservationUpdateByUser(requestParameters.id, requestParameters.taxiReservationUpdateByUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taxiReservationCreateByTaxiCompany operation in TaxiReservationApi.
 * @export
 * @interface TaxiReservationApiTaxiReservationCreateByTaxiCompanyRequest
 */
export interface TaxiReservationApiTaxiReservationCreateByTaxiCompanyRequest {
    /**
     * 
     * @type {TaxiReservationCreateByTaxiCompanyRequest}
     * @memberof TaxiReservationApiTaxiReservationCreateByTaxiCompany
     */
    readonly taxiReservationCreateByTaxiCompanyRequest: TaxiReservationCreateByTaxiCompanyRequest
}

/**
 * Request parameters for taxiReservationCreateByUser operation in TaxiReservationApi.
 * @export
 * @interface TaxiReservationApiTaxiReservationCreateByUserRequest
 */
export interface TaxiReservationApiTaxiReservationCreateByUserRequest {
    /**
     * 
     * @type {TaxiReservationCreateByUserRequest}
     * @memberof TaxiReservationApiTaxiReservationCreateByUser
     */
    readonly taxiReservationCreateByUserRequest: TaxiReservationCreateByUserRequest
}

/**
 * Request parameters for taxiReservationDelete operation in TaxiReservationApi.
 * @export
 * @interface TaxiReservationApiTaxiReservationDeleteRequest
 */
export interface TaxiReservationApiTaxiReservationDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationApiTaxiReservationDelete
     */
    readonly id: number
}

/**
 * Request parameters for taxiReservationList operation in TaxiReservationApi.
 * @export
 * @interface TaxiReservationApiTaxiReservationListRequest
 */
export interface TaxiReservationApiTaxiReservationListRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly withTaxiCar?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly withTaxiCompany?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly withUser?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly withStartTaxiLandmark?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly withEndTaxiLandmark?: boolean

    /**
     * 
     * @type {string}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly rideAtFrom?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly rideAtTo?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly dropAtFrom?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly dropAtTo?: string

    /**
     * 
     * @type {number}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly taxiCompanyId?: number

    /**
     * 
     * @type {string}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof TaxiReservationApiTaxiReservationList
     */
    readonly userInfoCode?: string
}

/**
 * Request parameters for taxiReservationUpdate operation in TaxiReservationApi.
 * @export
 * @interface TaxiReservationApiTaxiReservationUpdateRequest
 */
export interface TaxiReservationApiTaxiReservationUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationApiTaxiReservationUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TaxiReservationUpdateRequest}
     * @memberof TaxiReservationApiTaxiReservationUpdate
     */
    readonly taxiReservationUpdateRequest?: TaxiReservationUpdateRequest
}

/**
 * Request parameters for taxiReservationUpdateByTaxiCompany operation in TaxiReservationApi.
 * @export
 * @interface TaxiReservationApiTaxiReservationUpdateByTaxiCompanyRequest
 */
export interface TaxiReservationApiTaxiReservationUpdateByTaxiCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationApiTaxiReservationUpdateByTaxiCompany
     */
    readonly id: number

    /**
     * 
     * @type {TaxiReservationUpdateByTaxiCompanyRequest}
     * @memberof TaxiReservationApiTaxiReservationUpdateByTaxiCompany
     */
    readonly taxiReservationUpdateByTaxiCompanyRequest: TaxiReservationUpdateByTaxiCompanyRequest
}

/**
 * Request parameters for taxiReservationUpdateByUser operation in TaxiReservationApi.
 * @export
 * @interface TaxiReservationApiTaxiReservationUpdateByUserRequest
 */
export interface TaxiReservationApiTaxiReservationUpdateByUserRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiReservationApiTaxiReservationUpdateByUser
     */
    readonly id: number

    /**
     * 
     * @type {TaxiReservationUpdateByUserRequest}
     * @memberof TaxiReservationApiTaxiReservationUpdateByUser
     */
    readonly taxiReservationUpdateByUserRequest: TaxiReservationUpdateByUserRequest
}

/**
 * TaxiReservationApi - object-oriented interface
 * @export
 * @class TaxiReservationApi
 * @extends {BaseAPI}
 */
export class TaxiReservationApi extends BaseAPI {
    /**
     * 
     * @summary タクシ予約を登録する
     * @param {TaxiReservationApiTaxiReservationCreateByTaxiCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiReservationApi
     */
    public taxiReservationCreateByTaxiCompany(requestParameters: TaxiReservationApiTaxiReservationCreateByTaxiCompanyRequest, options?: AxiosRequestConfig) {
        return TaxiReservationApiFp(this.configuration).taxiReservationCreateByTaxiCompany(requestParameters.taxiReservationCreateByTaxiCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシ予約を登録する
     * @param {TaxiReservationApiTaxiReservationCreateByUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiReservationApi
     */
    public taxiReservationCreateByUser(requestParameters: TaxiReservationApiTaxiReservationCreateByUserRequest, options?: AxiosRequestConfig) {
        return TaxiReservationApiFp(this.configuration).taxiReservationCreateByUser(requestParameters.taxiReservationCreateByUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaxiReservationApiTaxiReservationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiReservationApi
     */
    public taxiReservationDelete(requestParameters: TaxiReservationApiTaxiReservationDeleteRequest, options?: AxiosRequestConfig) {
        return TaxiReservationApiFp(this.configuration).taxiReservationDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシ予約一覧を取得する
     * @param {TaxiReservationApiTaxiReservationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiReservationApi
     */
    public taxiReservationList(requestParameters: TaxiReservationApiTaxiReservationListRequest = {}, options?: AxiosRequestConfig) {
        return TaxiReservationApiFp(this.configuration).taxiReservationList(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.withTaxiCar, requestParameters.withTaxiCompany, requestParameters.withUser, requestParameters.withStartTaxiLandmark, requestParameters.withEndTaxiLandmark, requestParameters.rideAtFrom, requestParameters.rideAtTo, requestParameters.dropAtFrom, requestParameters.dropAtTo, requestParameters.taxiCompanyId, requestParameters.status, requestParameters.userInfoCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaxiReservationApiTaxiReservationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiReservationApi
     */
    public taxiReservationUpdate(requestParameters: TaxiReservationApiTaxiReservationUpdateRequest, options?: AxiosRequestConfig) {
        return TaxiReservationApiFp(this.configuration).taxiReservationUpdate(requestParameters.id, requestParameters.taxiReservationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシ予約を更新する
     * @param {TaxiReservationApiTaxiReservationUpdateByTaxiCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiReservationApi
     */
    public taxiReservationUpdateByTaxiCompany(requestParameters: TaxiReservationApiTaxiReservationUpdateByTaxiCompanyRequest, options?: AxiosRequestConfig) {
        return TaxiReservationApiFp(this.configuration).taxiReservationUpdateByTaxiCompany(requestParameters.id, requestParameters.taxiReservationUpdateByTaxiCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タクシ予約を更新する
     * @param {TaxiReservationApiTaxiReservationUpdateByUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiReservationApi
     */
    public taxiReservationUpdateByUser(requestParameters: TaxiReservationApiTaxiReservationUpdateByUserRequest, options?: AxiosRequestConfig) {
        return TaxiReservationApiFp(this.configuration).taxiReservationUpdateByUser(requestParameters.id, requestParameters.taxiReservationUpdateByUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaxiWeeklyScheduleApi - axios parameter creator
 * @export
 */
export const TaxiWeeklyScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [taxiCompanyId] 
         * @param {number} [dayOfWeek] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiWeeklyScheduleList: async (taxiCompanyId?: number, dayOfWeek?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/taxiWeeklySchedules/dayOfWeeks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (taxiCompanyId !== undefined) {
                localVarQueryParameter['taxiCompanyId'] = taxiCompanyId;
            }

            if (dayOfWeek !== undefined) {
                localVarQueryParameter['dayOfWeek'] = dayOfWeek;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TaxiWeeklyScheduleSaveRequest} taxiWeeklyScheduleSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiWeeklyScheduleSave: async (taxiWeeklyScheduleSaveRequest: TaxiWeeklyScheduleSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxiWeeklyScheduleSaveRequest' is not null or undefined
            assertParamExists('taxiWeeklyScheduleSave', 'taxiWeeklyScheduleSaveRequest', taxiWeeklyScheduleSaveRequest)
            const localVarPath = `/taxiWeeklySchedules/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxiWeeklyScheduleSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxiWeeklyScheduleApi - functional programming interface
 * @export
 */
export const TaxiWeeklyScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxiWeeklyScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [taxiCompanyId] 
         * @param {number} [dayOfWeek] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiWeeklyScheduleList(taxiCompanyId?: number, dayOfWeek?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxiWeeklyScheduleDayOfWeeksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiWeeklyScheduleList(taxiCompanyId, dayOfWeek, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TaxiWeeklyScheduleSaveRequest} taxiWeeklyScheduleSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxiWeeklyScheduleSave(taxiWeeklyScheduleSaveRequest: TaxiWeeklyScheduleSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxiWeeklyScheduleSave(taxiWeeklyScheduleSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxiWeeklyScheduleApi - factory interface
 * @export
 */
export const TaxiWeeklyScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxiWeeklyScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {TaxiWeeklyScheduleApiTaxiWeeklyScheduleListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiWeeklyScheduleList(requestParameters: TaxiWeeklyScheduleApiTaxiWeeklyScheduleListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TaxiWeeklyScheduleDayOfWeeksResponse> {
            return localVarFp.taxiWeeklyScheduleList(requestParameters.taxiCompanyId, requestParameters.dayOfWeek, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TaxiWeeklyScheduleApiTaxiWeeklyScheduleSaveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxiWeeklyScheduleSave(requestParameters: TaxiWeeklyScheduleApiTaxiWeeklyScheduleSaveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.taxiWeeklyScheduleSave(requestParameters.taxiWeeklyScheduleSaveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taxiWeeklyScheduleList operation in TaxiWeeklyScheduleApi.
 * @export
 * @interface TaxiWeeklyScheduleApiTaxiWeeklyScheduleListRequest
 */
export interface TaxiWeeklyScheduleApiTaxiWeeklyScheduleListRequest {
    /**
     * 
     * @type {number}
     * @memberof TaxiWeeklyScheduleApiTaxiWeeklyScheduleList
     */
    readonly taxiCompanyId?: number

    /**
     * 
     * @type {number}
     * @memberof TaxiWeeklyScheduleApiTaxiWeeklyScheduleList
     */
    readonly dayOfWeek?: number

    /**
     * 
     * @type {string}
     * @memberof TaxiWeeklyScheduleApiTaxiWeeklyScheduleList
     */
    readonly sort?: string
}

/**
 * Request parameters for taxiWeeklyScheduleSave operation in TaxiWeeklyScheduleApi.
 * @export
 * @interface TaxiWeeklyScheduleApiTaxiWeeklyScheduleSaveRequest
 */
export interface TaxiWeeklyScheduleApiTaxiWeeklyScheduleSaveRequest {
    /**
     * 
     * @type {TaxiWeeklyScheduleSaveRequest}
     * @memberof TaxiWeeklyScheduleApiTaxiWeeklyScheduleSave
     */
    readonly taxiWeeklyScheduleSaveRequest: TaxiWeeklyScheduleSaveRequest
}

/**
 * TaxiWeeklyScheduleApi - object-oriented interface
 * @export
 * @class TaxiWeeklyScheduleApi
 * @extends {BaseAPI}
 */
export class TaxiWeeklyScheduleApi extends BaseAPI {
    /**
     * 
     * @param {TaxiWeeklyScheduleApiTaxiWeeklyScheduleListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiWeeklyScheduleApi
     */
    public taxiWeeklyScheduleList(requestParameters: TaxiWeeklyScheduleApiTaxiWeeklyScheduleListRequest = {}, options?: AxiosRequestConfig) {
        return TaxiWeeklyScheduleApiFp(this.configuration).taxiWeeklyScheduleList(requestParameters.taxiCompanyId, requestParameters.dayOfWeek, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaxiWeeklyScheduleApiTaxiWeeklyScheduleSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxiWeeklyScheduleApi
     */
    public taxiWeeklyScheduleSave(requestParameters: TaxiWeeklyScheduleApiTaxiWeeklyScheduleSaveRequest, options?: AxiosRequestConfig) {
        return TaxiWeeklyScheduleApiFp(this.configuration).taxiWeeklyScheduleSave(requestParameters.taxiWeeklyScheduleSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TenantCreateRequest} [tenantCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantCreate: async (tenantCreateRequest?: TenantCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantDelete', 'id', id)
            const localVarPath = `/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tenantList
         * @param {string} [search] 
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withPlaces] 
         * @param {boolean} [withPlacesAreas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantList: async (search?: string, id?: number, page?: number, pageSize?: number, withPlaces?: boolean, withPlacesAreas?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (withPlaces !== undefined) {
                localVarQueryParameter['withPlaces'] = withPlaces;
            }

            if (withPlacesAreas !== undefined) {
                localVarQueryParameter['withPlacesAreas'] = withPlacesAreas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TenantUpdateRequest} [tenantUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantUpdate: async (id: number, tenantUpdateRequest?: TenantUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tenantUpdate', 'id', id)
            const localVarPath = `/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TenantCreateRequest} [tenantCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantCreate(tenantCreateRequest?: TenantCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantCreate(tenantCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * tenantList
         * @param {string} [search] 
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withPlaces] 
         * @param {boolean} [withPlacesAreas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantList(search?: string, id?: number, page?: number, pageSize?: number, withPlaces?: boolean, withPlacesAreas?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantList(search, id, page, pageSize, withPlaces, withPlacesAreas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TenantUpdateRequest} [tenantUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantUpdate(id: number, tenantUpdateRequest?: TenantUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenantUpdate(id, tenantUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantApiTenantCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantCreate(requestParameters: TenantApiTenantCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TenantCreateResponse> {
            return localVarFp.tenantCreate(requestParameters.tenantCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantApiTenantDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantDelete(requestParameters: TenantApiTenantDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.tenantDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * tenantList
         * @param {TenantApiTenantListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantList(requestParameters: TenantApiTenantListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TenantListResponse> {
            return localVarFp.tenantList(requestParameters.search, requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.withPlaces, requestParameters.withPlacesAreas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantApiTenantUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantUpdate(requestParameters: TenantApiTenantUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.tenantUpdate(requestParameters.id, requestParameters.tenantUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for tenantCreate operation in TenantApi.
 * @export
 * @interface TenantApiTenantCreateRequest
 */
export interface TenantApiTenantCreateRequest {
    /**
     * 
     * @type {TenantCreateRequest}
     * @memberof TenantApiTenantCreate
     */
    readonly tenantCreateRequest?: TenantCreateRequest
}

/**
 * Request parameters for tenantDelete operation in TenantApi.
 * @export
 * @interface TenantApiTenantDeleteRequest
 */
export interface TenantApiTenantDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof TenantApiTenantDelete
     */
    readonly id: number
}

/**
 * Request parameters for tenantList operation in TenantApi.
 * @export
 * @interface TenantApiTenantListRequest
 */
export interface TenantApiTenantListRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiTenantList
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof TenantApiTenantList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof TenantApiTenantList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantApiTenantList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof TenantApiTenantList
     */
    readonly withPlaces?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TenantApiTenantList
     */
    readonly withPlacesAreas?: boolean
}

/**
 * Request parameters for tenantUpdate operation in TenantApi.
 * @export
 * @interface TenantApiTenantUpdateRequest
 */
export interface TenantApiTenantUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof TenantApiTenantUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TenantUpdateRequest}
     * @memberof TenantApiTenantUpdate
     */
    readonly tenantUpdateRequest?: TenantUpdateRequest
}

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {TenantApiTenantCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public tenantCreate(requestParameters: TenantApiTenantCreateRequest = {}, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).tenantCreate(requestParameters.tenantCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantApiTenantDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public tenantDelete(requestParameters: TenantApiTenantDeleteRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).tenantDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tenantList
     * @param {TenantApiTenantListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public tenantList(requestParameters: TenantApiTenantListRequest = {}, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).tenantList(requestParameters.search, requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.withPlaces, requestParameters.withPlacesAreas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantApiTenantUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public tenantUpdate(requestParameters: TenantApiTenantUpdateRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).tenantUpdate(requestParameters.id, requestParameters.tenantUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TopicApi - axios parameter creator
 * @export
 */
export const TopicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TopicSaveRequest} topicSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicSave: async (topicSaveRequest: TopicSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicSaveRequest' is not null or undefined
            assertParamExists('topicSave', 'topicSaveRequest', topicSaveRequest)
            const localVarPath = `/topic/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topicSaveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TopicSaveByOfficeRequest} topicSaveByOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicSaveByOffice: async (topicSaveByOfficeRequest: TopicSaveByOfficeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicSaveByOfficeRequest' is not null or undefined
            assertParamExists('topicSaveByOffice', 'topicSaveByOfficeRequest', topicSaveByOfficeRequest)
            const localVarPath = `/topic/saveByOffice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topicSaveByOfficeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TopicVerifyRequest} topicVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicVerify: async (topicVerifyRequest: TopicVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicVerifyRequest' is not null or undefined
            assertParamExists('topicVerify', 'topicVerifyRequest', topicVerifyRequest)
            const localVarPath = `/topic/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topicVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopicApi - functional programming interface
 * @export
 */
export const TopicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TopicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TopicSaveRequest} topicSaveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topicSave(topicSaveRequest: TopicSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.topicSave(topicSaveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TopicSaveByOfficeRequest} topicSaveByOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topicSaveByOffice(topicSaveByOfficeRequest: TopicSaveByOfficeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.topicSaveByOffice(topicSaveByOfficeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TopicVerifyRequest} topicVerifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topicVerify(topicVerifyRequest: TopicVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.topicVerify(topicVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TopicApi - factory interface
 * @export
 */
export const TopicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TopicApiFp(configuration)
    return {
        /**
         * 
         * @param {TopicApiTopicSaveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicSave(requestParameters: TopicApiTopicSaveRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.topicSave(requestParameters.topicSaveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TopicApiTopicSaveByOfficeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicSaveByOffice(requestParameters: TopicApiTopicSaveByOfficeRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.topicSaveByOffice(requestParameters.topicSaveByOfficeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TopicApiTopicVerifyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicVerify(requestParameters: TopicApiTopicVerifyRequest, options?: AxiosRequestConfig): AxiosPromise<TopicVerifyResponse> {
            return localVarFp.topicVerify(requestParameters.topicVerifyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for topicSave operation in TopicApi.
 * @export
 * @interface TopicApiTopicSaveRequest
 */
export interface TopicApiTopicSaveRequest {
    /**
     * 
     * @type {TopicSaveRequest}
     * @memberof TopicApiTopicSave
     */
    readonly topicSaveRequest: TopicSaveRequest
}

/**
 * Request parameters for topicSaveByOffice operation in TopicApi.
 * @export
 * @interface TopicApiTopicSaveByOfficeRequest
 */
export interface TopicApiTopicSaveByOfficeRequest {
    /**
     * 
     * @type {TopicSaveByOfficeRequest}
     * @memberof TopicApiTopicSaveByOffice
     */
    readonly topicSaveByOfficeRequest: TopicSaveByOfficeRequest
}

/**
 * Request parameters for topicVerify operation in TopicApi.
 * @export
 * @interface TopicApiTopicVerifyRequest
 */
export interface TopicApiTopicVerifyRequest {
    /**
     * 
     * @type {TopicVerifyRequest}
     * @memberof TopicApiTopicVerify
     */
    readonly topicVerifyRequest: TopicVerifyRequest
}

/**
 * TopicApi - object-oriented interface
 * @export
 * @class TopicApi
 * @extends {BaseAPI}
 */
export class TopicApi extends BaseAPI {
    /**
     * 
     * @param {TopicApiTopicSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public topicSave(requestParameters: TopicApiTopicSaveRequest, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).topicSave(requestParameters.topicSaveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TopicApiTopicSaveByOfficeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public topicSaveByOffice(requestParameters: TopicApiTopicSaveByOfficeRequest, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).topicSaveByOffice(requestParameters.topicSaveByOfficeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TopicApiTopicVerifyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public topicVerify(requestParameters: TopicApiTopicVerifyRequest, options?: AxiosRequestConfig) {
        return TopicApiFp(this.configuration).topicVerify(requestParameters.topicVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrashCalendarApi - axios parameter creator
 * @export
 */
export const TrashCalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ゴミの日カレンダーを作成する
         * @param {TrashCalendarCreateRequest} trashCalendarCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trashCalendarCreate: async (trashCalendarCreateRequest: TrashCalendarCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trashCalendarCreateRequest' is not null or undefined
            assertParamExists('trashCalendarCreate', 'trashCalendarCreateRequest', trashCalendarCreateRequest)
            const localVarPath = `/trashCalendars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trashCalendarCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ゴミの日カレンダーを削除する
         * @param {number} id 削除するゴミの日カレンダーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trashCalendarDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('trashCalendarDelete', 'id', id)
            const localVarPath = `/trashCalendars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ゴミの日カレンダー一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {boolean} [withTrashCalendarDates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trashCalendarList: async (id?: number, page?: number, pageSize?: number, startDate?: string, endDate?: string, withTrashCalendarDates?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trashCalendars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }

            if (withTrashCalendarDates !== undefined) {
                localVarQueryParameter['withTrashCalendarDates'] = withTrashCalendarDates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ゴミの日カレンダーを更新する
         * @param {number} id 更新するゴミの日カレンダーのID
         * @param {TrashCalendarUpdateRequest} trashCalendarUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trashCalendarUpdate: async (id: number, trashCalendarUpdateRequest: TrashCalendarUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('trashCalendarUpdate', 'id', id)
            // verify required parameter 'trashCalendarUpdateRequest' is not null or undefined
            assertParamExists('trashCalendarUpdate', 'trashCalendarUpdateRequest', trashCalendarUpdateRequest)
            const localVarPath = `/trashCalendars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trashCalendarUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrashCalendarApi - functional programming interface
 * @export
 */
export const TrashCalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrashCalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ゴミの日カレンダーを作成する
         * @param {TrashCalendarCreateRequest} trashCalendarCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trashCalendarCreate(trashCalendarCreateRequest: TrashCalendarCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrashCalendarCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trashCalendarCreate(trashCalendarCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ゴミの日カレンダーを削除する
         * @param {number} id 削除するゴミの日カレンダーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trashCalendarDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trashCalendarDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ゴミの日カレンダー一覧を取得する
         * @param {number} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {boolean} [withTrashCalendarDates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trashCalendarList(id?: number, page?: number, pageSize?: number, startDate?: string, endDate?: string, withTrashCalendarDates?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrashCalendarListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trashCalendarList(id, page, pageSize, startDate, endDate, withTrashCalendarDates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ゴミの日カレンダーを更新する
         * @param {number} id 更新するゴミの日カレンダーのID
         * @param {TrashCalendarUpdateRequest} trashCalendarUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trashCalendarUpdate(id: number, trashCalendarUpdateRequest: TrashCalendarUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trashCalendarUpdate(id, trashCalendarUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrashCalendarApi - factory interface
 * @export
 */
export const TrashCalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrashCalendarApiFp(configuration)
    return {
        /**
         * 
         * @summary ゴミの日カレンダーを作成する
         * @param {TrashCalendarApiTrashCalendarCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trashCalendarCreate(requestParameters: TrashCalendarApiTrashCalendarCreateRequest, options?: AxiosRequestConfig): AxiosPromise<TrashCalendarCreateResponse> {
            return localVarFp.trashCalendarCreate(requestParameters.trashCalendarCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ゴミの日カレンダーを削除する
         * @param {TrashCalendarApiTrashCalendarDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trashCalendarDelete(requestParameters: TrashCalendarApiTrashCalendarDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.trashCalendarDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ゴミの日カレンダー一覧を取得する
         * @param {TrashCalendarApiTrashCalendarListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trashCalendarList(requestParameters: TrashCalendarApiTrashCalendarListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<TrashCalendarListResponse> {
            return localVarFp.trashCalendarList(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.startDate, requestParameters.endDate, requestParameters.withTrashCalendarDates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ゴミの日カレンダーを更新する
         * @param {TrashCalendarApiTrashCalendarUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trashCalendarUpdate(requestParameters: TrashCalendarApiTrashCalendarUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.trashCalendarUpdate(requestParameters.id, requestParameters.trashCalendarUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for trashCalendarCreate operation in TrashCalendarApi.
 * @export
 * @interface TrashCalendarApiTrashCalendarCreateRequest
 */
export interface TrashCalendarApiTrashCalendarCreateRequest {
    /**
     * 
     * @type {TrashCalendarCreateRequest}
     * @memberof TrashCalendarApiTrashCalendarCreate
     */
    readonly trashCalendarCreateRequest: TrashCalendarCreateRequest
}

/**
 * Request parameters for trashCalendarDelete operation in TrashCalendarApi.
 * @export
 * @interface TrashCalendarApiTrashCalendarDeleteRequest
 */
export interface TrashCalendarApiTrashCalendarDeleteRequest {
    /**
     * 削除するゴミの日カレンダーのID
     * @type {number}
     * @memberof TrashCalendarApiTrashCalendarDelete
     */
    readonly id: number
}

/**
 * Request parameters for trashCalendarList operation in TrashCalendarApi.
 * @export
 * @interface TrashCalendarApiTrashCalendarListRequest
 */
export interface TrashCalendarApiTrashCalendarListRequest {
    /**
     * 
     * @type {number}
     * @memberof TrashCalendarApiTrashCalendarList
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof TrashCalendarApiTrashCalendarList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TrashCalendarApiTrashCalendarList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TrashCalendarApiTrashCalendarList
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof TrashCalendarApiTrashCalendarList
     */
    readonly endDate?: string

    /**
     * 
     * @type {boolean}
     * @memberof TrashCalendarApiTrashCalendarList
     */
    readonly withTrashCalendarDates?: boolean
}

/**
 * Request parameters for trashCalendarUpdate operation in TrashCalendarApi.
 * @export
 * @interface TrashCalendarApiTrashCalendarUpdateRequest
 */
export interface TrashCalendarApiTrashCalendarUpdateRequest {
    /**
     * 更新するゴミの日カレンダーのID
     * @type {number}
     * @memberof TrashCalendarApiTrashCalendarUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TrashCalendarUpdateRequest}
     * @memberof TrashCalendarApiTrashCalendarUpdate
     */
    readonly trashCalendarUpdateRequest: TrashCalendarUpdateRequest
}

/**
 * TrashCalendarApi - object-oriented interface
 * @export
 * @class TrashCalendarApi
 * @extends {BaseAPI}
 */
export class TrashCalendarApi extends BaseAPI {
    /**
     * 
     * @summary ゴミの日カレンダーを作成する
     * @param {TrashCalendarApiTrashCalendarCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashCalendarApi
     */
    public trashCalendarCreate(requestParameters: TrashCalendarApiTrashCalendarCreateRequest, options?: AxiosRequestConfig) {
        return TrashCalendarApiFp(this.configuration).trashCalendarCreate(requestParameters.trashCalendarCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ゴミの日カレンダーを削除する
     * @param {TrashCalendarApiTrashCalendarDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashCalendarApi
     */
    public trashCalendarDelete(requestParameters: TrashCalendarApiTrashCalendarDeleteRequest, options?: AxiosRequestConfig) {
        return TrashCalendarApiFp(this.configuration).trashCalendarDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ゴミの日カレンダー一覧を取得する
     * @param {TrashCalendarApiTrashCalendarListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashCalendarApi
     */
    public trashCalendarList(requestParameters: TrashCalendarApiTrashCalendarListRequest = {}, options?: AxiosRequestConfig) {
        return TrashCalendarApiFp(this.configuration).trashCalendarList(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.startDate, requestParameters.endDate, requestParameters.withTrashCalendarDates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ゴミの日カレンダーを更新する
     * @param {TrashCalendarApiTrashCalendarUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashCalendarApi
     */
    public trashCalendarUpdate(requestParameters: TrashCalendarApiTrashCalendarUpdateRequest, options?: AxiosRequestConfig) {
        return TrashCalendarApiFp(this.configuration).trashCalendarUpdate(requestParameters.id, requestParameters.trashCalendarUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lambdaからアクセス用の認証ログインの1段階目の認証。 成功すると認証コードが返され、これを2段階目の認証で使用する。
         * @param {UserAuthorizeRequest} [userAuthorizeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthorize: async (userAuthorizeRequest?: UserAuthorizeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAuthorizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreateRequest} [userCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate: async (userCreateRequest?: UserCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 削除するユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDelete', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * userList
         * @param {number} [id] 
         * @param {string} [q] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withPlace] 
         * @param {boolean} [withUserInfo] 
         * @param {boolean} [withTaxiCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userList: async (id?: number, q?: string, page?: number, pageSize?: number, withPlace?: boolean, withUserInfo?: boolean, withTaxiCompany?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (withPlace !== undefined) {
                localVarQueryParameter['withPlace'] = withPlace;
            }

            if (withUserInfo !== undefined) {
                localVarQueryParameter['withUserInfo'] = withUserInfo;
            }

            if (withTaxiCompany !== undefined) {
                localVarQueryParameter['withTaxiCompany'] = withTaxiCompany;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 二段階認証ログインの1段階目の認証。 成功すると認証コードがEmailかSMS経由で返され、これを2段階目の認証で使用する。
         * @param {string} [emailOrPhone] ログインするユーザーのEmailかPhone
         * @param {UserLoginRequest} [userLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogin: async (emailOrPhone?: string, userLoginRequest?: UserLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (emailOrPhone !== undefined) {
                localVarQueryParameter['emailOrPhone'] = emailOrPhone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 二段階認証ログインの2段階目の認証。 成功するとjwt文字列が返される。
         * @param {string} [emailOrPhone] ログインするユーザーのEmailかPhone
         * @param {UserLoginCodeRequest} [userLoginCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLoginCode: async (emailOrPhone?: string, userLoginCodeRequest?: UserLoginCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/loginCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (emailOrPhone !== undefined) {
                localVarQueryParameter['emailOrPhone'] = emailOrPhone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLoginCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワード再設定のための確認コードをEmailかSMSで送信する。
         * @param {UserResetPasswordRequest} [userResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword: async (userResetPasswordRequest?: UserResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワード再設定のための確認コードを使用して、パスワードを新たに設定する。
         * @param {UserResetPasswordVerifyRequest} [userResetPasswordVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPasswordVerify: async (userResetPasswordVerifyRequest?: UserResetPasswordVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/resetPasswordVerify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userResetPasswordVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSignupRequest} [userSignupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSignup: async (userSignupRequest?: UserSignupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSignupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserTokenRequest} [userTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userToken: async (userTokenRequest?: UserTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {number} id 更新するユーザーのID
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (id: number, userUpdateRequest: UserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userUpdate', 'id', id)
            // verify required parameter 'userUpdateRequest' is not null or undefined
            assertParamExists('userUpdate', 'userUpdateRequest', userUpdateRequest)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserUpdateEmailRequest} [userUpdateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateEmail: async (userUpdateEmailRequest?: UserUpdateEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/updateEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserUpdateEmailVerifyRequest} [userUpdateEmailVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateEmailVerify: async (userUpdateEmailVerifyRequest?: UserUpdateEmailVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/updateEmailVerify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateEmailVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserUpdatePhoneRequest} [userUpdatePhoneRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePhone: async (userUpdatePhoneRequest?: UserUpdatePhoneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/updatePhone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatePhoneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserUpdatePhoneVerifyRequest} [userUpdatePhoneVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePhoneVerify: async (userUpdatePhoneVerifyRequest?: UserUpdatePhoneVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/updatePhoneVerify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatePhoneVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserVerifyCodeForTaxiRequest} [userVerifyCodeForTaxiRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerifyCodeForTaxi: async (userVerifyCodeForTaxiRequest?: UserVerifyCodeForTaxiRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/verifyCodeForTaxi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userVerifyCodeForTaxiRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Lambdaからアクセス用の認証ログインの1段階目の認証。 成功すると認証コードが返され、これを2段階目の認証で使用する。
         * @param {UserAuthorizeRequest} [userAuthorizeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAuthorize(userAuthorizeRequest?: UserAuthorizeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthorizeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthorize(userAuthorizeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserCreateRequest} [userCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreate(userCreateRequest?: UserCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCreate(userCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 削除するユーザーのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * userList
         * @param {number} [id] 
         * @param {string} [q] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [withPlace] 
         * @param {boolean} [withUserInfo] 
         * @param {boolean} [withTaxiCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userList(id?: number, q?: string, page?: number, pageSize?: number, withPlace?: boolean, withUserInfo?: boolean, withTaxiCompany?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userList(id, q, page, pageSize, withPlace, withUserInfo, withTaxiCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 二段階認証ログインの1段階目の認証。 成功すると認証コードがEmailかSMS経由で返され、これを2段階目の認証で使用する。
         * @param {string} [emailOrPhone] ログインするユーザーのEmailかPhone
         * @param {UserLoginRequest} [userLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLogin(emailOrPhone?: string, userLoginRequest?: UserLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLogin(emailOrPhone, userLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 二段階認証ログインの2段階目の認証。 成功するとjwt文字列が返される。
         * @param {string} [emailOrPhone] ログインするユーザーのEmailかPhone
         * @param {UserLoginCodeRequest} [userLoginCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLoginCode(emailOrPhone?: string, userLoginCodeRequest?: UserLoginCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLoginCode(emailOrPhone, userLoginCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワード再設定のための確認コードをEmailかSMSで送信する。
         * @param {UserResetPasswordRequest} [userResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResetPassword(userResetPasswordRequest?: UserResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userResetPassword(userResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワード再設定のための確認コードを使用して、パスワードを新たに設定する。
         * @param {UserResetPasswordVerifyRequest} [userResetPasswordVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResetPasswordVerify(userResetPasswordVerifyRequest?: UserResetPasswordVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userResetPasswordVerify(userResetPasswordVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSignupRequest} [userSignupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSignup(userSignupRequest?: UserSignupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSignupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSignup(userSignupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserTokenRequest} [userTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userToken(userTokenRequest?: UserTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userToken(userTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {number} id 更新するユーザーのID
         * @param {UserUpdateRequest} userUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(id: number, userUpdateRequest: UserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(id, userUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserUpdateEmailRequest} [userUpdateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateEmail(userUpdateEmailRequest?: UserUpdateEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateEmail(userUpdateEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserUpdateEmailVerifyRequest} [userUpdateEmailVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateEmailVerify(userUpdateEmailVerifyRequest?: UserUpdateEmailVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateEmailVerify(userUpdateEmailVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserUpdatePhoneRequest} [userUpdatePhoneRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdatePhone(userUpdatePhoneRequest?: UserUpdatePhoneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdatePhone(userUpdatePhoneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserUpdatePhoneVerifyRequest} [userUpdatePhoneVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdatePhoneVerify(userUpdatePhoneVerifyRequest?: UserUpdatePhoneVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdatePhoneVerify(userUpdatePhoneVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserVerifyCodeForTaxiRequest} [userVerifyCodeForTaxiRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userVerifyCodeForTaxi(userVerifyCodeForTaxiRequest?: UserVerifyCodeForTaxiRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserVerifyCodeForTaxiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userVerifyCodeForTaxi(userVerifyCodeForTaxiRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Lambdaからアクセス用の認証ログインの1段階目の認証。 成功すると認証コードが返され、これを2段階目の認証で使用する。
         * @param {UserApiUserAuthorizeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAuthorize(requestParameters: UserApiUserAuthorizeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserAuthorizeResponse> {
            return localVarFp.userAuthorize(requestParameters.userAuthorizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate(requestParameters: UserApiUserCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserCreateResponse> {
            return localVarFp.userCreate(requestParameters.userCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete(requestParameters: UserApiUserDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * userList
         * @param {UserApiUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userList(requestParameters: UserApiUserListRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserListResponse> {
            return localVarFp.userList(requestParameters.id, requestParameters.q, requestParameters.page, requestParameters.pageSize, requestParameters.withPlace, requestParameters.withUserInfo, requestParameters.withTaxiCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 二段階認証ログインの1段階目の認証。 成功すると認証コードがEmailかSMS経由で返され、これを2段階目の認証で使用する。
         * @param {UserApiUserLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogin(requestParameters: UserApiUserLoginRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userLogin(requestParameters.emailOrPhone, requestParameters.userLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 二段階認証ログインの2段階目の認証。 成功するとjwt文字列が返される。
         * @param {UserApiUserLoginCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLoginCode(requestParameters: UserApiUserLoginCodeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserLoginCodeResponse> {
            return localVarFp.userLoginCode(requestParameters.emailOrPhone, requestParameters.userLoginCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワード再設定のための確認コードをEmailかSMSで送信する。
         * @param {UserApiUserResetPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword(requestParameters: UserApiUserResetPasswordRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userResetPassword(requestParameters.userResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワード再設定のための確認コードを使用して、パスワードを新たに設定する。
         * @param {UserApiUserResetPasswordVerifyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPasswordVerify(requestParameters: UserApiUserResetPasswordVerifyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userResetPasswordVerify(requestParameters.userResetPasswordVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserSignupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSignup(requestParameters: UserApiUserSignupRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserSignupResponse> {
            return localVarFp.userSignup(requestParameters.userSignupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userToken(requestParameters: UserApiUserTokenRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserTokenResponse> {
            return localVarFp.userToken(requestParameters.userTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーを更新する
         * @param {UserApiUserUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(requestParameters: UserApiUserUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userUpdate(requestParameters.id, requestParameters.userUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserUpdateEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateEmail(requestParameters: UserApiUserUpdateEmailRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userUpdateEmail(requestParameters.userUpdateEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserUpdateEmailVerifyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateEmailVerify(requestParameters: UserApiUserUpdateEmailVerifyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userUpdateEmailVerify(requestParameters.userUpdateEmailVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserUpdatePhoneRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePhone(requestParameters: UserApiUserUpdatePhoneRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userUpdatePhone(requestParameters.userUpdatePhoneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserUpdatePhoneVerifyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePhoneVerify(requestParameters: UserApiUserUpdatePhoneVerifyRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userUpdatePhoneVerify(requestParameters.userUpdatePhoneVerifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiUserVerifyCodeForTaxiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userVerifyCodeForTaxi(requestParameters: UserApiUserVerifyCodeForTaxiRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserVerifyCodeForTaxiResponse> {
            return localVarFp.userVerifyCodeForTaxi(requestParameters.userVerifyCodeForTaxiRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userAuthorize operation in UserApi.
 * @export
 * @interface UserApiUserAuthorizeRequest
 */
export interface UserApiUserAuthorizeRequest {
    /**
     * 
     * @type {UserAuthorizeRequest}
     * @memberof UserApiUserAuthorize
     */
    readonly userAuthorizeRequest?: UserAuthorizeRequest
}

/**
 * Request parameters for userCreate operation in UserApi.
 * @export
 * @interface UserApiUserCreateRequest
 */
export interface UserApiUserCreateRequest {
    /**
     * 
     * @type {UserCreateRequest}
     * @memberof UserApiUserCreate
     */
    readonly userCreateRequest?: UserCreateRequest
}

/**
 * Request parameters for userDelete operation in UserApi.
 * @export
 * @interface UserApiUserDeleteRequest
 */
export interface UserApiUserDeleteRequest {
    /**
     * 削除するユーザーのID
     * @type {number}
     * @memberof UserApiUserDelete
     */
    readonly id: number
}

/**
 * Request parameters for userList operation in UserApi.
 * @export
 * @interface UserApiUserListRequest
 */
export interface UserApiUserListRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserList
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof UserApiUserList
     */
    readonly q?: string

    /**
     * 
     * @type {number}
     * @memberof UserApiUserList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UserApiUserList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {boolean}
     * @memberof UserApiUserList
     */
    readonly withPlace?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiUserList
     */
    readonly withUserInfo?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiUserList
     */
    readonly withTaxiCompany?: boolean
}

/**
 * Request parameters for userLogin operation in UserApi.
 * @export
 * @interface UserApiUserLoginRequest
 */
export interface UserApiUserLoginRequest {
    /**
     * ログインするユーザーのEmailかPhone
     * @type {string}
     * @memberof UserApiUserLogin
     */
    readonly emailOrPhone?: string

    /**
     * 
     * @type {UserLoginRequest}
     * @memberof UserApiUserLogin
     */
    readonly userLoginRequest?: UserLoginRequest
}

/**
 * Request parameters for userLoginCode operation in UserApi.
 * @export
 * @interface UserApiUserLoginCodeRequest
 */
export interface UserApiUserLoginCodeRequest {
    /**
     * ログインするユーザーのEmailかPhone
     * @type {string}
     * @memberof UserApiUserLoginCode
     */
    readonly emailOrPhone?: string

    /**
     * 
     * @type {UserLoginCodeRequest}
     * @memberof UserApiUserLoginCode
     */
    readonly userLoginCodeRequest?: UserLoginCodeRequest
}

/**
 * Request parameters for userResetPassword operation in UserApi.
 * @export
 * @interface UserApiUserResetPasswordRequest
 */
export interface UserApiUserResetPasswordRequest {
    /**
     * 
     * @type {UserResetPasswordRequest}
     * @memberof UserApiUserResetPassword
     */
    readonly userResetPasswordRequest?: UserResetPasswordRequest
}

/**
 * Request parameters for userResetPasswordVerify operation in UserApi.
 * @export
 * @interface UserApiUserResetPasswordVerifyRequest
 */
export interface UserApiUserResetPasswordVerifyRequest {
    /**
     * 
     * @type {UserResetPasswordVerifyRequest}
     * @memberof UserApiUserResetPasswordVerify
     */
    readonly userResetPasswordVerifyRequest?: UserResetPasswordVerifyRequest
}

/**
 * Request parameters for userSignup operation in UserApi.
 * @export
 * @interface UserApiUserSignupRequest
 */
export interface UserApiUserSignupRequest {
    /**
     * 
     * @type {UserSignupRequest}
     * @memberof UserApiUserSignup
     */
    readonly userSignupRequest?: UserSignupRequest
}

/**
 * Request parameters for userToken operation in UserApi.
 * @export
 * @interface UserApiUserTokenRequest
 */
export interface UserApiUserTokenRequest {
    /**
     * 
     * @type {UserTokenRequest}
     * @memberof UserApiUserToken
     */
    readonly userTokenRequest?: UserTokenRequest
}

/**
 * Request parameters for userUpdate operation in UserApi.
 * @export
 * @interface UserApiUserUpdateRequest
 */
export interface UserApiUserUpdateRequest {
    /**
     * 更新するユーザーのID
     * @type {number}
     * @memberof UserApiUserUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UserUpdateRequest}
     * @memberof UserApiUserUpdate
     */
    readonly userUpdateRequest: UserUpdateRequest
}

/**
 * Request parameters for userUpdateEmail operation in UserApi.
 * @export
 * @interface UserApiUserUpdateEmailRequest
 */
export interface UserApiUserUpdateEmailRequest {
    /**
     * 
     * @type {UserUpdateEmailRequest}
     * @memberof UserApiUserUpdateEmail
     */
    readonly userUpdateEmailRequest?: UserUpdateEmailRequest
}

/**
 * Request parameters for userUpdateEmailVerify operation in UserApi.
 * @export
 * @interface UserApiUserUpdateEmailVerifyRequest
 */
export interface UserApiUserUpdateEmailVerifyRequest {
    /**
     * 
     * @type {UserUpdateEmailVerifyRequest}
     * @memberof UserApiUserUpdateEmailVerify
     */
    readonly userUpdateEmailVerifyRequest?: UserUpdateEmailVerifyRequest
}

/**
 * Request parameters for userUpdatePhone operation in UserApi.
 * @export
 * @interface UserApiUserUpdatePhoneRequest
 */
export interface UserApiUserUpdatePhoneRequest {
    /**
     * 
     * @type {UserUpdatePhoneRequest}
     * @memberof UserApiUserUpdatePhone
     */
    readonly userUpdatePhoneRequest?: UserUpdatePhoneRequest
}

/**
 * Request parameters for userUpdatePhoneVerify operation in UserApi.
 * @export
 * @interface UserApiUserUpdatePhoneVerifyRequest
 */
export interface UserApiUserUpdatePhoneVerifyRequest {
    /**
     * 
     * @type {UserUpdatePhoneVerifyRequest}
     * @memberof UserApiUserUpdatePhoneVerify
     */
    readonly userUpdatePhoneVerifyRequest?: UserUpdatePhoneVerifyRequest
}

/**
 * Request parameters for userVerifyCodeForTaxi operation in UserApi.
 * @export
 * @interface UserApiUserVerifyCodeForTaxiRequest
 */
export interface UserApiUserVerifyCodeForTaxiRequest {
    /**
     * 
     * @type {UserVerifyCodeForTaxiRequest}
     * @memberof UserApiUserVerifyCodeForTaxi
     */
    readonly userVerifyCodeForTaxiRequest?: UserVerifyCodeForTaxiRequest
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Lambdaからアクセス用の認証ログインの1段階目の認証。 成功すると認証コードが返され、これを2段階目の認証で使用する。
     * @param {UserApiUserAuthorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAuthorize(requestParameters: UserApiUserAuthorizeRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAuthorize(requestParameters.userAuthorizeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCreate(requestParameters: UserApiUserCreateRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userCreate(requestParameters.userCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDelete(requestParameters: UserApiUserDeleteRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * userList
     * @param {UserApiUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userList(requestParameters: UserApiUserListRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userList(requestParameters.id, requestParameters.q, requestParameters.page, requestParameters.pageSize, requestParameters.withPlace, requestParameters.withUserInfo, requestParameters.withTaxiCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 二段階認証ログインの1段階目の認証。 成功すると認証コードがEmailかSMS経由で返され、これを2段階目の認証で使用する。
     * @param {UserApiUserLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userLogin(requestParameters: UserApiUserLoginRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userLogin(requestParameters.emailOrPhone, requestParameters.userLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 二段階認証ログインの2段階目の認証。 成功するとjwt文字列が返される。
     * @param {UserApiUserLoginCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userLoginCode(requestParameters: UserApiUserLoginCodeRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userLoginCode(requestParameters.emailOrPhone, requestParameters.userLoginCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワード再設定のための確認コードをEmailかSMSで送信する。
     * @param {UserApiUserResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userResetPassword(requestParameters: UserApiUserResetPasswordRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userResetPassword(requestParameters.userResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワード再設定のための確認コードを使用して、パスワードを新たに設定する。
     * @param {UserApiUserResetPasswordVerifyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userResetPasswordVerify(requestParameters: UserApiUserResetPasswordVerifyRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userResetPasswordVerify(requestParameters.userResetPasswordVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSignup(requestParameters: UserApiUserSignupRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userSignup(requestParameters.userSignupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userToken(requestParameters: UserApiUserTokenRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userToken(requestParameters.userTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーを更新する
     * @param {UserApiUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(requestParameters: UserApiUserUpdateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdate(requestParameters.id, requestParameters.userUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserUpdateEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateEmail(requestParameters: UserApiUserUpdateEmailRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdateEmail(requestParameters.userUpdateEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserUpdateEmailVerifyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateEmailVerify(requestParameters: UserApiUserUpdateEmailVerifyRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdateEmailVerify(requestParameters.userUpdateEmailVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserUpdatePhoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdatePhone(requestParameters: UserApiUserUpdatePhoneRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdatePhone(requestParameters.userUpdatePhoneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserUpdatePhoneVerifyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdatePhoneVerify(requestParameters: UserApiUserUpdatePhoneVerifyRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdatePhoneVerify(requestParameters.userUpdatePhoneVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserVerifyCodeForTaxiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userVerifyCodeForTaxi(requestParameters: UserApiUserVerifyCodeForTaxiRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userVerifyCodeForTaxi(requestParameters.userVerifyCodeForTaxiRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WeatherApi - axios parameter creator
 * @export
 */
export const WeatherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 1kmメッシュ天気API（24時間天気表示, 現在の天気表示に利用）
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherPointwf: async (lat?: number, lon?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/weather/pointwf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lon !== undefined) {
                localVarQueryParameter['lon'] = lon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 10 日先天気予報 API （週間天気表示に利用）
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherWth10: async (lat?: number, lon?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/weather/wth10`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication tenantId required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lon !== undefined) {
                localVarQueryParameter['lon'] = lon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeatherApi - functional programming interface
 * @export
 */
export const WeatherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeatherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 1kmメッシュ天気API（24時間天気表示, 現在の天気表示に利用）
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weatherPointwf(lat?: number, lon?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeatherPointwfResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weatherPointwf(lat, lon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 10 日先天気予報 API （週間天気表示に利用）
         * @param {number} [lat] 
         * @param {number} [lon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weatherWth10(lat?: number, lon?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeatherWth10Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weatherWth10(lat, lon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeatherApi - factory interface
 * @export
 */
export const WeatherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeatherApiFp(configuration)
    return {
        /**
         * 
         * @summary 1kmメッシュ天気API（24時間天気表示, 現在の天気表示に利用）
         * @param {WeatherApiWeatherPointwfRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherPointwf(requestParameters: WeatherApiWeatherPointwfRequest = {}, options?: AxiosRequestConfig): AxiosPromise<WeatherPointwfResponse> {
            return localVarFp.weatherPointwf(requestParameters.lat, requestParameters.lon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 10 日先天気予報 API （週間天気表示に利用）
         * @param {WeatherApiWeatherWth10Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherWth10(requestParameters: WeatherApiWeatherWth10Request = {}, options?: AxiosRequestConfig): AxiosPromise<WeatherWth10Response> {
            return localVarFp.weatherWth10(requestParameters.lat, requestParameters.lon, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for weatherPointwf operation in WeatherApi.
 * @export
 * @interface WeatherApiWeatherPointwfRequest
 */
export interface WeatherApiWeatherPointwfRequest {
    /**
     * 
     * @type {number}
     * @memberof WeatherApiWeatherPointwf
     */
    readonly lat?: number

    /**
     * 
     * @type {number}
     * @memberof WeatherApiWeatherPointwf
     */
    readonly lon?: number
}

/**
 * Request parameters for weatherWth10 operation in WeatherApi.
 * @export
 * @interface WeatherApiWeatherWth10Request
 */
export interface WeatherApiWeatherWth10Request {
    /**
     * 
     * @type {number}
     * @memberof WeatherApiWeatherWth10
     */
    readonly lat?: number

    /**
     * 
     * @type {number}
     * @memberof WeatherApiWeatherWth10
     */
    readonly lon?: number
}

/**
 * WeatherApi - object-oriented interface
 * @export
 * @class WeatherApi
 * @extends {BaseAPI}
 */
export class WeatherApi extends BaseAPI {
    /**
     * 
     * @summary 1kmメッシュ天気API（24時間天気表示, 現在の天気表示に利用）
     * @param {WeatherApiWeatherPointwfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeatherApi
     */
    public weatherPointwf(requestParameters: WeatherApiWeatherPointwfRequest = {}, options?: AxiosRequestConfig) {
        return WeatherApiFp(this.configuration).weatherPointwf(requestParameters.lat, requestParameters.lon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 10 日先天気予報 API （週間天気表示に利用）
     * @param {WeatherApiWeatherWth10Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeatherApi
     */
    public weatherWth10(requestParameters: WeatherApiWeatherWth10Request = {}, options?: AxiosRequestConfig) {
        return WeatherApiFp(this.configuration).weatherWth10(requestParameters.lat, requestParameters.lon, options).then((request) => request(this.axios, this.basePath));
    }
}



