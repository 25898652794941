import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppErrorResult from "../../../components/common/AppErrorResult";
import { AppLoading } from "../../../components/common/AppLoading";
import TaxiAreaSelect, {
  TaxiAreaSelectButton,
} from "../../../components/taxi/TaxiAreaSelect";
import TaxiFreewordForm from "../../../components/taxi/TaxiFreewordForm";
import { TaxiPointHeading } from "../../../components/taxi/TaxiPointHeading";
import TaxiPointItem from "../../../components/taxi/TaxiPointItem";
import { TaxiSectionTitle } from "../../../components/taxi/TaxiSectionTitle";
import { useTaxiLandmarkListQuery } from "../../../hooks/coreApi/useTaxiLandmarkListQuery";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { TaxiLandmarkResponse } from "../../../openapi";
import useTaxiReservationCreateState from "../useTaxiReservationCreateState";

// 目的地選択
const TaxiReservationCreateSelectTo: React.FC = () => {
  const { f } = useFormatMessage();

  const { setFormValues, internalLandmarks, externalLandmarks } =
    useTaxiReservationCreateState();
  const navigate = useNavigate();

  // 地点一覧絞り込み用のエリアID
  const [selectedPlaceId, setSelectedPlaceId] = useState<number>(1);

  // 目的地取得
  const taxiLandmarkListQuery = useTaxiLandmarkListQuery({});

  if (taxiLandmarkListQuery.isLoading) {
    return <AppLoading />;
  }

  if (taxiLandmarkListQuery.isError) {
    return <AppErrorResult error={taxiLandmarkListQuery.error} />;
  }

  // エリアクリック
  const clickPoint = (point: TaxiLandmarkResponse) => {
    setFormValues({
      endTaxiLandmarkId: point.id,
      endLatLng: point?.latLng || undefined,
      endAddress: "",
    });
    navigate("/taxi/reservations/create");
  };

  // 「自宅」をクリック
  const clickPointMyHome = () => {
    setFormValues({
      endTaxiLandmarkId: undefined,
      endLatLng: undefined,
      endAddress: "自宅",
    });
    navigate("/taxi/reservations/create");
  };

  const handleFreewordSubmit = (value: string) => {
    setFormValues({
      endTaxiLandmarkId: null,
      endLatLng: "",
      endAddress: value,
    });
    navigate("/taxi/reservations/create");
  };

  return (
    <>
      <Box px={{ base: "16px", md: "0px" }}>
        <TaxiSectionTitle label={f("目的地を選択もしくは入力してください。")} />

        <TaxiAreaSelect>
          <TaxiAreaSelectButton
            w="50%"
            isActive={selectedPlaceId === 1}
            onClick={() => setSelectedPlaceId(1)}
          >
            {f("神山町内")}
          </TaxiAreaSelectButton>
          <TaxiAreaSelectButton
            w="50%"
            isActive={selectedPlaceId === 2}
            onClick={() => setSelectedPlaceId(2)}
          >
            {f("神山町外")}
          </TaxiAreaSelectButton>
        </TaxiAreaSelect>

        <Box
          mt="40px"
          mb="32px"
          mx="auto"
          w="100%"
          maxW="600px"
          h="2px"
          backgroundColor="grand.grayLight"
        />

        <TaxiFreewordForm
          mt="24px"
          handleSubmit={(value: string) => handleFreewordSubmit(value)}
        />
      </Box>

      <Flex
        mt={{ base: "16px", md: "24px" }}
        direction="column"
        pr={{ base: "0px", md: "30px" }}
      >
        <TaxiPointHeading
          label={selectedPlaceId === 1 ? f("町内") : f("町外")}
        />
        {/* 町内は地点ボタン */}
        {selectedPlaceId === 1 ? (
          <>
            {/* 自宅選択肢 住所に「自宅」をセット */}
            <TaxiPointItem
              onClick={clickPointMyHome}
              borderBottomWidth="1px"
              borderBottomColor="grand.graySuperLight"
              _last={{ borderBottomWidth: "0px" }}
            >
              自宅
            </TaxiPointItem>
            {internalLandmarks.map((place) => (
              <TaxiPointItem
                onClick={() => clickPoint(place)}
                key={place.id}
                borderBottomWidth="1px"
                borderBottomColor="grand.graySuperLight"
                _last={{ borderBottomWidth: "0px" }}
              >
                {place.name}
              </TaxiPointItem>
            ))}
          </>
        ) : (
          externalLandmarks.map((place) => (
            <TaxiPointItem
              onClick={() => clickPoint(place)}
              key={place.id}
              borderBottomWidth="1px"
              borderBottomColor="grand.graySuperLight"
              _last={{ borderBottomWidth: "0px" }}
            >
              {place.name}
            </TaxiPointItem>
          ))
        )}
      </Flex>
    </>
  );
};

export default TaxiReservationCreateSelectTo;
