import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TaxiBox } from "../../../components/taxi/TaxiBox";
import { TaxiButton } from "../../../components/taxi/TaxiButton";
import { TaxiTitle } from "../../../components/taxi/TaxiTitle";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useHeaderConfig from "../../../hooks/useHeaderConfig";
import { p2r } from "../../../utils/font";
import useTaxiReservationCreateState, {
  defaultFormValues,
  defaultTaxiRideDateTime,
} from "../useTaxiReservationCreateState";

// 予約確定
const TaxiReservationFix: React.FC = () => {
  useHeaderConfig({
    title: "タクシー予約",
  });
  const { f } = useFormatMessage();
  const navigate = useNavigate();
  const {
    formValues,
    setFormValues,
    setTaxiRideDateTime,
    selectedTaxiCompany,
    selectedEndTaxiLandmark,
    selectedStartTaxiLandmark,
    selectedRideDate,
    selectedRideTime,
    selectedReturnRideTime,
    taxiRideDateTime,
  } = useTaxiReservationCreateState();

  const rideTime = `${selectedRideDate}${selectedRideTime}`;
  const rideReturnTime = `${selectedRideDate}${selectedReturnRideTime}`;

  return (
    <Flex
      bgColor={{ base: "#FFFDFC", md: "theme.background" }}
      minH={{ base: "unset", md: "calc(100vh - 56px)" }}
      justify="center"
      alignItems="center"
      direction="column"
      pt={{ base: "16px", md: "0px" }}
    >
      <Flex
        justify="center"
        alignItems="center"
        direction="column"
        px={{ base: "16px", md: "0px" }}
        w="100%"
      >
        <TaxiTitle label={f("予約が確定しました")} />
        <Text
          fontSize={{ base: p2r(16), md: p2r(20) }}
          color="text.sub"
          pt="8px"
        >
          {f("当日のご乗車お待ちしております。")}
        </Text>
        <Box
          my={{ base: "16px", md: "2.8vh" }}
          w={{ base: "100%", md: "unset" }}
        >
          <TaxiBox
            to={selectedEndTaxiLandmark ? selectedEndTaxiLandmark.name : ""}
            from={
              selectedStartTaxiLandmark ? selectedStartTaxiLandmark.name : ""
            }
            companies={selectedTaxiCompany ? [selectedTaxiCompany.name] : [""]}
            fromTime={rideTime}
            toTime={rideReturnTime}
            isGoback={taxiRideDateTime.returnTime.hour !== 0}
            isReverse
            userMessage={formValues.userMessage}
            passengerCount={formValues.passengerCount}
          />
        </Box>
      </Flex>
      <Flex
        mt={{ base: "auto", md: "unset" }}
        columnGap={{ base: "0px", md: "32px" }}
        rowGap={{ base: "8px", md: "0px" }}
        direction={{ base: "column", md: "row" }}
        w={{ base: "100%", md: "auto" }}
        p={{ base: "16px", md: "0px" }}
        backgroundColor={{ base: "grand.white", md: "unset" }}
        boxShadow={{ base: "0px 0px 10px rgba(0, 0, 0, 0.1)", md: "unset" }}
      >
        <TaxiButton
          onClick={() => {
            // リセット
            setFormValues({
              ...defaultFormValues,
            });
            setTaxiRideDateTime({
              ...defaultTaxiRideDateTime,
            });
            navigate("/");
          }}
          isLarge
        >
          {f("ホームに戻る")}
        </TaxiButton>
      </Flex>
    </Flex>
  );
};

export default TaxiReservationFix;
