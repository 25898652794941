import { Input, InputProps } from "@chakra-ui/react";
import React from "react";

type InputTextProps = InputProps;

const InputText: React.FC<InputTextProps> = (props) => (
  <Input
    bg="grand.white"
    border="1px solid"
    borderColor="grand.grayLight"
    padding="16px"
    _placeholder={{ color: "grand.grayLight" }}
    borderRadius="8px"
    focusBorderColor="theme.primary"
    {...props}
  />
);

export default InputText;
