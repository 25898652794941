import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useTaxiLandmarkListQuery } from '../../hooks/coreApi/useTaxiLandmarkListQuery'
import {
  TaxiCompanyResponse,
  TaxiLandmarkResponse,
  TaxiReservationCreateByUserRequest,
} from '../../openapi'

export type FormValuesType = {
  taxiCompanyId: number | null
  rideAt: Date
  startTaxiLandmarkId: number | null
  endTaxiLandmarkId: number | null
  startAddress: string | null
  endAddress: string | null
  startLatLng: string | null
  endLatLng: string | null
  // 帰路
  returnRideAt: Date
  returnStartTaxiLandmarkId: number | null
  returnEndTaxiLandmarkId: number | null
  returnStartAddress: string | null
  returnEndAddress: string | null
  returnStartLatLng: string | null
  returnEndLatLng: string | null
  userMessage: string
  passengerCount: number
}

export type TaxiRideDateTimeType = {
  date: {
    year: number
    month: number
    day: number
  }
  time: {
    hour: number
    minute: number
  }
  returnDate: {
    year: number
    month: number
    day: number
  }
  returnTime: {
    hour: number
    minute: number
  }
}

// 帰路用（TODO API修正後削除）
type TaxiReservationReturnRequest = {
  returnRideAt: string
  returnStartTaxiLandmarkId: number | null
  returnEndTaxiLandmarkId: number | null
  returnStartAddress: string | null
  returnEndAddress: string | null
  returnStartLatLng: string | null
  returnEndLatLng: string | null
}
export const defaultFormValues: TaxiReservationCreateByUserRequest &
  TaxiReservationReturnRequest = {
  // API
  taxiCompanyId: 0,
  rideAt: '',
  dropAt: '',
  startTaxiLandmarkId: 0,
  endTaxiLandmarkId: 0,
  startAddress: '',
  endAddress: '',
  startLatLng: '',
  endLatLng: '',
  isInBound: false,
  userMessage: '',
  passengerCount: 1,
  // 帰路
  returnRideAt: '',
  returnDropAt: '',
  returnStartTaxiLandmarkId: 0,
  returnEndTaxiLandmarkId: 0,
  returnStartAddress: '',
  returnEndAddress: '',
  returnStartLatLng: '',
  returnEndLatLng: '',
}

export const defaultTaxiRideDateTime: TaxiRideDateTimeType = {
  date: {
    year: 0,
    month: 0,
    day: 0,
  },
  time: {
    hour: 0,
    minute: 0,
  },
  returnDate: {
    year: 0,
    month: 0,
    day: 0,
  },
  returnTime: {
    hour: 0,
    minute: 0,
  },
}

const formValuesState = atom<
  TaxiReservationCreateByUserRequest & TaxiReservationReturnRequest
>({
  key: 'formValues',
  default: {
    ...defaultFormValues,
  },
})

const taxiCompaniesState = atom<TaxiCompanyResponse[]>({
  key: 'taxiCompanies',
  default: [],
})

const taxiLandmarksState = atom<TaxiLandmarkResponse[]>({
  key: 'taxiLandmarks',
  default: [],
})

const taxiRideDateTimeState = atom<TaxiRideDateTimeType>({
  key: 'taxiRideDateTime',
  default: {
    ...defaultTaxiRideDateTime,
  },
})

const useTaxiReservationCreateState = () => {
  const [formValues, setOriginalFormValues] = useRecoilState(formValuesState)
  const [taxiCompanies, setOriginalTaxiCompanies] =
    useRecoilState(taxiCompaniesState)
  const [taxiLandmarks, setOriginalTaxiLandmarks] =
    useRecoilState(taxiLandmarksState)
  const [taxiRideDateTime, setOriginalTaxiRideDateTime] = useRecoilState(
    taxiRideDateTimeState,
  )

  const taxiLandmarkListQuery = useTaxiLandmarkListQuery({})
  const fetchedTaxiLandmarks = useMemo(
    () => taxiLandmarkListQuery?.data?.taxiLandmarks || [],
    [taxiLandmarkListQuery],
  )

  useEffect(() => {
    if (taxiLandmarkListQuery.isSuccess) {
      setOriginalTaxiLandmarks(fetchedTaxiLandmarks)
    }
  }, [fetchedTaxiLandmarks])

  const internalLandmarks = useMemo(
    () => fetchedTaxiLandmarks.filter((l) => l.isOutsideTenant === false),
    [fetchedTaxiLandmarks],
  )

  const externalLandmarks = useMemo(
    () => fetchedTaxiLandmarks.filter((l) => l.isOutsideTenant === true),
    [fetchedTaxiLandmarks],
  )

  // 予約フォームの入力値の更新
  const setFormValues = useCallback(
    (
      params: Partial<
        TaxiReservationCreateByUserRequest & TaxiReservationReturnRequest
      >,
    ) => {
      setOriginalFormValues({
        ...formValues,
        ...params,
      })
    },
    [formValues, setOriginalFormValues],
  )

  // タクシー会社情報の更新
  const setTaxiCompanies = useCallback(
    (companies: TaxiCompanyResponse[]) => {
      setOriginalTaxiCompanies(companies)
    },
    [setOriginalTaxiCompanies],
  )

  // タクシー乗客日時用
  const setTaxiRideDateTime = useCallback(
    (params: Partial<TaxiRideDateTimeType>) => {
      setOriginalTaxiRideDateTime({
        ...taxiRideDateTime,
        ...params,
      })
    },
    [taxiRideDateTime, setOriginalTaxiRideDateTime],
  )

  // タクシー会社IDから選択中のタクシー会社情報を更新
  const selectedTaxiCompany = useMemo(() => {
    if (formValues.taxiCompanyId === null) {
      return null
    }
    return taxiCompanies.find(
      (company) => company.id === formValues.taxiCompanyId,
    )
  }, [formValues.taxiCompanyId])

  // 乗車地の更新
  const selectedStartTaxiLandmark = useMemo(() => {
    if (
      formValues.startTaxiLandmarkId === null &&
      formValues.startAddress === null
    ) {
      return null
    }
    return (
      taxiLandmarks.find(
        (landmark) => landmark.id === formValues.startTaxiLandmarkId,
      ) || {
        name: formValues.startAddress,
      }
    )
  }, [formValues.startTaxiLandmarkId, formValues.startAddress])

  // 降車地の更新
  const selectedEndTaxiLandmark = useMemo(() => {
    if (
      formValues.endTaxiLandmarkId === null &&
      formValues.endAddress === null
    ) {
      return null
    }
    return (
      taxiLandmarks.find(
        (landmark) => landmark.id === formValues.endTaxiLandmarkId,
      ) || {
        name: formValues.endAddress,
      }
    )
  }, [formValues.endTaxiLandmarkId, formValues.endAddress])

  // 帰路：乗車地の更新
  const selectedReturnStartTaxiLandmark = useMemo(() => {
    if (
      formValues.returnStartTaxiLandmarkId === null &&
      formValues.returnStartAddress === null
    ) {
      return null
    }
    return (
      taxiLandmarks.find(
        (landmark) => landmark.id === formValues.returnStartTaxiLandmarkId,
      ) || {
        name: formValues.returnStartAddress,
      }
    )
  }, [formValues.returnStartTaxiLandmarkId, formValues.returnStartAddress])

  // 帰路：降車地の更新
  const selectedReturnEndTaxiLandmark = useMemo(() => {
    if (
      formValues.returnEndTaxiLandmarkId === null &&
      formValues.returnEndAddress === null
    ) {
      return null
    }
    return (
      taxiLandmarks.find(
        (landmark) => landmark.id === formValues.returnEndTaxiLandmarkId,
      ) || {
        name: formValues.returnEndAddress,
      }
    )
  }, [formValues.returnEndTaxiLandmarkId, formValues.returnEndAddress])

  // 往路の日付
  const selectedRideDate = useMemo(() => {
    const { year, month, day } = taxiRideDateTime.date
    if (year === 0) {
      return ''
    }
    // 曜日
    const weekDay = dayjs(`${year}-${month}-${day}`).format('ddd')

    return `${month}月${day}日(${weekDay})`
  }, [taxiRideDateTime.date])

  // 往路の時間
  const selectedRideTime = useMemo(() => {
    const { hour, minute } = taxiRideDateTime.time
    if (hour === 0) {
      return ''
    }

    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
  }, [taxiRideDateTime.time])

  // 復路の日付
  const selectedReturnRideDate = useMemo(() => {
    const { year, month, day } = taxiRideDateTime.returnDate
    if (year === 0) {
      return ''
    }
    // 曜日
    const weekDay = dayjs(`${year}-${month}-${day}`).format('ddd')

    return `${month}月${day}日(${weekDay})`
  }, [taxiRideDateTime.returnDate])

  // 復路の時間
  const selectedReturnRideTime = useMemo(() => {
    const { hour, minute } = taxiRideDateTime.returnTime
    if (hour === 0) {
      return ''
    }

    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
  }, [taxiRideDateTime.returnTime])

  // 往路のrideAt更新
  useEffect(() => {
    const { year, month, day } = taxiRideDateTime.date
    const { hour, minute } = taxiRideDateTime.time

    setFormValues({
      rideAt: dayjs(`${year}-${month}-${day} ${hour}:${minute}`).format(),
    })
  }, [taxiRideDateTime.date, taxiRideDateTime.time])

  // 帰路のrideAt更新
  useEffect(() => {
    const { year, month, day } = taxiRideDateTime.returnDate
    const { hour, minute } = taxiRideDateTime.returnTime
    const formattedDate = dayjs(
      `${year}-${month}-${day} ${hour}:${minute}`,
    ).format()

    if (formattedDate === 'Invalid Date') {
      setFormValues({
        returnRideAt: '',
      })
    } else
      setFormValues({
        returnRideAt: formattedDate,
      })
  }, [taxiRideDateTime.returnDate, taxiRideDateTime.returnTime])

  return {
    formValues,
    setFormValues,
    taxiCompanies,
    setTaxiCompanies,
    selectedTaxiCompany,
    taxiLandmarks,
    internalLandmarks,
    externalLandmarks,
    selectedStartTaxiLandmark,
    selectedEndTaxiLandmark,
    taxiRideDateTime,
    setTaxiRideDateTime,
    selectedRideDate,
    selectedRideTime,
    // 帰路
    selectedReturnRideDate,
    selectedReturnRideTime,
    selectedReturnStartTaxiLandmark,
    selectedReturnEndTaxiLandmark,
  }
}

export default useTaxiReservationCreateState
