import {
  Flex,
  Text,
  Box,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppConfirm } from "../../../components/common/AppConfirm/useAppConfirm";
import AppErrorResult from "../../../components/common/AppErrorResult";
import { AppLoading } from "../../../components/common/AppLoading";
import { TaxiBox } from "../../../components/taxi/TaxiBox";
import { TaxiButton } from "../../../components/taxi/TaxiButton";
import { TaxiSectionTitle } from "../../../components/taxi/TaxiSectionTitle";
import { useTaxiReservationListQuery } from "../../../hooks/coreApi/useTaxiReservationListQuery";
import { useTaxiReservationUpdateStatusByUserMutation } from "../../../hooks/coreApi/useTaxiReservationUpdateStatusByUserMutation";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useHeaderConfig from "../../../hooks/useHeaderConfig";
import {
  TaxiReservationStatus,
  getFromLabel,
  getToLabel,
} from "../../../models/taxiReservation";
import { p2r } from "../../../utils/font";

// 予約確認
const TaxiReservationDetail: React.FC = () => {
  useHeaderConfig({
    title: "タクシー予約",
  });
  const { id } = useParams<{ id: string }>();

  const taxiReservationListQuery = useTaxiReservationListQuery({
    id: Number(id),
    withTaxiCompany: true,
    withStartTaxiLandmark: true,
    withEndTaxiLandmark: true,
  });
  const navigate = useNavigate();
  const updateMutation = useTaxiReservationUpdateStatusByUserMutation();
  const toast = useToast();
  const appConfirm = useAppConfirm();

  const onClickCancel = async () => {
    const confirmOk = await appConfirm.open({
      title: "予約のキャンセル",
      body: "この予約をキャンセルしますか？",
    });

    if (!confirmOk) {
      return;
    }

    updateMutation.mutate(
      {
        id: Number(id),
        taxiReservationUpdateByUserRequest: {
          status: "canceled",
        },
      },
      {
        onSuccess() {
          toast({
            title: "予約をキャンセルしました",
            status: "success",
          });
          navigate("/taxi");
        },
        onError() {
          toast({
            title: "予約のキャンセルに失敗しました。",
            status: "error",
          });
        },
      }
    );
  };

  const taxiReservation = taxiReservationListQuery.data?.taxiReservations?.[0];

  const { f } = useFormatMessage();
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (taxiReservationListQuery.isLoading) {
    return <AppLoading />;
  }

  if (!taxiReservation) {
    return <AppErrorResult message="予約が見つかりませんでした" />;
  }

  return (
    <Flex
      minH={{ base: "unset", md: "calc(100vh - 56px)" }}
      justify="center"
      alignItems="center"
      direction="column"
      bgColor={{ base: "#FFFDFC", md: "theme.background" }}
    >
      {!isMobile && (
        <Box w="100%" px="32px">
          <TaxiSectionTitle
            label={f("内容の確認、取り消しを行うことができます。")}
          />
        </Box>
      )}
      <Flex
        direction="column"
        py={{ base: "16px", md: "32px" }}
        px={{ base: "16px", md: "unset" }}
        alignItems={{ base: "center", md: "unset" }}
        justify="center"
        w={{ base: "100%", md: "unset" }}
      >
        <Text
          color="text.body"
          fontSize={p2r(24)}
          fontWeight="700"
          lineHeight="150%"
          pb={{ base: "8px", md: "0px" }}
          mr={{ base: "unset", md: "auto" }}
        >
          {f("予約内容")}
        </Text>
        {isMobile && (
          <TaxiSectionTitle
            label={f("内容の確認、取り消しを行うことができます。")}
          />
        )}
        <Box mt={{ base: "16px", md: "1.9vh" }} w="100%">
          <TaxiBox
            companies={[taxiReservation.taxiCompany?.name || ""]} // TODO API withCompanyで取得
            from={getFromLabel(taxiReservation)}
            to={getToLabel(taxiReservation)}
            fromTime={dayjs(taxiReservation.rideAt).format(
              "M月DD日(ddd) HH:mm"
            )}
            status={taxiReservation.status as TaxiReservationStatus}
            cancelReason={taxiReservation.cancelReason || ""}
            userMessage={taxiReservation.userMessage}
            taxiCompanyMessage={taxiReservation.taxiCompanyMessage}
            passengerCount={taxiReservation.passengerCount}
          />
        </Box>
      </Flex>
      {taxiReservation.status === "draft" && (
        <Flex
          columnGap={{ base: "0px", md: "3.8vh" }}
          rowGap={{ base: "8px", md: "0px" }}
          direction={{ base: "column", md: "row" }}
          w={{ base: "100%", md: "auto" }}
          p={{ base: "16px", md: "0px" }}
          backgroundColor={{ base: "grand.white", md: "unset" }}
          boxShadow={{ base: "0px 0px 10px rgba(0, 0, 0, 0.1)", md: "unset" }}
        >
          <TaxiButton onClick={onClickCancel}>{f("予約を取り消す")}</TaxiButton>
        </Flex>
      )}
    </Flex>
  );
};

export default TaxiReservationDetail;
