import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TaxiAreaSelect, {
  TaxiAreaSelectButton,
} from "../../../components/taxi/TaxiAreaSelect";
import TaxiFreewordForm from "../../../components/taxi/TaxiFreewordForm";
import { TaxiPointHeading } from "../../../components/taxi/TaxiPointHeading";
import TaxiPointItem from "../../../components/taxi/TaxiPointItem";
import { TaxiSectionTitle } from "../../../components/taxi/TaxiSectionTitle";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { TaxiLandmarkResponse } from "../../../openapi";
import useTaxiReservationCreateState from "../useTaxiReservationCreateState";

// 乗車場所選択
const TaxiReservationCreateSelectFrom: React.FC = () => {
  const { f } = useFormatMessage();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { setFormValues, internalLandmarks, externalLandmarks } =
    useTaxiReservationCreateState();
  const navigate = useNavigate();

  // 地点一覧絞り込み用のエリアID
  const [selectedPlaceId, setSelectedPlaceId] = useState<number>(1);

  // エリアクリック
  const clickPoint = (point: TaxiLandmarkResponse) => {
    setFormValues({
      startTaxiLandmarkId: point.id,
      startLatLng: point?.latLng || undefined,
      startAddress: "",
    });
    navigate("/taxi/reservations/create");
  };

  // 「自宅」をクリック
  const clickPointMyHome = () => {
    setFormValues({
      startTaxiLandmarkId: undefined,
      startLatLng: undefined,
      startAddress: "自宅",
    });
    navigate("/taxi/reservations/create");
  };

  const handleFreewordSubmit = (value: string) => {
    setFormValues({
      startTaxiLandmarkId: null,
      startLatLng: "",
      startAddress: value,
    });
    navigate("/taxi/reservations/create");
  };

  return (
    <>
      <Box px={isMobile ? "16px" : "0px"}>
        <TaxiSectionTitle
          label={f("乗車場所を入力もしくは選択してください。")}
        />

        <TaxiAreaSelect>
          <TaxiAreaSelectButton
            w="50%"
            isActive={selectedPlaceId === 1}
            onClick={() => setSelectedPlaceId(1)}
          >
            {f("神山町内")}
          </TaxiAreaSelectButton>
          <TaxiAreaSelectButton
            w="50%"
            isActive={selectedPlaceId === 2}
            onClick={() => setSelectedPlaceId(2)}
          >
            {f("神山町外")}
          </TaxiAreaSelectButton>
        </TaxiAreaSelect>

        <Box
          mt={isMobile ? "24px" : "40px"}
          mb={isMobile ? "16px" : "32px"}
          mx="auto"
          w="100%"
          maxW={isMobile ? "unset" : "600px"}
          h="2px"
          backgroundColor="grand.grayLight"
        />

        <TaxiFreewordForm
          mt={isMobile ? "16px" : "24px"}
          handleSubmit={(value: string) => handleFreewordSubmit(value)}
        />
      </Box>

      <Flex
        mt={{ base: "16px", md: "24px" }}
        direction="column"
        pr={{ base: "0px", md: "30px" }}
      >
        <TaxiPointHeading
          label={selectedPlaceId === 1 ? f("町内") : f("町外")}
        />
        {/* 町内は地点ボタン */}
        {selectedPlaceId === 1 ? (
          <>
            {/* 自宅選択肢 住所に「自宅」をセット */}
            <TaxiPointItem
              onClick={clickPointMyHome}
              borderBottomWidth="1px"
              borderBottomColor="grand.graySuperLight"
              _last={{ borderBottomWidth: "0px" }}
            >
              自宅
            </TaxiPointItem>
            {internalLandmarks.map((place) => (
              <TaxiPointItem
                onClick={() => clickPoint(place)}
                key={place.id}
                borderBottomWidth="1px"
                borderBottomColor="grand.graySuperLight"
                _last={{ borderBottomWidth: "0px" }}
              >
                {place.name}
              </TaxiPointItem>
            ))}
          </>
        ) : (
          externalLandmarks.map((place) => (
            <TaxiPointItem
              onClick={() => clickPoint(place)}
              key={place.id}
              borderBottomWidth="1px"
              borderBottomColor="grand.graySuperLight"
              _last={{ borderBottomWidth: "0px" }}
            >
              {place.name}
            </TaxiPointItem>
          ))
        )}
      </Flex>
    </>
  );
};

export default TaxiReservationCreateSelectFrom;
