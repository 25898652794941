import { Box, Flex, Text } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TaxiBox } from "../../../components/taxi/TaxiBox";
import { TaxiButton } from "../../../components/taxi/TaxiButton";
import { TaxiTitle } from "../../../components/taxi/TaxiTitle";
import { useTaxiReservationCreateByUserMutation } from "../../../hooks/coreApi/useTaxiReservationCreateByUserMutation";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { getDefaultDropAt } from "../../../models/taxiReservation";
import { TaxiReservationCreateByUserResponse } from "../../../openapi";
import { p2r } from "../../../utils/font";
import useTaxiReservationCreateState from "../useTaxiReservationCreateState";

// 仮予約
const TaxiReservationCreateConfirm: React.FC = () => {
  const { f } = useFormatMessage();
  const navigate = useNavigate();
  const {
    formValues,
    selectedTaxiCompany,
    selectedEndTaxiLandmark,
    selectedStartTaxiLandmark,
    selectedRideDate,
    selectedRideTime,
    selectedReturnRideTime,
  } = useTaxiReservationCreateState();

  const rideTime = `${selectedRideDate}${selectedRideTime}`;
  const rideReturnTime = `${selectedRideDate}${selectedReturnRideTime}`;

  // 予約送信処理
  const reservationMutation = useTaxiReservationCreateByUserMutation();

  // 予約作成
  const handleReservationSubmit = () => {
    // TODO TaxiReservationCreateByUserRequestの型利用
    const params: any = {
      taxiCompanyId: formValues.taxiCompanyId,
      rideAt: dayjs(formValues.rideAt).utc().format(),
      dropAt: getDefaultDropAt(dayjs(formValues.rideAt)).utc().format(),
      startTaxiLandmarkId: formValues.startTaxiLandmarkId,
      startAddress: formValues.startAddress,
      endTaxiLandmarkId: formValues.endTaxiLandmarkId,
      endAddress: formValues.endAddress,
      isInbound: formValues.isInBound,
      passengerCount: formValues.passengerCount,
      userMessage: formValues.userMessage,
    };

    if (formValues.isInBound) {
      params.returnRideAt = dayjs(formValues.returnRideAt).utc().format();
      params.returnDropAt = getDefaultDropAt(dayjs(formValues.returnRideAt))
        .utc()
        .format();
    }

    reservationMutation.mutate(
      {
        taxiReservationCreateByUserRequest: params,
      },
      {
        onSuccess(res: AxiosResponse<TaxiReservationCreateByUserResponse>) {
          const r = res.data;
          navigate(
            `/taxi/reservations/pre-fix?inBoundTaxiReservationId=${r.inBoundTaxiReservationId}&outBoundTaxiReservationId=${r.outBoundTaxiReservationId}`
          );
        },
        onError() {
          navigate("/taxi/reservations/failed");
        },
      }
    );
  };

  return (
    <Flex
      backgroundColor={{ base: "#FFFDFC", md: "theme.background" }}
      w="100%"
      minH="calc(100vh - 56px)"
      direction="column"
      justify="center"
    >
      <Flex
        direction="column"
        alignItems="center"
        px={{ base: "16px", md: "0px" }}
        pt={{ base: "16px", md: "0px" }}
      >
        <TaxiTitle label={f("下記の内容で仮予約いたします")} />
        <Text
          color="grand.error"
          fontSize={{ base: p2r(16), md: p2r(20) }}
          fontWeight="700"
          lineHeight="150%"
          pt="8px"
        >
          ※{f("仮予約はまだ完了しておりません")}
        </Text>
        <Box
          my={{ base: "16px", md: "3.8vh" }}
          w={{ base: "100%", md: "unset" }}
        >
          <TaxiBox
            to={selectedEndTaxiLandmark ? selectedEndTaxiLandmark.name : ""}
            from={
              selectedStartTaxiLandmark ? selectedStartTaxiLandmark.name : ""
            }
            companies={selectedTaxiCompany ? [selectedTaxiCompany.name] : [""]}
            fromTime={rideTime}
            toTime={rideReturnTime}
            isGoback={formValues.isInBound}
            userMessage={formValues.userMessage}
            passengerCount={formValues.passengerCount}
            isReverse
          />
        </Box>
      </Flex>
      <Flex
        w={{ base: "100vw", md: "auto" }}
        backgroundColor={{ base: "grand.white", md: "unset" }}
        boxShadow={{ base: "0px 0px 10px rgba(0, 0, 0, 0.1)", md: "unset" }}
        columnGap={{ base: "0px", md: "3.8vh" }}
        rowGap={{ base: "8px", md: "0px" }}
        direction={{ base: "column", md: "row" }}
        p={{ base: "16px", md: "0px" }}
        justify="center"
      >
        <TaxiButton
          onClick={() => {
            navigate("/taxi/reservations/create");
          }}
          isLarge
          isReverse
        >
          {f("内容を変更する")}
        </TaxiButton>
        <TaxiButton
          onClick={handleReservationSubmit}
          isLarge
          isLoading={reservationMutation.isLoading}
        >
          {f("仮予約を申し込む")}
        </TaxiButton>
      </Flex>
    </Flex>
  );
};

export default TaxiReservationCreateConfirm;
