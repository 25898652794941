// コンテンツ デフォルト表示件数
export const CONTENT_DEFAULT_PAGE_SIZE = 21;

// タクシー予約デフォルトの降車時間(分)
export const TAXI_RESERVATION_DROP_AT_DURATION_MINUTES = 40;

// タクシー予約可能な日付
export const TAXI_RESERVATION_LIMIT_DATE = 1;

// ビデオ会議(Qualink)へのリンク
// TODO 本番 STG ドメイン切り替え
export const QUALINK_VIDEO_BASE_PATH =
  process.env.REACT_APP_ENV === "prod"
    ? "https://cir-cle.qualink.jp/#"
    : "https://cir-cle.qualink.jp/#";
