import { Box, Flex, RadioProps, Text } from "@chakra-ui/react";
import React, { forwardRef, ComponentPropsWithoutRef } from "react";
import { p2r } from "../../../utils/font";

export type TaxiCompanyRadioProps = {
  taxiCompanyName: string;
  startTime: string;
  endTime: string;
  address: string;
  isSelected: boolean;
  onClick: () => void;
  // onChange(event: React.ChangeEvent<HTMLInputElement>): void
} & ComponentPropsWithoutRef<"input"> &
  RadioProps;

export const TaxiCompanyRadio = forwardRef<
  HTMLInputElement,
  TaxiCompanyRadioProps
>((props) => {
  const { taxiCompanyName, startTime, endTime, address, onClick, isSelected } =
    props;
  return (
    <Flex
      px={{ base: "16px", md: "32px" }}
      py="16px"
      justify="space-between"
      backgroundColor={isSelected ? "green.100" : "grand.white"}
      direction={{ base: "column", md: "row" }}
      rowGap={{ base: "16px", md: "0px" }}
      borderWidth="2px"
      borderColor="grand.grayLight"
      borderRadius="10px"
      cursor="pointer"
      onClick={onClick}
    >
      <Box align-items="center">
        <Text fontSize="xl" fontWeight="bold">
          {taxiCompanyName}
        </Text>
      </Box>
      <Flex direction="column" rowGap="8px">
        <Flex justify="space-between" columnGap="56px">
          <Text
            color="text.sub"
            fontSize={{ base: p2r(14), md: p2r(16) }}
            fontWeight="400"
            lineHeight="150%"
          >
            営業時間
          </Text>
          <Text
            color="text.body"
            fontSize={{ base: p2r(14), md: p2r(16) }}
            fontWeight="700"
            lineHeight="150%"
          >
            {startTime}-{endTime}
          </Text>
        </Flex>
        <Flex justify="space-between" columnGap="56px">
          <Text
            color="text.sub"
            fontSize={{ base: p2r(14), md: p2r(16) }}
            fontWeight="400"
            lineHeight="150%"
          >
            住所
          </Text>
          <Text
            color="text.body"
            fontSize={{ base: p2r(14), md: p2r(16) }}
            fontWeight="700"
            lineHeight="150%"
          >
            {address}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
});
