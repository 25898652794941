import { Radio, RadioProps } from "@chakra-ui/react";
import React from "react";

type RadioButtonProps = RadioProps;

const RadioButton: React.FC<RadioButtonProps> = (props) => (
  <Radio
    size="lg"
    colorScheme="radio"
    columnGap="4px"
    spacing="4px"
    {...props}
  />
);

export default RadioButton;
